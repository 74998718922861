import { UpdateReleaseData } from '../../actions/system/release'
import config from '../../application/config'
import { setRelease } from '../../utils/log'

const updateVersion = (version: string) => {
  // Update version in app config object, stripping off the first v if it has one.
  config.app.version = version.replace(/^v/, '')

  // Update version used in Sentry.
  setRelease(config.app.version)
}

interface Component {
  ref: string
  hash: string
}

interface Proximity extends Component {
  api: string
}

interface Migration {
  version: string
  dirty: string
}

interface DB extends Component {
  migrations: {
    global: Migration
    aeon: Migration
    audit: Migration
  }
}

interface Components {
  exivityd: Component
  db: DB
  use: Component
  transcript: Component
  edify: Component
  glass: Component
  proximity: Proximity
  aeon: Component
  pigeon: Component
}

export interface ReleaseState {
  version: string
  components?: Partial<Components>
}

const initialState: ReleaseState = {
  version: '',
  components: {}
}

const release = (state = initialState, action: UpdateReleaseData): ReleaseState => {
  switch (action.type) {
    case UpdateReleaseData.type:

      updateVersion(action.data.version ?? '')

      return { ...state, ...action.data }

    default:
      return state
  }
}

export default release
