import { serverV1 } from '../data/sources'

export const setParams = (params) => ({
  sources: {
    server: {
      settings: {
        params
      }
    }
  }
})

export function addRecord (type, attributes, relationships, options) {
  return new Promise((resolve, reject) => {
    serverV1.server.addRecord({
      type,
      attributes: serverV1.server.schema.removeReadOnlyAttributes(type, attributes),
      relationships
    }, options)
      .then(transforms => resolve(transforms[0].operations[0].record))
      .catch(reject)
  })
}

export function push (transform, options) {
  return new Promise((resolve, reject) => {
    serverV1.server.push(transform, options)
      .then(transforms => resolve(transforms[0].operations[0].record))
      .catch(error => {
        serverV1.server.requestQueue.skip()
        reject(error)
      })
  })
}

export function updateRecord (type, id, attributes, relationships, options) {
  const record = {
    type,
    id,
    attributes: serverV1.server.schema.removeReadOnlyAttributes(type, attributes)
  }

  if (relationships) {
    record.relationships = relationships
  }

  return push(transform => transform.updateRecord(record), options)
}

export function removeRecord (type, id, options) {
  return push(transform => transform.removeRecord({ type, id }), options)
}
