var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { translate } from '@exivity/translations';
import AutoSizer from 'react-virtualized-auto-sizer';
import useResizeObserver from 'use-resize-observer';
import { append, complement, isEmpty, assocPath, path, equals, reject, includes, curry } from 'ramda';
import { Searchbar } from './Searchbar';
import { ListContext } from './listContext';
import { StyledContainer } from './StyledContainer';
import { SelectOption } from './SelectOption';
import { Actions } from './Actions';
export var ITEM_HEIGHT = 30;
var isNotEmpty = complement(isEmpty);
export function isMultiple(args) {
    return Array.isArray(args);
}
var styles = {
    autoSizer: { minHeight: 300, height: '100%', width: '100%' },
    virtualList: { minHeight: 300 }
};
var identityAny = function (id) { return id; };
export function SelectList(_a) {
    var className = _a.className, actions = _a.actions, onClear = _a.onClear, _b = _a.clearText, clearText = _b === void 0 ? translate('Clear selection') : _b, _c = _a.noDataText, noDataText = _c === void 0 ? translate('No items in this list...') : _c, categorized = _a.categorized, multiple = _a.multiple, _d = _a.children, children = _d === void 0 ? SelectOption : _d, getChildNodes = _a.getChildNodes, _e = _a.labelAccessor, labelAccessor = _e === void 0 ? identityAny : _e, _f = _a.valueAccessor, valueAccessor = _f === void 0 ? identityAny : _f, value = _a.value, _g = _a.onChange, onChange = _g === void 0 ? function () { } : _g, data = _a.data, renderIcon = _a.renderIcon, _h = _a.initExpandedState, initExpandedState = _h === void 0 ? {} : _h, searchable = _a.searchable;
    var _j = useState(initExpandedState), expandedStateTree = _j[0], updateExpandedStateTree = _j[1];
    var _k = useState(''), query = _k[0], setQuery = _k[1];
    function searchAll(data, query, expandedState, parentPath) {
        if (query === void 0) { query = ''; }
        if (expandedState === void 0) { expandedState = {}; }
        if (parentPath === void 0) { parentPath = []; }
        if (query.length === 0)
            return expandedState;
        var getChildren = getChildNodes
            ? getChildNodes
            : function () { return []; };
        var newState = expandedState;
        data.forEach(function (item, index) {
            var nodePath = __spreadArray(__spreadArray([], parentPath, true), [index], false);
            var children = getChildren(item);
            newState = labelAccessor(item).toLowerCase().indexOf(query.toLowerCase()) !== -1
                ? assocPath(nodePath, true, newState)
                : newState;
            if (children.length > 0) {
                newState = searchAll(children, query, newState, nodePath);
            }
        });
        return newState;
    }
    var list = useMemo(function () {
        function getList(data, expandedState, parents, parentPath, onlyExpanded) {
            if (data === void 0) { data = []; }
            if (expandedState === void 0) { expandedState = {}; }
            if (parents === void 0) { parents = []; }
            if (parentPath === void 0) { parentPath = []; }
            if (onlyExpanded === void 0) { onlyExpanded = false; }
            var getChildren = getChildNodes
                ? getChildNodes
                : function () { return []; };
            return data.flatMap(function (item, index) {
                var nodePath = __spreadArray(__spreadArray([], parentPath, true), [index], false);
                var expanded = !!path(nodePath, expandedState);
                var isActive = curry(function (currentValue, node) { return multiple && Array.isArray(currentValue)
                    ? includes(valueAccessor(node), currentValue)
                    : equals(valueAccessor(node), currentValue); });
                var node = {
                    data: item,
                    path: nodePath,
                    label: labelAccessor(item),
                    expanded: expanded,
                    onChangeExpand: function () { return updateExpandedStateTree(assocPath(nodePath, !expanded)); },
                    query: query,
                    isTree: !!getChildNodes,
                    isLeaf: function () { return getChildren(item).length === 0; },
                    categorized: categorized,
                    isCategory: !!categorized && nodePath.length === 1,
                    multiple: multiple,
                    isActive: isActive,
                    isParentActive: function (currentValue) { return parents.some(isActive(currentValue)); },
                    getValue: function (currentValue) {
                        if (multiple && parents.some(isActive(currentValue)))
                            return;
                        var nodeValue = valueAccessor(item);
                        // Can add typeguards
                        return (!multiple
                            ? nodeValue
                            : isActive(currentValue, item)
                                ? reject(equals(nodeValue), currentValue || [])
                                : append(nodeValue, currentValue));
                    },
                    renderIcon: function (currentValue) {
                        return renderIcon
                            ? renderIcon({ item: item, value: currentValue })
                            : null;
                    }
                };
                if (expanded || (categorized && query.length === 0)) {
                    return __spreadArray([
                        node
                    ], getList(getChildren(item), expandedState, __spreadArray(__spreadArray([], parents, true), [item], false), nodePath, onlyExpanded), true);
                }
                else if (!onlyExpanded) {
                    return [node];
                }
                else if (onlyExpanded && expanded) {
                    return [node];
                }
                else {
                    return [];
                }
            });
        }
        return query.length > 0
            ? getList(data, expandedStateTree, [], [], true)
            : getList(data, expandedStateTree);
    }, [
        data,
        expandedStateTree,
        query,
        categorized,
        getChildNodes,
        multiple,
        labelAccessor,
        valueAccessor,
        renderIcon
    ]);
    // Because list is virtualized it doesnt respond well to resizing
    // In order to adjust when resizing we observe container and remount
    // the autoSizer when there is any change.
    // There is still room for improvement as it doesnt pick up on each resize.
    var _l = useResizeObserver(), ref = _l.ref, _m = _l.height, height = _m === void 0 ? 0 : _m;
    return (_jsxs(StyledContainer, __assign({ ref: ref, className: className }, { children: [isNotEmpty(data) && searchable
                && (_jsx("div", __assign({ className: 'select__searchbar' }, { children: _jsx(Searchbar, { onSubmit: function (value) {
                            setQuery(value);
                            updateExpandedStateTree(searchAll(data, value, initExpandedState));
                        } }) }))), actions && (_jsx(Actions, { children: actions })), onClear
                && (_jsx("ul", { children: _jsx("li", __assign({ "data-testid": 'select__clear', className: 'select__clearable', onClick: onClear }, { children: clearText })) })), _jsx(ListContext.Provider, __assign({ value: useMemo(function () { return ({
                    list: list,
                    onChange: onChange,
                    value: value
                }); }, [list, onChange, value]) }, { children: isNotEmpty(list) && isEmpty(list)
                    ? _jsx("ul", { children: _jsx("li", __assign({ className: 'select__clearable' }, { children: translate('Not found...') })) })
                    : isEmpty(list)
                        ? _jsx("ul", { children: _jsx("li", __assign({ className: 'select__clearable' }, { children: noDataText })) })
                        : list.length <= 10
                            ? (_jsx("ul", { children: list
                                    .map(function (_, index) { return _jsx(SelectOption, { index: index }, index); }) }))
                            : (_jsx("div", __assign({ style: styles.autoSizer }, { children: _jsx(AutoSizer, __assign({ style: styles.autoSizer }, { children: function (_a) {
                                        var sizerHeight = _a.height;
                                        return (_jsx(FixedSizeList, __assign({ innerElementType: 'ul', style: styles.virtualList, itemSize: ITEM_HEIGHT, height: sizerHeight, itemCount: list.length, width: '100%' }, { children: children }), height));
                                    } }), height) }))) }))] })));
}
function withoutValueAccessor(props) {
    return props.valueAccessor === undefined && !props.multiple;
}
function multipleWithValueAccessor(props) {
    return props.valueAccessor !== undefined && !!props.multiple;
}
function multipleWithoutValueAccessor(props) {
    return props.valueAccessor === undefined && !!props.multiple;
}
export function renderSelectList(props) {
    if (multipleWithoutValueAccessor(props)) {
        return _jsx(SelectList, __assign({}, props));
    }
    else if (multipleWithValueAccessor(props)) {
        return _jsx(SelectList, __assign({}, props));
    }
    else if (withoutValueAccessor(props)) {
        return _jsx(SelectList, __assign({}, props));
    }
    else {
        return _jsx(SelectList, __assign({}, props));
    }
}
