var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useState, useRef } from 'react';
import { equals } from 'ramda';
import { WarningModal, ConfirmModal, NoticeModal } from './variations';
var MODALS = {
    confirm: ConfirmModal,
    notice: NoticeModal,
    warning: WarningModal
};
var ModalContext = createContext({});
export function ModalProvider(_a) {
    var children = _a.children;
    var _b = useState('confirm'), modelType = _b[0], setType = _b[1];
    var _c = useState({}), props = _c[0], setProps = _c[1];
    var _d = useState(false), show = _d[0], setShow = _d[1];
    var resolve = useRef(function () {
        setShow(false);
    });
    var reject = useRef(function () {
        setShow(false);
    });
    var modal = function (type) {
        return function (initProps) {
            modelType !== type && setType(type);
            initProps && !equals(props, initProps) && setProps(initProps);
            !show && setShow(true);
            return {
                then: function (cb) {
                    resolve.current = function () {
                        setShow(false);
                        cb();
                    };
                    return this;
                },
                catch: function (cb) {
                    reject.current = function () {
                        setShow(false);
                        cb();
                    };
                }
            };
        };
    };
    var Modal = MODALS[modelType];
    return (_jsxs(ModalContext.Provider, __assign({ value: {
            setModal: modal,
            close: function () { return setShow(false); },
            resolve: resolve.current,
            reject: reject.current
        } }, { children: [children, show && _jsx(Modal, __assign({}, props))] })));
}
export function useModalActions() {
    var _a = useContext(ModalContext), resolve = _a.resolve, reject = _a.reject;
    return { resolve: resolve, reject: reject };
}
export function useModal() {
    var setModal = useContext(ModalContext).setModal;
    if (!setModal) {
        throw new Error('Modal can be used only within its root');
    }
    return {
        confirm: setModal('confirm'),
        notice: setModal('notice'),
        warning: setModal('warning')
    };
}
