import React, { useCallback } from 'react'
import { Field, Select, Label, TextArea, Checkbox } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { invoker, prop } from '@exivity/fp'
import { CrudRecord } from 'react-crud-hook'
import { queries, Resources, useCacheQuery, UserGroupPermission } from '@exivity/data-layer'

import { useAllowPermissions } from '../../../../components/organisms/Permission'

import { useNotificationInfo } from './useNotificationInfo'

const NO_FOLLOWING = [] as string[]
const ALL_SELECTED_TOKEN = '*'
const WORKFLOW_STATUS: {
  value: ('successful'|'failed')[]
  label: () => string
}[] = [
  { value: ['successful'], label: () => translate('Successful workflows') },
  { value: ['failed'], label: () => translate('Failed workflows') },
  { value: ['successful', 'failed'], label: () => translate('Successful and failed workflows') }
]

const getLabel = invoker(0, 'label')

const ALL_WORKFLOWS = [{
  id: ALL_SELECTED_TOKEN,
  attributes: {
    name: translate('Use all workflows')
  }
} as any as Resources['workflow']]

const getName = (item: Resources['workflow']) => item.attributes.name

const INIT_WORKFLOW_ENDED = {
  only_status: [],
  follow: [],
  files: {
    filename: [''],
    compress_attachments: false,
    edited_since_workflow_started: false
  }
}

interface WorkflowEndedNotificationProps {
  notification: (
    CrudRecord<Resources['notificationsubscription']>
  )
}

const expandRoot = {
  0: true
}

const getId = prop('id')

export function WorkflowEnded ({ notification }: WorkflowEndedNotificationProps) {
  const workflows = useCacheQuery(
    queries
      .findAll('workflow')
      .sortByAttribute('name', 'ascending')
  )
  const canManageFiles = useAllowPermissions(UserGroupPermission.ManageFiles)
  const [info, setInfo] = useNotificationInfo(
    notification,
    notification.id ? notification.attributes.info : INIT_WORKFLOW_ENDED
  )
  const following = info.follow || NO_FOLLOWING

  return (
    <>
      <Field>
        <Label.Group>
          <Label>{translate('Workflows')}</Label>
          <Label.Sub>{translate('Optionally trigger for specific workflows.')}</Label.Sub>
        </Label.Group>
        <Select
          required
          multiple
          searchable
          initExpandedState={expandRoot}
          value={following}
          data={ALL_WORKFLOWS}
          name={translate('Workflows')}
          labelAccessor={getName}
          valueAccessor={getId}
          getChildNodes={useCallback(({ id }) => (
            id === ALL_SELECTED_TOKEN
              ? workflows
              : []
          ), [workflows])}
          onChange={setInfo('follow')} />
      </Field>
      <Field>
        <Label.Group>
          <Label>{translate('Workflow status')}</Label>
          <Label.Sub>{translate('Limit trigger to successful or failed workflows, or both.')}</Label.Sub>
        </Label.Group>
        <Select
          required
          name={translate('Workflow status')}
          value={info.only_status}
          labelAccessor={getLabel}
          valueAccessor={prop('value')}
          data={WORKFLOW_STATUS}
          onChange={setInfo('only_status')} />
      </Field>
      <Field>
        <Label.Group>
          <Label>{translate('Filenames')}</Label>
          <Label.Sub>
            {translate('Use regular expressions to select files for export. Each expression on a new line.')}
          </Label.Sub>
        </Label.Group>
        <TextArea
          placeholder={translate('First expression\nSecond expression...\n')}
          disabled={!canManageFiles}
          value={(info.files?.filename || ['']).join('\n')}
          onBlur={() => {
            if (info.files?.filename?.length) {
              setInfo('files', {
                ...(info.files || INIT_WORKFLOW_ENDED.files),
                // filter out white space
                filename: info.files?.filename
                  ?.filter((option: string) => !/^\s*$/.test(option)) ?? []
              })
            }
          }}
          onChange={(value) => {
            setInfo('files', {
              ...(info.files || INIT_WORKFLOW_ENDED.files),
              filename: value.split('\n')
            })
          }} />
      </Field>
      <Field>
        <Label.Group>
          <Label>{translate('Since workflow start time')}</Label>
          <Label.Sub>
            {translate('Only add file to notification if file was created/modified after workflow start time.')}
          </Label.Sub>
        </Label.Group>
        <Checkbox
          disabled={!canManageFiles}
          label={translate('Enabled')}
          checked={info.files?.edited_since_workflow_started || false}
          onChange={(edited_since_workflow_started) => {
            setInfo('files', {
              ...(info.files || INIT_WORKFLOW_ENDED.files),
              edited_since_workflow_started
            })
          }} />
      </Field>
      <Field>
        <Label.Group>
          <Label>{translate('Compress attachments')}</Label>
        </Label.Group>
        <Checkbox
          disabled={!canManageFiles}
          label={translate('Enabled')}
          checked={info.files?.compress_attachments || false}
          onChange={(compress_attachments) => {
            setInfo('files', {
              ...(info.files || INIT_WORKFLOW_ENDED.files),
              compress_attachments
            })
          }} />
      </Field>
    </>
  )
}
