/* eslint-disable @typescript-eslint/no-var-requires */
import { Resources } from '@exivity/data-layer'
import { QueryBuilder } from '@orbit/data'
import { createSelector } from 'reselect'

import { accountLevelKeys } from '../data/types'

export interface SimplifiedAccount {
  id: string | null
  level: number
  name: string
  key: string
}

export const getAccountIdentifier = (
  account: Resources['account']
) => ({ type: 'account', id: account.id })

export const listAccounts = (accounts: Resources['account'][] = []) => {
  const list = accounts.map((item) => {
    const parent = (item.relationships
      && item.relationships.parent
      && item.relationships.parent.data
      && item.relationships.parent.data.id) || null
    return {
      ...item,
      key: item.id,
      parent: parent,
      attributes: item.attributes,
      relationships: item.relationships
    }
  })

  list.sort((a, b) => {
    if (a.key < b.key) {
      return -1
    }
    if (a.key > b.key) {
      return 1
    }
    return 0
  })

  return list
}

export const getAccount = createSelector<any, any, any>(
  getAccountIdentifier,
  (account) => {
    try {
      return require('../data/store').default
        .cache.query((q: any) => q.findRecord(account)) as Resources['account']
    } catch (e) {
      return null
    }
  }
)

// can optimise by caching results
// DETAILS TABLE BREADCRUMBS REPORT
export const parentAccounts = createSelector(
  getAccount,
  (account: Resources['account']): SimplifiedAccount[] => {
    const parents: SimplifiedAccount[] = []

    let next = account

    try {
      const query = (q: QueryBuilder) => q.findRelatedRecord(next, 'parent')

      while (next) {
        next = require('../data/store').default.cache.query(query) as Resources['account']

        if (next) {
          parents.push(
            simplifiedAccount(next)
          )
        }
      }
    } catch (e) {}

    return parents
  })

export const simplifiedAccount = (account: Resources['account'] | null): SimplifiedAccount => ({
  id: account?.id || '',
  level: account?.attributes.level || 1,
  name: account?.attributes.name || '',
  key: account?.attributes[accountLevelKeys[account.attributes.level]] || ''
})
