import Coordinator, {
  RequestStrategy,
  SyncStrategy
} from '@orbit/coordinator'

export function createSyncCoordinator (servers, memory) {
  // Create sync coordinator
  const syncCoordinator = new Coordinator({ sources: [memory, ...servers] })

  servers.forEach((server, index) => {
    // Add strategy for live queries
    if (index === 0) {
      syncCoordinator.addStrategy(new RequestStrategy({
        name: 'pull-from-server' + `-v${index}`,

        source: 'memory',
        on: 'beforeQuery',

        target: server.name,
        action: 'pull',

        blocking: true,

        catch () {
          // handler(error, 'Could not fetch data from API at this time.')
          this.source.requestQueue.skip()
          this.target.requestQueue.skip()
        }
      }))
    }

    // Add strategy to sync server to memory
    syncCoordinator.addStrategy(new SyncStrategy({
      name: 'sync-server-to-memory' + `-v${index}`,

      source: server.name,
      target: 'memory',

      blocking: true
    }))
  })

  return syncCoordinator
}
