var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from 'styled-components';
import { startOfQuarter, setQuarter, format, addMonths, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { CalendarMode } from '../CalendarMode';
import { StyledTimeUnits, TimeUnit } from '../shared';
export var StyledQuarters = styled(StyledTimeUnits)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  li {\n    width: calc(100% - 5px);\n  }\n"], ["\n  li {\n    width: calc(100% - 5px);\n  }\n"])));
export function Quarters(_a) {
    var _b = _a.value, start = _b[0], end = _b[1], browserDate = _a.browserDate, onHoverItem = _a.onHoverItem, onEndHoverItem = _a.onEndHoverItem, _c = _a.offset, offset = _c === void 0 ? 0 : _c, onChange = _a.onChange, min = _a.min, max = _a.max;
    var normalizedValue = useMemo(function () {
        return [
            offset && start ? subMonths(startOfMonth(start), offset) : start,
            offset && end ? subMonths(endOfMonth(end), offset) : end
        ];
    }, [start, end]);
    function renderQuarter(_, i) {
        var quarter = i + 1;
        var thisQuarter = startOfQuarter(setQuarter(browserDate, quarter));
        var quarterWithOffset = addMonths(thisQuarter, offset);
        return (_jsx("span", __assign({ "data-testid": 'calendar-quarters-quarter' }, { children: _jsx(TimeUnit, __assign({ date: thisQuarter, mode: CalendarMode.Quarters, value: normalizedValue, min: min, max: max, onHover: function () { return onHoverItem === null || onHoverItem === void 0 ? void 0 : onHoverItem(quarterWithOffset); }, onStopHovering: function () { return onEndHoverItem === null || onEndHoverItem === void 0 ? void 0 : onEndHoverItem(quarterWithOffset); }, onChange: function (_, ctrlPressed) { return onChange === null || onChange === void 0 ? void 0 : onChange(quarterWithOffset, ctrlPressed); } }, { children: format(thisQuarter, 'Qo') })) }), i));
    }
    return (_jsx(StyledQuarters, __assign({ "data-testid": 'calendar-quarters' }, { children: new Array(4).fill(null).map(renderQuarter) })));
}
var templateObject_1;
