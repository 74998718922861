var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Children, cloneElement } from 'react';
import ReactDOM from 'react-dom';
import { Overlay, Heading } from '../..';
import { ModalWrapper, Header, Body, Footer } from './ModalElements';
function ModalContent(_a) {
    var title = _a.title, children = _a.children, buttons = _a.buttons, onOutsideClick = _a.onOutsideClick, rest = __rest(_a, ["title", "children", "buttons", "onOutsideClick"]);
    var onOverlayClick = React.useCallback(function (e) {
        if (onOutsideClick && e.currentTarget === e.target) {
            onOutsideClick();
        }
    }, [onOutsideClick]);
    return (_jsx(Overlay, __assign({}, rest, { onClick: onOverlayClick }, { children: _jsxs(ModalWrapper, { children: [title && _jsx(Header, { children: typeof title === 'string' ? _jsx(Heading, { children: title }) : title }), _jsx(Body, { children: children }), buttons && (_jsx(Footer, { children: Children.map(buttons, function (child, index) { return cloneElement(child, __assign(__assign({}, child.props), { key: index })); }) }))] }) })));
}
/**
 * @deprecated use useModal instead
 */
export var initModal = function (root) { return function (_a) {
    var _b = _a.open, open = _b === void 0 ? false : _b, rest = __rest(_a, ["open"]);
    if (!open) {
        return null;
    }
    return ReactDOM.createPortal(_jsx(ModalContent, __assign({}, rest)), root);
}; };
