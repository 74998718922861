var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { fromTheme, themeColor, themeGlobal, themeSpace } from '../Theme';
import { toRgbString } from '../utils';
export var Widget = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  background: white;\n  box-shadow: 1px 1px 0 rgba(", ",0.4);\n  box-sizing: border-box;\n"], ["\n  height: 100%;\n  background: white;\n  box-shadow: 1px 1px 0 rgba(", ",0.4);\n  box-sizing: border-box;\n"])), fromTheme(function (theme) { return toRgbString(theme.global.purposes.brand); }));
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  width: 100%;\n"], ["\n  display: flex;\n  height: 100%;\n  width: 100%;\n"])));
export var Master = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  position: sticky;\n  top: 80px;\n  \n  min-width: ", "px;\n  max-width: ", "px;\n  height: ", ";\n  margin-right: ", ";\n \n @media all and (display-mode: fullscreen) {\n    height: ", "\n  }\n"], ["\n  box-sizing: border-box;\n  position: sticky;\n  top: 80px;\n  \n  min-width: ", "px;\n  max-width: ", "px;\n  height: ", ";\n  margin-right: ", ";\n \n @media all and (display-mode: fullscreen) {\n    height: ", "\n  }\n"])), function (_a) {
    var width = _a.width;
    return width || '325';
}, function (_a) {
    var width = _a.width;
    return width || '420';
}, themeGlobal('contentHeight'), themeSpace(1), themeGlobal('fullscreenContentHeight'));
export var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0 ", ";\n  height: 50px;\n  border-bottom: 1px solid ", ";\n  text-transform: uppercase;\n  color: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0 ", ";\n  height: 50px;\n  border-bottom: 1px solid ", ";\n  text-transform: uppercase;\n  color: ", ";\n"])), themeSpace(2), themeColor('lightGray'), themeColor('gray'));
export var StyledDetail = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  \n  & > ", " {\n    min-height: ", ";\n    padding: ", ";\n  }\n  \n  @media all and (display-mode: fullscreen) {\n    & > ", " {\n      min-height: ", "\n    }\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  \n  & > ", " {\n    min-height: ", ";\n    padding: ", ";\n  }\n  \n  @media all and (display-mode: fullscreen) {\n    & > ", " {\n      min-height: ", "\n    }\n  }\n"])), Widget, themeGlobal('contentHeight'), themeSpace(2), Widget, themeGlobal('fullscreenContentHeight'));
export function DetailWidget(_a) {
    var children = _a.children;
    return (_jsx(StyledDetail, { children: _jsx(Widget, { children: children }) }));
}
export function MasterWidget(_a) {
    var title = _a.title, width = _a.width, children = _a.children;
    return (_jsx(Master, __assign({ width: width }, { children: _jsxs(Widget, { children: [title && _jsx(Title, { children: title }), children] }) })));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
