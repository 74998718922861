var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { usePopper } from 'react-popper';
import { injectIf } from '../utils';
import { TooltipContent, ContentContainer } from './styles';
var OFFSET_FROM_TRIGGER = 8;
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), injectIf('referenceWidth', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      position: relative;\n    "], ["\n      position: relative;\n    "])))));
function useTimeout(cb, timeout) {
    var timer = useRef(null);
    var clear = function () {
        if (timer.current !== null) {
            clearTimeout(timer.current);
        }
    };
    return [
        function () {
            clear();
            timer.current = setTimeout(cb, timeout);
        },
        clear
    ];
}
var offsetModifier = {
    name: 'offset',
    options: {
        offset: [0, OFFSET_FROM_TRIGGER]
    }
};
export function Tooltip(_a) {
    var children = _a.children, content = _a.content, _b = _a.placement, placement = _b === void 0 ? 'top' : _b, _c = _a.referenceWidth, referenceWidth = _c === void 0 ? false : _c, _d = _a.timeout, timeout = _d === void 0 ? 0 : _d, className = _a.className;
    var _e = useState(null), referenceElement = _e[0], setReferenceElement = _e[1];
    var _f = useState(null), popperElement = _f[0], setPopperElement = _f[1];
    var _g = useState(false), open = _g[0], setOpen = _g[1];
    var _h = usePopper(referenceElement, popperElement, {
        placement: placement,
        modifiers: [offsetModifier]
    }), styles = _h.styles, attributes = _h.attributes;
    var _j = useTimeout(function () { return setOpen(false); }, timeout), setTimer = _j[0], clearTimer = _j[1];
    return (_jsxs(Container, __assign({ referenceWidth: referenceWidth }, { children: [_jsx("div", __assign({ ref: setReferenceElement, "data-testid": 'ex-tooltip-reference', style: { display: 'flex' }, onMouseEnter: function () {
                    clearTimer();
                    setOpen(true);
                }, onMouseLeave: setTimer }, { children: children })), open && content && (_jsx(ContentContainer, __assign({ ref: setPopperElement, style: styles.popper }, attributes.popper, { children: _jsx(TooltipContent, __assign({ "data-testid": 'custom-content', className: className }, { children: content })) })))] })));
}
var templateObject_1, templateObject_2;
