var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, createContext, useMemo } from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { fromTheme, themeSpace } from '../Theme';
import { withExtendableProps } from '../ExtendProps';
var FormContext = createContext({ stacked: false });
export function useFormContext() {
    return useContext(FormContext);
}
var StyledToolbar = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: sticky;\n\n  bottom: ", "em;\n  margin-top: ", "em;\n\n  padding: ", " 0;\n  background: repeating-linear-gradient(120deg, transparent 0,\n    ", " 1px, transparent 2px, transparent 4px);\n\n  &:not(:last-child) {\n    margin-bottom: ", "em;\n  }\n"], ["\n  position: sticky;\n\n  bottom: ", "em;\n  margin-top: ", "em;\n\n  padding: ", " 0;\n  background: repeating-linear-gradient(120deg, transparent 0,\n    ", " 1px, transparent 2px, transparent 4px);\n\n  &:not(:last-child) {\n    margin-bottom: ", "em;\n  }\n"])), fromTheme(function (theme) { return theme.global.baseSpacing; }), fromTheme(function (theme) { return theme.global.baseSpacing; }), themeSpace(1), fromTheme(function (theme) { return theme.colors.lightGray; }), fromTheme(function (theme) { return theme.global.baseSpacing; }));
var ToolContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  margin-top: -", ";\n\n  > * {\n    margin-top: ", ";\n    margin-right: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  margin-top: -", ";\n\n  > * {\n    margin-top: ", ";\n    margin-right: ", ";\n  }\n"])), themeSpace(1), themeSpace(1), themeSpace(1));
export function FormToolbar(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (_jsx(StyledToolbar, __assign({}, rest, { children: _jsx(ToolContainer, { children: children }) })));
}
function Base(props) {
    var _a = useResizeObserver(), ref = _a.ref, _b = _a.width, width = _b === void 0 ? 600 : _b;
    return (_jsx(FormContext.Provider, __assign({ value: useMemo(function () { return ({ stacked: width < 550 }); }, [width]) }, { children: _jsx("form", __assign({ ref: ref }, props)) })));
}
Base.Toolbar = FormToolbar;
var Form = withExtendableProps('Form')(Base);
Form.Toolbar = FormToolbar;
export { Form };
var templateObject_1, templateObject_2;
