var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { getDaysInMonth, setDate, getDay, startOfMonth } from 'date-fns';
import { globalFont } from '../../../utils';
import { CalendarMode } from '../CalendarMode';
import { StyledTimeUnits, TimeUnit, TIME_UNIT_TOTAL_HEIGHT } from '../shared';
export var StyledWeekDays = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  opacity: 0.6;\n  margin: 0;\n  padding: 10px 0;\n\n  li {\n    display: inline-block;\n    width: 14.28%;\n    text-align: center;\n  }\n"], ["\n  ", "\n  opacity: 0.6;\n  margin: 0;\n  padding: 10px 0;\n\n  li {\n    display: inline-block;\n    width: 14.28%;\n    text-align: center;\n  }\n"])), globalFont);
export var WeekDays = function () { return (_jsxs(StyledWeekDays, { children: [_jsx("li", { children: "Mo" }), _jsx("li", { children: "Tu" }), _jsx("li", { children: "We" }), _jsx("li", { children: "Th" }), _jsx("li", { children: "Fr" }), _jsx("li", { children: "Sa" }), _jsx("li", { children: "Su" })] })); };
var MAX_AMOUNT_OF_WEEKS = 6;
export var StyledDays = styled(StyledTimeUnits)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: ", "px;\n\n  li {\n    width: calc(14.28% - 4px);\n  }\n"], ["\n  height: ", "px;\n\n  li {\n    width: calc(14.28% - 4px);\n  }\n"])), MAX_AMOUNT_OF_WEEKS * TIME_UNIT_TOTAL_HEIGHT);
export function Days(_a) {
    var value = _a.value, browserDate = _a.browserDate, onHoverItem = _a.onHoverItem, onEndHoverItem = _a.onEndHoverItem, rest = __rest(_a, ["value", "browserDate", "onHoverItem", "onEndHoverItem"]);
    var weekdayOffset = getDay(startOfMonth(browserDate)) - 1;
    var nbOfPlaceholderDays = weekdayOffset < 0
        ? 7 + weekdayOffset
        : weekdayOffset;
    function renderDay(_, i) {
        var day = i + 1 - nbOfPlaceholderDays;
        var thisDay = setDate(browserDate, day);
        if (i < nbOfPlaceholderDays)
            return _jsx("li", { "data-testid": 'calendar-days-placeholder' }, i);
        return (_jsx("span", __assign({ "data-testid": 'calendar-days-day' }, { children: _jsx(TimeUnit, __assign({ date: thisDay, mode: CalendarMode.Days, value: value, onHover: onHoverItem, onStopHovering: onEndHoverItem }, rest, { children: String(day) })) }), i));
    }
    var nbOfDays = getDaysInMonth(browserDate) + nbOfPlaceholderDays;
    return (_jsxs(_Fragment, { children: [_jsx(WeekDays, {}), _jsx(StyledDays, __assign({ "data-testid": 'calendar-days' }, { children: new Array(nbOfDays).fill(null).map(renderDay) }))] }));
}
var templateObject_1, templateObject_2;
