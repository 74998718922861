var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { format as dateFormat } from 'date-fns';
import { useToggle } from '@exivity/hooks';
import { identity, juxt } from '@exivity/fp';
import { translate } from '@exivity/translations';
import { SelectInput } from '../Input/SelectInput';
import { ModePicker } from '../Calendar/ModePicker';
import { DateRangeCalendarV2 } from '../Calendar/DateRangeCalendarV2/DateRangeCalendarV2';
import { CalendarMode } from '../Calendar/CalendarModes';
import { useFieldContext } from '../Field/context';
import { withExtendableProps } from '../ExtendProps';
import { DatePickerDropDown } from './DatePickerDropdown';
function formatRange(range, format) {
    return range.map(function (date) { return dateFormat(date, format); }).join(' - ');
}
export function _DateRangePicker(_a) {
    var name = _a.name, _b = _a.format, format = _b === void 0 ? 'yyyyMMdd' : _b, _c = _a.placeholder, placeholder = _c === void 0 ? translate('Choose date') : _c, required = _a.required, disabled = _a.disabled, min = _a.min, max = _a.max, _d = _a.mode, mode = _d === void 0 ? CalendarMode.Months : _d, value = _a.value, onModeChange = _a.onModeChange, _e = _a.onChange, onChange = _e === void 0 ? identity : _e, onClear = _a.onClear;
    var _f = useToggle(false), isOpen = _f[0], toggleOpen = _f[1];
    var id = useFieldContext().id;
    var calendar = (_jsx(DateRangeCalendarV2, { mode: mode, value: value, min: min, max: max, onChange: function (date) {
            onChange(date);
            if (date[0] && date[1]) {
                toggleOpen();
            }
        }, onClear: onClear
            ? juxt([onClear, toggleOpen])
            : undefined }));
    return (_jsx(DatePickerDropDown, __assign({ disabled: disabled, reference: _jsx(SelectInput, { id: id, name: name, value: value
                ? formatRange(value, format)
                : '', placeholder: placeholder, required: required, disabled: disabled }), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: onModeChange
            ? (_jsx(ModePicker, __assign({ value: mode, onChange: onModeChange }, { children: calendar })))
            : calendar })));
}
export var DateRangePicker = withExtendableProps('DateRangePicker')(_DateRangePicker);
