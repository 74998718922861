import React, { useCallback } from 'react'
import { CrudRecord } from 'react-crud-hook'
import { translate } from '@exivity/translations'
import { Resources, queries, useCacheQuery, useCacheQueryFn } from '@exivity/data-layer'
import { Link, getPathname } from '@exivity/routing'
import {
  Field,
  Label,
  Select,
  Input,
  Adornment,
  Checkbox,
  Placeholder
} from '@exivity/ui'

import { app } from '../../../../routes'
import {
  useRemoveParentAccountIds,
  useSelectedChildAccountsIndicators
} from '../../../accounts/components'

import { useNotificationInfo } from './useNotificationInfo'

const ANY_BUDGET_TOKEN = '*'

const INIT_BUDGET_EVALUATED = {
  budget_id: '',
  account_ids: [ANY_BUDGET_TOKEN],
  only_once: false,
  threshold_percentage: 100
}

const getBudgetDescription = (budget: Resources['budget']) => budget.attributes.description
const getBudgetId = (budget: Resources['budget']) => budget.id

const getAccountId = (account: Resources['account']) => account.id
const getAccountName = (account: Resources['account']) => account.attributes.name

interface BudgetEvaluatedNotificationProps {
  notification: (
    CrudRecord<Resources['notificationsubscription']>
  )
}

const emptyArray: string[] = []

export function BudgetEvaluated ({ notification }: BudgetEvaluatedNotificationProps) {
  const [info, setInfoField] = useNotificationInfo(
    notification,
    notification.id ? notification.attributes.info : INIT_BUDGET_EVALUATED
  )

  const budgets = useCacheQuery(queries.findAll('budget'))
  const report = useCacheQuery(queries.relationshipOf('budget', info.budget_id, 'report'))
  const rootAccounts = useCacheQuery(
    queries.relationshipOf('report', report, 'accounts')
      .filterByAttribute('level', 'equal', 1)
  )
  const cacheQuery = useCacheQueryFn()

  const onChangeAccounts = useCallback((accountIds: string[]) => {
    setInfoField('account_ids', accountIds)
  }, [setInfoField])

  const getChildNodes = useCallback((account) => (
    cacheQuery(queries.relationshipOf('account', account, 'children')) || []
  ), [cacheQuery])

  const renderMoreIcon = useSelectedChildAccountsIndicators(info.account_ids || emptyArray)
  const removeParentIds = useRemoveParentAccountIds()

  if (!budgets.length) {
    return (
      <Placeholder>
        {translate('Unable to configure budget evaluated notification. No budgets defined.')}{' '}
        <Link to={getPathname(app.routes.accounts.routes.budgets)}>{translate('Create a new budget.')}</Link>
      </Placeholder>
    )
  }

  return (
    <>
      <Field>
        <Label>{translate('Budget')}</Label>
        <Select
          required
          data={budgets}
          valueAccessor={getBudgetId}
          labelAccessor={getBudgetDescription}
          value={info.budget_id}
          onChange={setInfoField('budget_id')} />
      </Field>

      <Field>
        <Label.Group>
          <Label>{translate('Only specific accounts')}</Label>
          <Label.Sub>{translate('When enabled, a subset of all accounts in this budget can be selected.')}</Label.Sub>
        </Label.Group>
        <Checkbox
          checked={!info.account_ids?.includes(ANY_BUDGET_TOKEN)}
          label={translate('Enabled')}
          onChange={(enabled) => {
            setInfoField('account_ids', enabled ? [] : [ANY_BUDGET_TOKEN])
          }} />
      </Field>

      {!info.account_ids?.includes(ANY_BUDGET_TOKEN) && (
        <Field>
          <Label>{translate('Accounts')}</Label>
          <Select
            multiple
            renderIcon={renderMoreIcon}
            disabled={!info.budget_id}
            data={rootAccounts}
            value={info.account_ids || emptyArray}
            valueAccessor={getAccountId}
            labelAccessor={getAccountName}
            getChildNodes={getChildNodes}
            onChange={(value) => onChangeAccounts(removeParentIds(value))} />
        </Field>
      )}

      <Field>
        <Label>{translate('Threshold')}</Label>
        <Adornment right='%'>
          <Input
            type='number'
            min={0}
            value={info.threshold_percentage ?? 0}
            onChange={(value) => setInfoField('threshold_percentage', Number(value))} />
        </Adornment>
      </Field>

      <Field>
        <Label.Group>
          <Label>{translate('Only once')}</Label>
          <Label.Sub>{translate('Only send out a notification the first time a budget has passed the treshold, as opposed to every time it is evaluated.')}</Label.Sub>
        </Label.Group>
        <Checkbox
          checked={info?.only_once || false}
          label={translate('Enabled')}
          onChange={setInfoField('only_once')} />
      </Field>
    </>
  )
}
