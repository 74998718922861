import { Resources } from '@exivity/data-layer'
import { useEffect, useCallback } from 'react'
import { CrudRecord } from 'react-crud-hook'

type Info = Resources['notificationsubscription']['attributes']['info']

interface SetInfo {
  <K extends keyof Info>(key: K, value: Info[K]): void
  <K extends keyof Info>(key: K): (value: Info[K]) => void
}

export function useNotificationInfo (
  notification: CrudRecord<Resources['notificationsubscription']>,
  initValue: Partial<Info>
) {
  useEffect(() => {
    notification.setAttribute('info', initValue)
  }, [notification.attributes.type, initValue])

  const setInfoField = useCallback((key: string, value?: any) => {
    function _setInfoField (key: string, value: any) {
      notification.setAttribute('info', { ...notification.attributes.info || {}, [key]: value })
    }

    return value !== undefined
      ? _setInfoField(key, value)
      : (value: any) => _setInfoField(key, value)
  }, [notification]) as SetInfo

  return [notification.attributes.info, setInfoField] as const
}
