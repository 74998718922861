"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepend = void 0;
var ramda_1 = require("ramda");
/**
 * @description a → [a] → [a]
 */
exports.prepend = (0, ramda_1.curry)(function (a, fa) { return __spreadArray([a], fa, true); });
