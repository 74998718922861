var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
export var Delay = function (_a) {
    var wait = _a.wait, children = _a.children;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var isMounted = useRef(null);
    useEffect(function () {
        var timeout = setTimeout(function () {
            isMounted.current && setShow(true);
        }, wait);
        return function () { return clearTimeout(timeout); };
    }, [wait]);
    return _jsx("div", __assign({ ref: isMounted }, { children: show ? children : null }));
};
