import { logError, time, warn } from '../../utils/log'
import { getUserSessionStorage } from '../../store/storage'
import { authActions } from '../../domains/auth/state'
import config from '../../application/config'
import { configurationSelectors } from '../../domains/administration/state/configuration'
import reduxStore from '../../store'
import memory from '../store'

import importWorker from './loadImportWorker'

const before = () => {
  // @ts-ignore
  memory.disableProcessors()
}

const after = () => {
  // @ts-ignore
  memory.enableProcessors()
}

importWorker.onerror = console.error

// @ts-ignore
export default function importSyncer (thread, options) {
  // @ts-ignore
  const promises = []
  // @ts-ignore
  let currentResourceName

  return new Promise((resolve, reject) => {
    // Prepare store
    before()

    // Setup listener
    importWorker.onmessage = (workerMessage) => {
      const { type, data } = workerMessage.data

      switch (type) {
        // We received a set of transforms
        case 'transform': {
          config.app.profiler && window.performance.mark('Start sync')

          const promise = memory
            .sync(data)
            .then(() => {
              config.app.profiler && window.performance.mark('End sync')
              config.app.profiler
                && window
                  .performance
                // @ts-ignore
                  .measure(`Sync ${currentResourceName}`, 'Start sync', 'End sync')
            })
            .catch(error => {
              reject(new Error(`Can't apply new data (${error.message}).`))
            })
          promises.push(promise)
          break
        }

        // Last transform set has been received, so we can now resolve the wrapper promise
        case 'done':
          // @ts-ignore
          Promise.all(promises)
            .then(() => {
              after()

              config.app.profiler && window.performance.mark('End import')
              config.app.profiler
                && window.performance.measure('Import', 'Start import', 'End import')

              time('Importing data')
              // @ts-ignore
              resolve()
            })
          break

        // Something went wrong in the worker
        case 'error':
          logError(workerMessage.data.errorMessage, workerMessage)
          after()
          reject(new Error(data))
          break

        case 'warn':
          warn(workerMessage.data.errorMessage, workerMessage)
          after()
          reject(new Error(data))
          break

        case 'authError':
          after()
          reduxStore.dispatch(authActions.updateIsAuthenticated(false))
          break

        default:
          after()
          reject(new Error('Unsupported message received from worker: ' + type))
          break
      }
    }

    config.app.profiler && window.performance.mark('Start import')
    time('Importing data')
    // Tell web worker to start pulling data
    importWorker.postMessage({ type: 'reset' })
    importWorker.postMessage({
      type: 'config',
      data: configurationSelectors.getImportWorkerConfiguration(reduxStore.getState())
    })
    importWorker.postMessage({
      type: 'token',
      data: getUserSessionStorage.fromEitherStorage('session')?.token ?? ''
    })
    importWorker.postMessage({ type: 'options', data: options })
    importWorker.postMessage({ type: 'import' })
  })
}
