var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from 'styled-components';
import { setYear, getYear, startOfYear, format, addMonths, addYears, subMonths } from 'date-fns';
import { StyledTimeUnits, TimeUnit } from '../shared';
import { CalendarMode } from '../CalendarMode';
export var StyledYears = styled(StyledTimeUnits)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  li {\n    width: calc(33.33% - 5px);\n  }\n"], ["\n  li {\n    width: calc(33.33% - 5px);\n  }\n"])));
export function Years(_a) {
    var _b = _a.value, start = _b[0], end = _b[1], browserDate = _a.browserDate, onHoverItem = _a.onHoverItem, onEndHoverItem = _a.onEndHoverItem, _c = _a.offset, offset = _c === void 0 ? 0 : _c, onChange = _a.onChange, min = _a.min, max = _a.max;
    var normalizedValue = useMemo(function () {
        return [
            offset && start ? subMonths(start, offset) : start,
            offset && end ? subMonths(end, offset) : end
        ];
    }, [start, end]);
    function renderYear(_, i) {
        var browseYearMiddle = getYear(browserDate);
        var year = i <= 6
            ? browseYearMiddle - (6 - i)
            : browseYearMiddle + (i - 6);
        var thisYear = startOfYear(setYear(browserDate, year));
        var yearWithOffset = addMonths(thisYear, offset);
        return (_jsx("span", __assign({ "data-testid": 'calendar-years-year' }, { children: _jsx(TimeUnit, __assign({ date: thisYear, mode: CalendarMode.Years, value: normalizedValue, min: min, max: max, onHover: function () { return onHoverItem === null || onHoverItem === void 0 ? void 0 : onHoverItem(yearWithOffset); }, onStopHovering: function () { return onEndHoverItem === null || onEndHoverItem === void 0 ? void 0 : onEndHoverItem(yearWithOffset); }, onChange: function (_, ctrlPressed) { return onChange === null || onChange === void 0 ? void 0 : onChange(yearWithOffset, ctrlPressed); } }, { children: offset > 0
                    ? format(thisYear, 'yyyy') + ' - ' + format(addYears(thisYear, 1), 'yyyy')
                    : format(thisYear, 'yyyy') })) }), i));
    }
    return (_jsx(StyledYears, __assign({ "data-testid": 'calendar-years' }, { children: new Array(12).fill(null).map(renderYear) })));
}
var templateObject_1;
