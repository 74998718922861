var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { juxt } from '@exivity/fp';
import { useFieldContext } from '../Field/context';
import { outlined, regular } from '../Input/StyledInput';
import { useValidState } from '../Input';
export var StyledTextArea = styled.textarea(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  ", "\n  \n  min-height: 8.5em;\n  ", "\n"], ["\n  ", "\n  ", "\n  \n  min-height: 8.5em;\n  ", "\n"])), regular, outlined, function (props) { return props.width && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: ", ";\n  "], ["\n    width: ", ";\n  "])), props.width); });
export function TextArea(_a) {
    var onChange = _a.onChange, _b = _a.rows, rows = _b === void 0 ? 5 : _b, rest = __rest(_a, ["onChange", "rows"]);
    var id = useFieldContext().id;
    var validityProps = useValidState();
    var inputOnChange = function (e) {
        onChange && onChange(e.target.value, e);
    };
    return (_jsx(StyledTextArea, __assign({ id: id, name: id, rows: rows }, validityProps, { onChange: juxt([
            inputOnChange,
            validityProps.onChange
        ]) }, rest)));
}
var templateObject_1, templateObject_2;
