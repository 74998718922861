import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { queries, useCacheQuery, useCacheQueryFn } from './index';
const createAccountKeyAttribute = (level) => `lvl${level}_key`;
const initContext = {
    lvl1Accounts: [],
    getChildAccounts: () => [],
    sortAccountsBy: () => { }
};
const AccountsContext = createContext(initContext);
export const AccountsProvider = ({ reportId, children }) => {
    const cache = useRef({});
    const [sortBy, setSortBy] = useState('name');
    const queryFn = useCacheQueryFn();
    const lvl1Accounts = useCacheQuery(queries
        .relationshipOf('report', reportId, 'accounts')
        .filterByAttribute('level', 'equal', 1)
        .sortByAttribute(sortBy === 'key'
        ? createAccountKeyAttribute(1)
        : 'name', 'ascending'));
    const getChildAccounts = useCallback((account) => {
        cache.current[account.id + sortBy] = cache.current[account.id + sortBy]
            || queryFn(queries
                .relationshipOf('account', account.id, 'children')
                .sortByAttribute(sortBy === 'key'
                ? createAccountKeyAttribute(account.attributes.level + 1)
                : 'name', 'ascending'));
        return cache.current[account.id + sortBy];
    }, [queryFn, sortBy]);
    return (React.createElement(AccountsContext.Provider, { value: {
            lvl1Accounts,
            getChildAccounts,
            sortAccountsBy: useCallback(() => {
                setSortBy(prev => prev === 'name' ? 'key' : 'name');
            }, [])
        } }, children));
};
export function useReportAccounts() {
    return useContext(AccountsContext);
}
