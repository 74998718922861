import React, { useState } from 'react'
import { Layout, Placeholder } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { useParams } from '@exivity/routing'

import { useFetchAllRecords } from '../../../../components/hooks/useFetchAllRecords'
import DropdownWithSelect from '../../../../components/molecules/Dropdown/WithSelect'
import { useToggle } from '../../../../components/hooks/useToggle'
import { ShowServices } from '../../components/ServiceList/listServices'
import ServiceSelectList from '../../components/ServiceList'
import CreateService from '../../components/CreateService'
import Service from '../../components/Service'
import './index.css'

interface ServiceDetailProps {
  id: string | null
  useEditToggle: [boolean, () => void, (toggle: boolean) => void]
}

const INCLUDE_METADATA_DEFINITION = {
  sources: {
    server: {
      include: ['metadatadefinition']
    }
  }
}

function ServiceDetail ({ id, useEditToggle }: ServiceDetailProps) {
  if (id === 'new') {
    return <CreateService />
  }

  if (id) {
    return <Service serviceId={id} useEditToggle={useEditToggle} />
  }

  return (
    <div>
      <Placeholder>
        {translate('Select service')}
      </Placeholder>
    </div>
  )
}

export function Overview () {
  const [showServices, setShowServices] = useState(ShowServices.report)
  const useEditToggle = useToggle(false)
  const [params] = useParams()

  useFetchAllRecords('service')
  useFetchAllRecords(
    'dset',
    INCLUDE_METADATA_DEFINITION
  )

  const ALL_SERVICES = translate('All services')
  const REPORT_SERVICES = translate('Report services')
  const OPRHANS_ONLY = translate('Orphans only')

  const header = (
    <DropdownWithSelect label='Show'
      initialSelected={showServices}
      items={[
        { key: ShowServices.all, value: ALL_SERVICES },
        { key: ShowServices.report, value: REPORT_SERVICES },
        { key: ShowServices.orphans, value: OPRHANS_ONLY }
      ]}
      onChange={({ key }: { key: ShowServices }) => setShowServices(key)} />
  )

  return (
    <Layout.MasterDetail>
      <ServiceSelectList show={showServices} useEditToggle={useEditToggle} header={header} />
      <ServiceDetail id={params.id} useEditToggle={useEditToggle} />
    </Layout.MasterDetail>
  )
}

export default Overview
