var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSpring, animated } from '@react-spring/web';
import styled from 'styled-components';
import { themePurpose } from '../Theme';
var StyledStatus = styled(animated.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 3px;\n  background-color: ", ";\n"], ["\n  height: 3px;\n  background-color: ", ";\n"])), themePurpose('brand'));
var StyledBar = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 3px;\n  width: 100%;\n"], ["\n  height: 3px;\n  width: 100%;\n"])));
export function ProgressBar(_a) {
    var className = _a.className, _b = _a.percentage, percentage = _b === void 0 ? 0 : _b;
    var props = useSpring({ width: "".concat(percentage, "%") });
    return (_jsx(StyledBar, __assign({ className: className, style: { height: 3, width: '100%' } }, { children: _jsx(StyledStatus, { style: props }) })));
}
var templateObject_1, templateObject_2;
