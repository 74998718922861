var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PARENT } from './data';
import { getChildren } from './traverse';
import { listToTreeMap } from './map';
export function treeMapToTreeList(map) {
    return Object.values(map).reduce(function (list, item) {
        return item[PARENT]
            ? list
            : list.concat(__spreadArray([item], getChildren(item), true));
    }, []);
}
export function listToTreeList(list, keyAccessor, parentKeyAccessor) {
    var treeMap = listToTreeMap(list, keyAccessor, parentKeyAccessor);
    return treeMapToTreeList(treeMap);
}
