"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.join = exports.isNil = exports.isEmpty = exports.invoker = exports.init = exports.into = exports.intersperse = exports.includes = exports.indexOf = exports.ifElse = exports.identity = exports.head = exports.gte = exports.fromPairs = exports.gt = exports.forEach = exports.flip = exports.flatten = exports.findIndex = exports.find = exports.evolve = exports.equals = exports.divide = exports.dissocPath = exports.dissoc = exports.differenceWith = exports.difference = exports.dropWhile = exports.dropLast = exports.concat = exports.cond = exports.curry = exports.compose = exports.comparator = exports.complement = exports.chain = exports.descend = exports.both = exports.assocPath = exports.assoc = exports.ascend = exports.anyPass = exports.any = exports.append = exports.always = exports.applyTo = exports.allPass = exports.adjust = exports.addIndex = exports.add = void 0;
exports.tap = exports.take = exports.sum = exports.subtract = exports.startsWith = exports.splitAt = exports.split = exports.sort = exports.slice = exports.set = exports.reverse = exports.replace = exports.reject = exports.reduceWhile = exports.reduceRight = exports.range = exports.propSatisfies = exports.propEq = exports.prop = exports.Rpipe = exports.pick = exports.partition = exports.path = exports.over = exports.or = exports.omit = exports.nth = exports.none = exports.not = exports.negate = exports.min = exports.mergeWith = exports.mergeDeepLeft = exports.mergeAll = exports.max = exports.match = exports.mapObjIndexed = exports.mapAccumRight = exports.mapAccum = exports.map = exports.lt = exports.liftN = exports.lift = exports.lensPath = exports.lensProp = exports.lensIndex = exports.lens = exports.length = exports.last = exports.juxt = void 0;
exports.__ = exports.zipWith = exports.without = exports.whereEq = exports.when = exports.view = exports.values = exports.unless = exports.unfold = exports.uniqBy = exports.uniq = exports.trim = exports.traverse = exports.toUpper = exports.toLower = exports.thunkify = exports.test = exports.tail = void 0;
var ramda_1 = require("ramda");
Object.defineProperty(exports, "add", { enumerable: true, get: function () { return ramda_1.add; } });
Object.defineProperty(exports, "addIndex", { enumerable: true, get: function () { return ramda_1.addIndex; } });
Object.defineProperty(exports, "adjust", { enumerable: true, get: function () { return ramda_1.adjust; } });
Object.defineProperty(exports, "allPass", { enumerable: true, get: function () { return ramda_1.allPass; } });
Object.defineProperty(exports, "applyTo", { enumerable: true, get: function () { return ramda_1.applyTo; } });
Object.defineProperty(exports, "always", { enumerable: true, get: function () { return ramda_1.always; } });
Object.defineProperty(exports, "append", { enumerable: true, get: function () { return ramda_1.append; } });
Object.defineProperty(exports, "any", { enumerable: true, get: function () { return ramda_1.any; } });
Object.defineProperty(exports, "anyPass", { enumerable: true, get: function () { return ramda_1.anyPass; } });
Object.defineProperty(exports, "ascend", { enumerable: true, get: function () { return ramda_1.ascend; } });
Object.defineProperty(exports, "assoc", { enumerable: true, get: function () { return ramda_1.assoc; } });
Object.defineProperty(exports, "assocPath", { enumerable: true, get: function () { return ramda_1.assocPath; } });
Object.defineProperty(exports, "both", { enumerable: true, get: function () { return ramda_1.both; } });
Object.defineProperty(exports, "descend", { enumerable: true, get: function () { return ramda_1.descend; } });
Object.defineProperty(exports, "chain", { enumerable: true, get: function () { return ramda_1.chain; } });
Object.defineProperty(exports, "complement", { enumerable: true, get: function () { return ramda_1.complement; } });
Object.defineProperty(exports, "comparator", { enumerable: true, get: function () { return ramda_1.comparator; } });
Object.defineProperty(exports, "compose", { enumerable: true, get: function () { return ramda_1.compose; } });
Object.defineProperty(exports, "curry", { enumerable: true, get: function () { return ramda_1.curry; } });
Object.defineProperty(exports, "cond", { enumerable: true, get: function () { return ramda_1.cond; } });
Object.defineProperty(exports, "concat", { enumerable: true, get: function () { return ramda_1.concat; } });
Object.defineProperty(exports, "dropLast", { enumerable: true, get: function () { return ramda_1.dropLast; } });
Object.defineProperty(exports, "dropWhile", { enumerable: true, get: function () { return ramda_1.dropWhile; } });
Object.defineProperty(exports, "difference", { enumerable: true, get: function () { return ramda_1.difference; } });
Object.defineProperty(exports, "differenceWith", { enumerable: true, get: function () { return ramda_1.differenceWith; } });
Object.defineProperty(exports, "dissoc", { enumerable: true, get: function () { return ramda_1.dissoc; } });
Object.defineProperty(exports, "dissocPath", { enumerable: true, get: function () { return ramda_1.dissocPath; } });
Object.defineProperty(exports, "divide", { enumerable: true, get: function () { return ramda_1.divide; } });
Object.defineProperty(exports, "equals", { enumerable: true, get: function () { return ramda_1.equals; } });
Object.defineProperty(exports, "evolve", { enumerable: true, get: function () { return ramda_1.evolve; } });
Object.defineProperty(exports, "find", { enumerable: true, get: function () { return ramda_1.find; } });
Object.defineProperty(exports, "findIndex", { enumerable: true, get: function () { return ramda_1.findIndex; } });
Object.defineProperty(exports, "flatten", { enumerable: true, get: function () { return ramda_1.flatten; } });
Object.defineProperty(exports, "flip", { enumerable: true, get: function () { return ramda_1.flip; } });
Object.defineProperty(exports, "forEach", { enumerable: true, get: function () { return ramda_1.forEach; } });
Object.defineProperty(exports, "gt", { enumerable: true, get: function () { return ramda_1.gt; } });
Object.defineProperty(exports, "fromPairs", { enumerable: true, get: function () { return ramda_1.fromPairs; } });
Object.defineProperty(exports, "gte", { enumerable: true, get: function () { return ramda_1.gte; } });
Object.defineProperty(exports, "head", { enumerable: true, get: function () { return ramda_1.head; } });
Object.defineProperty(exports, "identity", { enumerable: true, get: function () { return ramda_1.identity; } });
Object.defineProperty(exports, "ifElse", { enumerable: true, get: function () { return ramda_1.ifElse; } });
Object.defineProperty(exports, "indexOf", { enumerable: true, get: function () { return ramda_1.indexOf; } });
Object.defineProperty(exports, "includes", { enumerable: true, get: function () { return ramda_1.includes; } });
Object.defineProperty(exports, "intersperse", { enumerable: true, get: function () { return ramda_1.intersperse; } });
Object.defineProperty(exports, "into", { enumerable: true, get: function () { return ramda_1.into; } });
Object.defineProperty(exports, "init", { enumerable: true, get: function () { return ramda_1.init; } });
Object.defineProperty(exports, "invoker", { enumerable: true, get: function () { return ramda_1.invoker; } });
Object.defineProperty(exports, "isEmpty", { enumerable: true, get: function () { return ramda_1.isEmpty; } });
Object.defineProperty(exports, "isNil", { enumerable: true, get: function () { return ramda_1.isNil; } });
Object.defineProperty(exports, "join", { enumerable: true, get: function () { return ramda_1.join; } });
Object.defineProperty(exports, "juxt", { enumerable: true, get: function () { return ramda_1.juxt; } });
Object.defineProperty(exports, "last", { enumerable: true, get: function () { return ramda_1.last; } });
Object.defineProperty(exports, "length", { enumerable: true, get: function () { return ramda_1.length; } });
Object.defineProperty(exports, "lens", { enumerable: true, get: function () { return ramda_1.lens; } });
Object.defineProperty(exports, "lensIndex", { enumerable: true, get: function () { return ramda_1.lensIndex; } });
Object.defineProperty(exports, "lensProp", { enumerable: true, get: function () { return ramda_1.lensProp; } });
Object.defineProperty(exports, "lensPath", { enumerable: true, get: function () { return ramda_1.lensPath; } });
Object.defineProperty(exports, "lift", { enumerable: true, get: function () { return ramda_1.lift; } });
Object.defineProperty(exports, "liftN", { enumerable: true, get: function () { return ramda_1.liftN; } });
Object.defineProperty(exports, "lt", { enumerable: true, get: function () { return ramda_1.lt; } });
Object.defineProperty(exports, "map", { enumerable: true, get: function () { return ramda_1.map; } });
Object.defineProperty(exports, "mapAccum", { enumerable: true, get: function () { return ramda_1.mapAccum; } });
Object.defineProperty(exports, "mapAccumRight", { enumerable: true, get: function () { return ramda_1.mapAccumRight; } });
Object.defineProperty(exports, "mapObjIndexed", { enumerable: true, get: function () { return ramda_1.mapObjIndexed; } });
Object.defineProperty(exports, "match", { enumerable: true, get: function () { return ramda_1.match; } });
Object.defineProperty(exports, "max", { enumerable: true, get: function () { return ramda_1.max; } });
Object.defineProperty(exports, "mergeAll", { enumerable: true, get: function () { return ramda_1.mergeAll; } });
Object.defineProperty(exports, "mergeDeepLeft", { enumerable: true, get: function () { return ramda_1.mergeDeepLeft; } });
Object.defineProperty(exports, "mergeWith", { enumerable: true, get: function () { return ramda_1.mergeWith; } });
Object.defineProperty(exports, "min", { enumerable: true, get: function () { return ramda_1.min; } });
Object.defineProperty(exports, "negate", { enumerable: true, get: function () { return ramda_1.negate; } });
Object.defineProperty(exports, "not", { enumerable: true, get: function () { return ramda_1.not; } });
Object.defineProperty(exports, "none", { enumerable: true, get: function () { return ramda_1.none; } });
Object.defineProperty(exports, "nth", { enumerable: true, get: function () { return ramda_1.nth; } });
Object.defineProperty(exports, "omit", { enumerable: true, get: function () { return ramda_1.omit; } });
Object.defineProperty(exports, "or", { enumerable: true, get: function () { return ramda_1.or; } });
Object.defineProperty(exports, "over", { enumerable: true, get: function () { return ramda_1.over; } });
Object.defineProperty(exports, "path", { enumerable: true, get: function () { return ramda_1.path; } });
Object.defineProperty(exports, "partition", { enumerable: true, get: function () { return ramda_1.partition; } });
Object.defineProperty(exports, "pick", { enumerable: true, get: function () { return ramda_1.pick; } });
Object.defineProperty(exports, "Rpipe", { enumerable: true, get: function () { return ramda_1.pipe; } });
Object.defineProperty(exports, "prop", { enumerable: true, get: function () { return ramda_1.prop; } });
Object.defineProperty(exports, "propEq", { enumerable: true, get: function () { return ramda_1.propEq; } });
Object.defineProperty(exports, "propSatisfies", { enumerable: true, get: function () { return ramda_1.propSatisfies; } });
Object.defineProperty(exports, "range", { enumerable: true, get: function () { return ramda_1.range; } });
Object.defineProperty(exports, "reduceRight", { enumerable: true, get: function () { return ramda_1.reduceRight; } });
Object.defineProperty(exports, "reduceWhile", { enumerable: true, get: function () { return ramda_1.reduceWhile; } });
Object.defineProperty(exports, "reject", { enumerable: true, get: function () { return ramda_1.reject; } });
Object.defineProperty(exports, "replace", { enumerable: true, get: function () { return ramda_1.replace; } });
Object.defineProperty(exports, "reverse", { enumerable: true, get: function () { return ramda_1.reverse; } });
Object.defineProperty(exports, "set", { enumerable: true, get: function () { return ramda_1.set; } });
Object.defineProperty(exports, "slice", { enumerable: true, get: function () { return ramda_1.slice; } });
Object.defineProperty(exports, "sort", { enumerable: true, get: function () { return ramda_1.sort; } });
Object.defineProperty(exports, "split", { enumerable: true, get: function () { return ramda_1.split; } });
Object.defineProperty(exports, "splitAt", { enumerable: true, get: function () { return ramda_1.splitAt; } });
Object.defineProperty(exports, "startsWith", { enumerable: true, get: function () { return ramda_1.startsWith; } });
Object.defineProperty(exports, "subtract", { enumerable: true, get: function () { return ramda_1.subtract; } });
Object.defineProperty(exports, "sum", { enumerable: true, get: function () { return ramda_1.sum; } });
Object.defineProperty(exports, "take", { enumerable: true, get: function () { return ramda_1.take; } });
Object.defineProperty(exports, "tap", { enumerable: true, get: function () { return ramda_1.tap; } });
Object.defineProperty(exports, "tail", { enumerable: true, get: function () { return ramda_1.tail; } });
Object.defineProperty(exports, "test", { enumerable: true, get: function () { return ramda_1.test; } });
Object.defineProperty(exports, "thunkify", { enumerable: true, get: function () { return ramda_1.thunkify; } });
Object.defineProperty(exports, "toLower", { enumerable: true, get: function () { return ramda_1.toLower; } });
Object.defineProperty(exports, "toUpper", { enumerable: true, get: function () { return ramda_1.toUpper; } });
Object.defineProperty(exports, "traverse", { enumerable: true, get: function () { return ramda_1.traverse; } });
Object.defineProperty(exports, "trim", { enumerable: true, get: function () { return ramda_1.trim; } });
Object.defineProperty(exports, "uniq", { enumerable: true, get: function () { return ramda_1.uniq; } });
Object.defineProperty(exports, "uniqBy", { enumerable: true, get: function () { return ramda_1.uniqBy; } });
Object.defineProperty(exports, "unfold", { enumerable: true, get: function () { return ramda_1.unfold; } });
Object.defineProperty(exports, "unless", { enumerable: true, get: function () { return ramda_1.unless; } });
Object.defineProperty(exports, "values", { enumerable: true, get: function () { return ramda_1.values; } });
Object.defineProperty(exports, "view", { enumerable: true, get: function () { return ramda_1.view; } });
Object.defineProperty(exports, "when", { enumerable: true, get: function () { return ramda_1.when; } });
Object.defineProperty(exports, "whereEq", { enumerable: true, get: function () { return ramda_1.whereEq; } });
Object.defineProperty(exports, "without", { enumerable: true, get: function () { return ramda_1.without; } });
Object.defineProperty(exports, "zipWith", { enumerable: true, get: function () { return ramda_1.zipWith; } });
Object.defineProperty(exports, "__", { enumerable: true, get: function () { return ramda_1.__; } });
__exportStar(require("./DOM"), exports);
__exportStar(require("./compose1"), exports);
__exportStar(require("./composeAsync"), exports);
__exportStar(require("./exists"), exports);
__exportStar(require("./filter"), exports);
__exportStar(require("./flatMap"), exports);
__exportStar(require("./id"), exports);
__exportStar(require("./entities"), exports);
__exportStar(require("./arrayFrom"), exports);
__exportStar(require("./partitionBy"), exports);
__exportStar(require("./pipe"), exports);
__exportStar(require("./pipeAsync"), exports);
__exportStar(require("./prepend"), exports);
__exportStar(require("./reduce"), exports);
__exportStar(require("./rejectIsNil"), exports);
__exportStar(require("./Tree"), exports);
__exportStar(require("./unlessIsNil"), exports);
__exportStar(require("./update"), exports);
