var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Flex } from '../Flex';
import { themeColor, themePalette, _themeFontFamily, _themeFontSize, _themeFontWeight } from '../Theme';
export var InfoCard = function (_a) {
    var subtitle = _a.subtitle, title = _a.title, children = _a.children, addon = _a.addon;
    return (_jsxs(StyledInfoCard, { children: [_jsxs(Flex, __assign({ direction: 'row', justifyContent: 'space-between' }, { children: [_jsxs(Flex, __assign({ direction: 'column' }, { children: [_jsx(Subtitle, { children: subtitle }), _jsx(Title, { children: title })] })), _jsx(Flex.Item, { children: addon })] })), children] }));
};
var StyledInfoCard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-bottom: solid 2px ", ";\n  padding: 1rem;\n  word-break: break-word;\n\n"], ["\n  background-color: ", ";\n  border-bottom: solid 2px ", ";\n  padding: 1rem;\n  word-break: break-word;\n\n"])), themeColor('white'), themePalette('neutral', 200));
var Subtitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  text-transform: uppercase;\n  word-break: break-word;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  text-transform: uppercase;\n  word-break: break-word;\n"])), themePalette('neutral', 500), _themeFontSize('small'), _themeFontFamily('monospace'), _themeFontWeight('bold'));
var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: 1rem;\n  word-break: break-word;\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: 1rem;\n  word-break: break-word;\n"])), _themeFontSize('huge'), _themeFontWeight('medium'));
var templateObject_1, templateObject_2, templateObject_3;
