import React from 'react'
import { useSelector } from 'react-redux'
import { AppBar as AppBarUI } from '@exivity/ui'
import { useRoute } from '@exivity/routing'

import { workSelectors } from '../../work/state'

import { usePageTitle } from './usePageTitle'
import { useIcon } from './useIcon'
import { useLogo } from './useLogo'
import { AdministrationMenu } from './AdministrationMenu'
import { UserMenu } from './UserMenu'
import { DocumentationLink } from './DocumentationLink'

export function AppBar () {
  const workMessage = useSelector(workSelectors.getUserMessage)
  const progress = useSelector(workSelectors.getForegroundProgress)
  const isLoading = useSelector(workSelectors.isForegroundBusy)
  const AppIcon = useIcon()
  const AppLogo = useLogo()
  const title = usePageTitle()
  const { exactRoute } = useRoute()

  return (
    <AppBarUI
      message={workMessage}
      icon={<AppIcon />}
      logo={<AppLogo />}
      title={title}
      isLoading={isLoading}
      progress={progress}>
      {exactRoute.requiresAuth
      && (
        <>
          <DocumentationLink />
          <AdministrationMenu />
          <UserMenu />
        </>
      )}
    </AppBarUI>
  )
}
