var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState, useMemo } from 'react';
import { searchBy } from '../utils/array';
export function useMultiSearchFilter(sources, getSearchOptions) {
    var _a = useState(function () { return sources.map(function () { return ''; }); }), filters = _a[0], setFilters = _a[1];
    function setFilter(index, value) {
        setFilters(__spreadArray(__spreadArray(__spreadArray([], filters.slice(0, index), true), [
            value
        ], false), filters.slice(index + 1), true));
    }
    function getFilterState(index) {
        return {
            searchTerm: filters[index],
            setSearchTerm: function (value) { return setFilter(index, value); }
        };
    }
    function search(data) {
        return sources.reduce(function (data, source, i) { return (searchBy(getSearchOptions(source), filters[i], data)); }, data);
    }
    return useMemo(function () {
        return [getFilterState, search];
    }, [filters, sources]);
}
