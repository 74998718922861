var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { prop } from '@exivity/fp';
import { MdAdd, MdRemove } from 'react-icons/md';
import { StyledCheckbox } from '../Checkbox';
import { themeSpace, themeColor } from '../Theme';
import { Text } from '../Text';
import { Icon as IconWrapper } from '../Icon';
import { useListContext } from './listContext';
var focusNextElement = function (event) {
    var _a, _b, _c;
    switch (event.key) {
        case 'Down':
        case 'ArrowDown':
            // eslint-disable-next-line no-unused-expressions
            (_a = event.currentTarget.nextElementSibling) === null || _a === void 0 ? void 0 : _a.focus();
            break;
        case 'Up':
        case 'ArrowUp':
            // eslint-disable-next-line no-unused-expressions
            (_b = event.currentTarget.previousElementSibling) === null || _b === void 0 ? void 0 : _b.focus();
            break;
        case 'Enter':
            // eslint-disable-next-line no-unused-expressions
            (_c = event.currentTarget) === null || _c === void 0 ? void 0 : _c.click();
            break;
        default:
            break;
    }
};
var Category = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), themeColor('gray'));
var StyledLi = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: calc(", " * ", ") !important;\n  \n  &.select {\n    &__li {    \n      & > *:not(.select__highlighter) {\n        margin-right: 10px;\n      }\n      \n      &:hover, :focus {\n        background-color: ", ";\n      }\n    }\n  }\n  \n  .select__checkbox {\n    flex-shrink: 0;\n  }\n"], ["\n  padding-left: calc(", " * ", ") !important;\n  \n  &.select {\n    &__li {    \n      & > *:not(.select__highlighter) {\n        margin-right: 10px;\n      }\n      \n      &:hover, :focus {\n        background-color: ", ";\n      }\n    }\n  }\n  \n  .select__checkbox {\n    flex-shrink: 0;\n  }\n"])), themeSpace(2), prop('depth'), themeColor('lightGray'));
var StyledButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  visibility: ", ";\n  \n  width: 20px;\n  height: 20px;\n  border-radius: 0;\n  border: none;\n  outline: none;\n  flex-shrink: 0;\n  \n  padding: 0;\n  cursor: pointer;\n  \n  svg {\n    width: 100%;\n    height: 100%;\n    color: ", ";\n  }\n"], ["\n  visibility: ", ";\n  \n  width: 20px;\n  height: 20px;\n  border-radius: 0;\n  border: none;\n  outline: none;\n  flex-shrink: 0;\n  \n  padding: 0;\n  cursor: pointer;\n  \n  svg {\n    width: 100%;\n    height: 100%;\n    color: ", ";\n  }\n"])), function (_a) {
    var leaf = _a.leaf;
    return leaf ? 'hidden' : 'visible';
}, themeColor('gray'));
function ExpandButton(_a) {
    var index = _a.index;
    var list = useListContext().list;
    var _b = list[index], expanded = _b.expanded, onChangeExpand = _b.onChangeExpand, isLeaf = _b.isLeaf, isTree = _b.isTree, isCategory = _b.isCategory, categorized = _b.categorized;
    if (isCategory || categorized || !isTree)
        return null;
    return (_jsx(StyledButton, __assign({ leaf: isLeaf(), type: 'button', onClick: function (e) {
            e.stopPropagation();
            onChangeExpand();
        } }, { children: _jsx(IconWrapper, { children: expanded
                ? _jsx(MdRemove, {})
                : _jsx(MdAdd, {}) }) })));
}
function CheckBox(_a) {
    var index = _a.index;
    var _b = useListContext(), list = _b.list, value = _b.value;
    var _c = list[index], isActive = _c.isActive, isParentActive = _c.isParentActive, multiple = _c.multiple, data = _c.data;
    if (!multiple)
        return null;
    var parentIsActive = isParentActive(value);
    return (_jsx("div", { children: _jsxs(StyledCheckbox, { children: [_jsx("input", { type: 'checkbox', readOnly: true, checked: (parentIsActive || isActive(value, data)), disabled: parentIsActive }), _jsx("div", { className: 'custom-checkbox' })] }) }));
}
export function Icon(_a) {
    var index = _a.index;
    var _b = useListContext(), list = _b.list, value = _b.value;
    var renderIcon = list[index].renderIcon;
    var Icon = renderIcon(value);
    if (!Icon)
        return null;
    return (_jsx("div", { children: _jsx(IconWrapper, { children: Icon }) }));
}
export var SelectOption = function (_a) {
    var style = _a.style, index = _a.index;
    var _b = useListContext(), list = _b.list, onChange = _b.onChange, value = _b.value;
    var _c = list[index], data = _c.data, label = _c.label, path = _c.path, query = _c.query, isCategory = _c.isCategory, isActive = _c.isActive, getValue = _c.getValue;
    if (isCategory) {
        return (_jsx(Category, __assign({ "data-testid": 'select__li--category', style: style, title: label }, { children: _jsx(Text.Highlight, __assign({ className: 'select__highlighter', textToMatch: [query] }, { children: label })) })));
    }
    return (_jsxs(StyledLi, __assign({ "data-testid": 'select__li', className: 'select__li', style: style, tabIndex: -1, depth: path.length, title: label, onClick: function () { return onChange(getValue(value)); }, onKeyDown: focusNextElement }, { children: [_jsx(ExpandButton, { index: index }), _jsx(CheckBox, { index: index }), _jsx(Icon, { index: index }), _jsx(Text.Highlight, __assign({ className: 'select__highlighter', active: isActive(value, data), textToMatch: [query] }, { children: label }))] })));
};
var templateObject_1, templateObject_2, templateObject_3;
