var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { createListStrategy } from '../../List';
import { classNames } from '../../utils';
import { fromTheme } from '../../Theme';
var StyledCategory = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-transform: uppercase;\n  color: ", ";\n  display: flex;\n  align-items: center;\n"], ["\n  text-transform: uppercase;\n  color: ", ";\n  display: flex;\n  align-items: center;\n"])), fromTheme(function (theme) { return theme.colors.gray; }));
var StyledCategorizedItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n\n  &:focus {\n    outline: none;\n  }\n\n  &:hover, &:focus {\n    background-color: ", ";\n  }\n\n  &.active {\n    color: ", ";\n  }\n\n  .columns > :first-child {\n    padding-left: ", "em;\n  }\n\n  .sortable & {\n    :active {\n      cursor: grabbing;\n    }\n\n    .columns > :first-child {\n      padding-left: ", "em;\n    }\n  }\n"], ["\n  cursor: pointer;\n  position: relative;\n\n  &:focus {\n    outline: none;\n  }\n\n  &:hover, &:focus {\n    background-color: ", ";\n  }\n\n  &.active {\n    color: ", ";\n  }\n\n  .columns > :first-child {\n    padding-left: ", "em;\n  }\n\n  .sortable & {\n    :active {\n      cursor: grabbing;\n    }\n\n    .columns > :first-child {\n      padding-left: ", "em;\n    }\n  }\n"])), fromTheme(function (theme) { return theme.colors.lightGray; }), fromTheme(function (theme) { return theme.global.purposes.primary; }), fromTheme(function (theme) { return theme.global.baseSpacing; }), fromTheme(function (theme) { return theme.global.baseSpacing * 2; }));
function listSorter(categoryDirection, direction) {
    if (categoryDirection === void 0) { categoryDirection = 'asc'; }
    if (direction === void 0) { direction = 'asc'; }
    function sortBy(a, b, dir) {
        if (a < b) {
            return dir === 'asc' ? -1 : 1;
        }
        else if (a > b) {
            return dir === 'asc' ? 1 : -1;
        }
    }
    return function (a, b) {
        if (a.type === 'category' && b.type === 'category') {
            return sortBy(a.orderBy, b.orderBy, categoryDirection) || 0;
        }
        else {
            return sortBy(a.categoryId, b.categoryId, categoryDirection)
                || sortBy(a.orderBy, b.orderBy, direction)
                || 0;
        }
    };
}
export function createCategorizedStrategy(data, _a) {
    var categorization = _a.categorization, order = _a.order, isActive = _a.isActive, onClick = _a.onClick, renderColumns = _a.renderColumns, sortable = _a.sortable;
    function makeCategoryItems() {
        return categorization.categories.map(function (category) {
            var _a;
            return ({
                type: 'category',
                category: category,
                categoryId: categorization.getCategoryId(category),
                orderBy: ((_a = categorization.order) === null || _a === void 0 ? void 0 : _a.by(category))
                    || categorization.getCategoryId(category)
            });
        });
    }
    function makeItem(payload) {
        var category = categorization.getItemCategoryId(payload);
        return {
            type: 'categorized-item',
            payload: payload,
            categoryId: category,
            orderBy: (order === null || order === void 0 ? void 0 : order.by(payload)) || payload
        };
    }
    return createListStrategy(data, {
        prepareData: function () {
            var _a;
            var categorizedList = makeCategoryItems();
            data.forEach(function (item) { return categorizedList.push(makeItem(item)); });
            return categorizedList.sort(listSorter((_a = categorization.order) === null || _a === void 0 ? void 0 : _a.direction, order === null || order === void 0 ? void 0 : order.direction));
        },
        renderItem: function (_a) {
            var item = _a.item;
            if (item.type === 'category') {
                return (_jsx(StyledCategory, __assign({ "data-testid": 'category-header', className: 'category' }, { children: categorization === null || categorization === void 0 ? void 0 : categorization.renderHeader(item.category) })));
            }
            else {
                var active = (isActive === null || isActive === void 0 ? void 0 : isActive(item.payload)) || false;
                return sortable(_jsx(StyledCategorizedItem, __assign({ tabIndex: 0, "data-active": active, "data-category": item.categoryId, className: classNames({ active: active }, 'categorized'), onClick: function () { return onClick === null || onClick === void 0 ? void 0 : onClick(item.payload); } }, { children: renderColumns(item.payload) })), { item: item.payload, slot: "listitem-".concat(item.categoryId) });
            }
        }
    });
}
var templateObject_1, templateObject_2;
