import React from 'react'
import { useSelector } from 'react-redux'
import { Field, Label, Select, Radio, Checkbox, Placeholder } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { CrudRecord } from 'react-crud-hook'
import { queries, Resources, useCacheQuery } from '@exivity/data-layer'
import { Link, getPathname } from '@exivity/routing'
import { prop } from 'ramda'
import { viewAttribute } from '@exivity/fp'

import { useReportLevelLabels } from '../../../reports/components/useReportLevels'
import { app } from '../../../../routes'
import { RootState } from '../../../../reducers'

import { useNotificationInfo } from './useNotificationInfo'

interface ReportPublishedProps {
  notification: (
    CrudRecord<Resources['notificationsubscription']>
  )
}

const INIT_REPORT_PUBLISHED = {
  report_id: '',
  account_depth: undefined,
  report_type: 'separate' as const,
  dimension: ['services' as const, 'instances' as const],
  group_by: 'instances_by_service' as const,
  date_settings: 'previous_month' as const,
  format: 'csv' as const
}

const getId = prop('id')
const viewAttributeName = viewAttribute('name')

export function ReportPublished ({ notification }: ReportPublishedProps) {
  const pdfExportEnabled = useSelector((state: RootState) => state.system.flags.SUPPORTS_PDF_EXPORT)
  const reports = useCacheQuery(queries.findAll('report').sortByAttribute('name', 'ascending'))
  const accounts = useCacheQuery(
    queries
      .relationshipOf('report', notification.attributes.info?.report_id || null, 'accounts')
      .filterByAttribute('level', 'equal', notification.attributes.info?.account_depth as number)
      .sortByAttribute('name', 'ascending')
  )

  const reportLevelLabels = useReportLevelLabels(notification.attributes.info?.report_id || null)

  const [info, setInfo] = useNotificationInfo(
    notification,
    notification.id ? notification.attributes.info : INIT_REPORT_PUBLISHED
  )

  if (!reports.length) {
    return (
      <Placeholder>
        {translate('Unable to configure report published notification. No reports defined.')}{' '}
        <Link to={getPathname(app.routes.datapipelines.routes.reportDefinitions)}>{translate('Create a new report.')}</Link>
      </Placeholder>
    )
  }

  return (
    <>
      <Field>
        <Label.Group>
          <Label>{translate('Report')}</Label>
        </Label.Group>
        <Select
          required
          value={info.report_id}
          data={reports}
          valueAccessor={({ id }) => id}
          labelAccessor={(item) => item.attributes.name}
          onChange={setInfo('report_id')} />
      </Field>
      <Field>
        <Label.Group>
          <Label>{translate('Depth')}</Label>
        </Label.Group>
        <Select
          required
          data={reportLevelLabels}
          value={info.account_depth}
          valueAccessor={([depth]) => depth}
          labelAccessor={([, label]) => label}
          onChange={setInfo('account_depth')} />
      </Field>
      <Field>
        <Label.Group>
          <Label>{translate('Report type')}</Label>
        </Label.Group>
        <Radio.Group value={info.report_type || ''}
          onChange={(report_type) => {
            notification.setAttribute('info', {
              ...notification.attributes.info,
              report_type,
              account_filter: null,
              dimension: report_type === 'consolidated'
                ? notification.attributes.info.dimension
                : notification.attributes.info.dimension
                  ?.filter((dimension) => dimension !== 'accounts')
            })
          }}>
          <Radio label={translate('Separate')} value='separate' />
          <Radio label={translate('Consolidated')} value='consolidated' />
        </Radio.Group>
      </Field>
      <Field>
        <Label.Group>
          <Label>{translate('Dimensions')}</Label>
          <Label.Sub>{translate('Multiple options possible.')}</Label.Sub>
        </Label.Group>
        <Checkbox.Group
          value={info.dimension || []}
          onChange={(dimension) => {
            notification.setAttribute('info', {
              ...notification.attributes.info,
              dimension,
              group_by: dimension?.includes('instances')
                ? notification.attributes?.info.group_by ?? 'instances_by_service'
                : null
            })
          }}>
          {info.report_type === 'consolidated'
            ? <Checkbox label={translate('Accounts')} value='accounts' />
            : null
          }
          <Checkbox label={translate('Services')} value='services' />
          <Checkbox label={translate('Instances')} value='instances' />
        </Checkbox.Group>
      </Field>
      {info?.dimension?.includes('instances')
      && (
        <Field>
          <Label.Group>
            <Label>{translate('Group instances by')}</Label>
          </Label.Group>
          <Radio.Group
            value={info.group_by}
            onChange={setInfo('group_by')}>
            <Radio label={translate('Services')} value='instances_by_service' />
            <Radio label={translate('Instances')} value='instances_by_instance' />
          </Radio.Group>
        </Field>
      )}
      {info.report_type === 'separate' && (
        <Field>
          <Label.Group>
            <Label>{translate('Account')}</Label>
            <Label.Sub>
              {translate('Optional. When none selected, you will receive a separate report for each account')}
            </Label.Sub>
          </Label.Group>
          <Select
            searchable
            valueAccessor={getId}
            labelAccessor={viewAttributeName}
            value={info.account_filter || ''}
            data={accounts}
            onClear={
              info.account_filter
                ? () => notification.setAttribute('info', {
                  ...info,
                  account_filter: null
                })
                : undefined}
            onChange={setInfo('account_filter')} />
        </Field>
      )}
      <Field>
        <Label.Group>
          <Label>{translate('Period')}</Label>
        </Label.Group>
        <Radio.Group
          value={info.date_settings}
          onChange={setInfo('date_settings')}>
          <Radio label={translate('Previous month')} value='previous_month' />
          <Radio label={translate('Month to date')} value='month_to_date' />
        </Radio.Group>
      </Field>
      {pdfExportEnabled
        ? (
          <Field>
            <Label.Group>
              <Label>{translate('Document format')}</Label>
            </Label.Group>
            <Radio.Group
              value={info.format}
              onChange={setInfo('format')}>
              <Radio label={translate('CSV')} value='csv' />
              <Radio label={translate('PDF/summary')} value='pdf/summary' />
            </Radio.Group>
          </Field>
        )
        : null
      }
    </>
  )
}
