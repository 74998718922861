import { useEffect } from 'react'
import { useAPIQueryV2, queries } from '@exivity/data-layer'

export const RUNS_LIMIT = 6

export function useFetchWorkflows () {
  const queryAPIV2 = useAPIQueryV2()

  useEffect(() => {
    queryAPIV2(
      queries.findAll('workflow'),
      {
        sources: {
          serverV2: {
            include: ['steps', 'schedules', 'runs'],
            settings: {
              params: {
                'related[runs][limit]': 10,
                'related[runs][sort]': '-start_ts'
              }
            }
          }
        }
      }
    )
  }, [queryAPIV2])
}
