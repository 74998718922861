var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useReducer } from 'react';
import { addMonths, addQuarters, addYears, subMonths, subQuarters, subYears } from 'date-fns';
import { CalendarMode } from '../CalendarModes';
import { Browser } from '../../Browser';
var BrowseTypes;
(function (BrowseTypes) {
    BrowseTypes["ADD_MONTH"] = "addMonth";
    BrowseTypes["SUB_MONTH"] = "subMonth";
    BrowseTypes["ADD_QUARTER"] = "addQuarter";
    BrowseTypes["SUB_QUARTER"] = "subQuarter";
    BrowseTypes["ADD_YEAR"] = "addYear";
    BrowseTypes["SUB_YEAR"] = "subYear";
    BrowseTypes["ADD_YEARS"] = "addYears";
    BrowseTypes["SUB_YEARS"] = "subYears";
})(BrowseTypes || (BrowseTypes = {}));
function browseReducer(date, type) {
    switch (type) {
        case BrowseTypes.ADD_MONTH:
            return addMonths(date, 1);
        case BrowseTypes.SUB_MONTH:
            return subMonths(date, 1);
        case BrowseTypes.ADD_QUARTER:
            return addQuarters(date, 1);
        case BrowseTypes.SUB_QUARTER:
            return subQuarters(date, 1);
        case BrowseTypes.ADD_YEAR:
            return addYears(date, 1);
        case BrowseTypes.SUB_YEAR:
            return subYears(date, 1);
        case BrowseTypes.ADD_YEARS:
            return addYears(date, 12);
        case BrowseTypes.SUB_YEARS:
            return subYears(date, 12);
        default:
            throw new Error();
    }
}
function createBrowsers(dispatch, mode) {
    function create(prev, next) {
        return [function () { return dispatch(prev); }, function () { return dispatch(next); }];
    }
    switch (mode) {
        case CalendarMode.Years:
            return create(BrowseTypes.SUB_YEARS, BrowseTypes.ADD_YEARS);
        case CalendarMode.Months:
        case CalendarMode.Quarters:
            return create(BrowseTypes.SUB_YEAR, BrowseTypes.ADD_YEAR);
        case CalendarMode.Days:
        default:
            return create(BrowseTypes.SUB_MONTH, BrowseTypes.ADD_MONTH);
    }
}
export function useBrowser(value, selectedMode) {
    var _a = useReducer(browseReducer, value), date = _a[0], dispatch = _a[1];
    var _b = createBrowsers(dispatch, selectedMode), onPrev = _b[0], onNext = _b[1];
    return {
        date: date,
        onPrev: onPrev,
        onNext: onNext
    };
}
export function CalendarBrowser(_a) {
    var value = _a.value, onChange = _a.onChange, mode = _a.mode, children = _a.children;
    var _b = useBrowser(value, mode), date = _b.date, onPrev = _b.onPrev, onNext = _b.onNext;
    useEffect(function () {
        onChange(date);
    }, [date]);
    return (_jsx(Browser, __assign({ prev: _jsx("span", __assign({ className: 'arrow', "data-testid": 'prev', onClick: onPrev }, { children: "\u276E" })), next: _jsx("span", __assign({ className: 'arrow', "data-testid": 'next', onClick: onNext }, { children: "\u276F" })) }, { children: children })));
}
