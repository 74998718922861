import { observeProfilePartial } from '../../domains/myProfile/state'
import { debouncedPersistUserState } from '../user'
import { onChangeDebug, serverV1, serverV2 } from '../../data/sources'
import { handleAuthChange } from '../../utils/auth'
import { configurationSelectors } from '../../domains/administration/state/configuration'
import { authSelectors } from '../../domains/auth/state'

import { stateObserver } from './stateObserver'

export const persistUserState = stateObserver({
  observe: (state) => ({
    user: observeProfilePartial(state.profile),
    reportsFilters: state.reports.filters,
    reportsGui: state.reports.gui
  }),
  onChange: debouncedPersistUserState
})

export const manageLogStrategy = stateObserver({
  observe: (state) => ({ debug: configurationSelectors.isDebugMode(state) }),
  onChange: ({ debug }) => onChangeDebug(debug)
})

export const handlePermissionsChange = stateObserver({
  observe: (state) => ({ auth: state.auth }),
  onChange: ({ auth }) => handleAuthChange(auth)
})

export const handleServerSettingsChange = stateObserver({
  observe: (state) => ({
    apiRoot: configurationSelectors.getApiRoot(state),
    // Have to observe this one in order to update token
    isAuthenticated: authSelectors.isAuthenticated(state)
  }),
  onChange: ({ apiRoot }) => {
    serverV1.updateServerSettings(apiRoot)
    serverV2.updateServerSettings(apiRoot)
  }
})
