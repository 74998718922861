var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, createContext, useContext } from 'react';
import { Searchbar } from '../Searchbar';
import { defaultLabelAccessor, SelectList } from '../SelectLegacy';
import { useSearchFilter } from '../useSearchFilter';
import { Block } from '../Block';
var SearchTermContext = createContext('');
export function useSearchTerm() {
    return useContext(SearchTermContext);
}
export function SearchableList(_a) {
    var _b = _a.placeholder, placeholder = _b === void 0 ? 'Search...' : _b, _c = _a.notFoundText, notFoundText = _c === void 0 ? 'Not found' : _c, data = _a.data, _d = _a.labelAccessor, labelAccessor = _d === void 0 ? defaultLabelAccessor : _d, rest = __rest(_a, ["placeholder", "notFoundText", "data", "labelAccessor"]);
    var _e = useState(''), searchTerm = _e[0], setSearchTerm = _e[1];
    var filteredData = useSearchFilter(data, searchTerm, labelAccessor);
    var gotData = data.length > 0;
    return (_jsxs(_Fragment, { children: [gotData && (_jsx(Block, __assign({ px: 1, pb: 1 }, { children: _jsx(Searchbar, { dark: true, value: searchTerm, placeholder: placeholder, onChange: function (value, e) {
                        e.stopPropagation();
                        setSearchTerm(value);
                    } }) }))), _jsx(SearchTermContext.Provider, __assign({ value: searchTerm }, { children: _jsx(SelectList, __assign({ data: filteredData, labelAccessor: labelAccessor }, rest, { noDataText: data.length === 0
                        ? rest.noDataText
                        : notFoundText })) }))] }));
}
