var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { translate } from '@exivity/translations';
import { useToggle } from '@exivity/hooks';
import { identity, juxt } from '@exivity/fp';
import { SelectInput } from '../Input';
import { useFieldContext } from '../Field/context';
import { renderSelectList } from '../SelectList';
import { Dropdown } from '../Dropdown';
import { themeSpace } from '../Theme';
var StyledDropdown = styled(Dropdown)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  .dropdown__drop {\n    max-width: 500px;\n    \n    ", "\n \n    padding: ", " 0;\n    \n    .select__searchbar {\n      margin-top: 0;\n    }\n  }\n \n  ", "\n"], ["\n  .dropdown__drop {\n    max-width: 500px;\n    \n    ", "\n \n    padding: ", " 0;\n    \n    .select__searchbar {\n      margin-top: 0;\n    }\n  }\n \n  ", "\n"])), function (_a) {
    var width = _a.width;
    return width
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["width: ", "px;"], ["width: ", "px;"])), width) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["width: 100%;"], ["width: 100%;"])));
}, themeSpace(1), function (_a) {
    var width = _a.width;
    return !width && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: relative;\n  "], ["\n    position: relative;\n  "])));
});
export function SelectFilter(_a) {
    var name = _a.name, value = _a.value, inputValue = _a.inputValue, className = _a.className, _b = _a.data, data = _b === void 0 ? [] : _b, onChange = _a.onChange, required = _a.required, disabled = _a.disabled, _c = _a.placement, placement = _c === void 0 ? 'bottom-start' : _c, _d = _a.multiple, multiple = _d === void 0 ? false : _d, _e = _a.searchable, searchable = _e === void 0 ? false : _e, categorized = _a.categorized, _f = _a.placeholder, placeholder = _f === void 0 ? translate('Choose an option') : _f, _g = _a.labelAccessor, labelAccessor = _g === void 0 ? identity : _g, _h = _a.valueAccessor, valueAccessor = _h === void 0 ? identity : _h, getChildNodes = _a.getChildNodes, initExpandedState = _a.initExpandedState, renderIcon = _a.renderIcon, actions = _a.actions, onClear = _a.onClear, clearText = _a.clearText, purposes = __rest(_a, ["name", "value", "inputValue", "className", "data", "onChange", "required", "disabled", "placement", "multiple", "searchable", "categorized", "placeholder", "labelAccessor", "valueAccessor", "getChildNodes", "initExpandedState", "renderIcon", "actions", "onClear", "clearText"]);
    var _j = useToggle(false), isOpen = _j[0], toggleOpen = _j[1];
    var id = useFieldContext().id;
    return (_jsx(StyledDropdown, __assign({ className: className, width: 400, disabled: disabled, placement: placement, reference: _jsx(SelectInput, __assign({ id: id, title: inputValue, required: required, name: name, outlined: true, secondary: true, value: inputValue, placeholder: placeholder, disabled: disabled }, purposes)), isOpen: isOpen, closeOnClick: false, onToggleOpen: toggleOpen }, { children: renderSelectList({
            actions: actions,
            multiple: multiple,
            searchable: searchable,
            categorized: categorized,
            value: value,
            data: data,
            valueAccessor: valueAccessor,
            labelAccessor: labelAccessor,
            getChildNodes: getChildNodes,
            renderIcon: renderIcon,
            initExpandedState: initExpandedState,
            clearText: clearText,
            onChange: function (value) {
                onChange && onChange(value);
                !multiple && toggleOpen();
            },
            onClear: onClear
                ? juxt([onClear, toggleOpen])
                : undefined
        }) })));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
