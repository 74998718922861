var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
export function SortableItem(_a) {
    var id = _a.id, slot = _a.slot, onSort = _a.onSort, children = _a.children, handle = _a.handle;
    var _b = useDrag({
        type: slot,
        item: { id: id }
    }), drag = _b[1];
    var _c = useDrop({
        accept: slot,
        hover: function (item) {
            if (item.id !== id) {
                onSort(item.id, id);
            }
        }
    }), drop = _c[1];
    return (_jsx("div", __assign({ ref: drop }, { children: _jsxs("div", __assign({ ref: drag }, { children: [handle, children] })) })));
}
/**
 * @description A hook that manages sorting state.
 */
export function useSortable(externalItems, _a) {
    var getKey = _a.getKey, getSort = _a.getSort, updateSort = _a.updateSort;
    var _b = useState(externalItems), items = _b[0], setItems = _b[1];
    return {
        /**
         * @description Current state of the items.
         */
        items: items,
        /**
         * @description Updates the `items`
         * by swapping the sort value of item `a` and the sort value of item `b` with eachother.
         */
        sort: function (a, b) {
            setItems(function (items) {
                return items.map(function (item) {
                    if (getKey(item) === getKey(a))
                        return updateSort(item, getSort(b));
                    if (getKey(item) === getKey(b))
                        return updateSort(item, getSort(a));
                    else
                        return item;
                });
            });
        },
        /**
         * @description Sets the value of items.
         */
        setItems: setItems
    };
}
