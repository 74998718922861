var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { globalFont, truncateText, classNames } from '../../utils';
import { fromTheme } from '../../Theme';
import { List } from '../../List';
import { SortableItem, useSortable } from './sortable';
import { createCategorizedStrategy } from './categorization';
import { createFlatStrategy } from './flat';
var ITEM_HEIGHT = 30;
var OVERSCAN_COUNT = 5;
var StyledList = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  height: 100%;\n\n  font-size: ", ";\n\n  .placeholder {\n    color: ", ";\n    padding-left: ", "em;\n  }\n\n  ul {\n    padding: 0;\n    margin: 0;\n    list-style-type: none;\n  }\n\n  li {\n    height: ", "px;\n    padding-left: ", "em;\n  }\n  \n  .columns {\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    > * {\n      box-sizing: border-box; \n      ", "\n    }\n  }\n\n  &.sortable {\n    .drag-handle {\n      position: absolute;\n      display: flex;\n      align-items: center;\n      height: 100%;\n      padding-left: ", "em;\n      z-index: 1;\n      cursor: grab;\n    }\n  }\n"], ["\n  ", "\n\n  height: 100%;\n\n  font-size: ", ";\n\n  .placeholder {\n    color: ", ";\n    padding-left: ", "em;\n  }\n\n  ul {\n    padding: 0;\n    margin: 0;\n    list-style-type: none;\n  }\n\n  li {\n    height: ", "px;\n    padding-left: ", "em;\n  }\n  \n  .columns {\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    > * {\n      box-sizing: border-box; \n      ", "\n    }\n  }\n\n  &.sortable {\n    .drag-handle {\n      position: absolute;\n      display: flex;\n      align-items: center;\n      height: 100%;\n      padding-left: ", "em;\n      z-index: 1;\n      cursor: grab;\n    }\n  }\n"])), globalFont, fromTheme(function (theme) { return theme.fontSizes[1]; }), fromTheme(function (theme) { return theme.colors.gray; }), fromTheme(function (theme) { return theme.global.baseSpacing; }), ITEM_HEIGHT, fromTheme(function (theme) { return theme.global.baseSpacing; }), truncateText, fromTheme(function (theme) { return theme.global.baseSpacing / 2; }));
function _InternalList(_a) {
    var data = _a.data, categorization = _a.categorization, isActive = _a.isActive, sorting = _a.sorting, onClick = _a.onClick, columns = _a.columns, order = _a.order;
    var sortable = useCallback(function (node, _a) {
        var item = _a.item, slot = _a.slot;
        if (!sorting)
            return node;
        return (_jsx(SortableItem, __assign({ id: sorting.getKey(item), slot: slot, handle: _jsx("span", __assign({ className: 'drag-handle' }, { children: "\u2630" })), onSort: function (dragKey, hoverKey) {
                var dragItem = data.find(function (item) { return sorting.getKey(item) === dragKey; });
                var hoverItem = data.find(function (item) { return sorting.getKey(item) === hoverKey; });
                if (dragItem && hoverItem)
                    sorting.onSort(dragItem, hoverItem);
            } }, { children: node })));
    }, [sorting]);
    var renderColumns = useCallback(function (item) {
        return (_jsx("div", __assign({ className: 'columns' }, { children: columns.map(function (column, i, arr) {
                var width = column.width || "".concat(100 / arr.length, "%");
                return (_jsx("span", __assign({ style: { width: width } }, { children: column.renderCell(item) }), i));
            }) })));
    }, [columns]);
    var renderingStrategy = useMemo(function () {
        var sharedArgs = {
            order: order,
            sortable: sortable,
            isActive: isActive,
            renderColumns: renderColumns,
            onClick: onClick
        };
        return categorization
            ? createCategorizedStrategy(data, __assign(__assign({}, sharedArgs), { categorization: categorization }))
            : createFlatStrategy(data, sharedArgs);
    }, [data, categorization, order, sortable, isActive, renderColumns, onClick]);
    return (_jsx(StyledList, __assign({ className: classNames({ sortable: !!sorting }) }, { children: renderingStrategy.itemCount !== 0
            ? (_jsx(List, __assign({}, renderingStrategy, { itemHeight: ITEM_HEIGHT, overscanCount: OVERSCAN_COUNT })))
            : _jsx("div", __assign({ className: 'placeholder' }, { children: "No items in this list." })) })));
}
export var InternalList = React.memo(_InternalList);
InternalList.useSortable = useSortable;
var templateObject_1;
