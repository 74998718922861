var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../Checkbox';
import { themeSpace } from '../Theme';
var StyledRadioGroup = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > :not(:last-child) {\n    margin-bottom: ", ";\n  }\n"], ["\n  & > :not(:last-child) {\n    margin-bottom: ", ";\n  }\n"])), themeSpace(1));
function Radio(_a) {
    var _b = _a.name, name = _b === void 0 ? '' : _b, value = _a.value, checked = _a.checked, onChange = _a.onChange, rest = __rest(_a, ["name", "value", "checked", "onChange"]);
    return (_jsx(Checkbox, __assign({ type: 'radio', value: value, name: name, checked: checked, onChange: function (val, e) {
            if (value) {
                onChange && onChange(value, e);
            }
        } }, rest)));
}
Radio.Group = function RadioGroup(_a) {
    var children = _a.children, id = _a.id, value = _a.value, radioProps = __rest(_a, ["children", "id", "value"]);
    return (_jsx(StyledRadioGroup, __assign({ "data-testid": 'radio-group' }, { children: React
            .Children
            .map(children, function (child) { return React.cloneElement(child, __assign(__assign(__assign({}, radioProps), child.props), { name: child.props.name || id, checked: value === child.props.value })); }) })));
};
export { Radio };
var templateObject_1;
