import { useCallback, useMemo } from 'react'
import { MdMoreHoriz } from 'react-icons/md'
import { queries, Resources, useCacheQueryFn } from '@exivity/data-layer'
import { RenderIconProps } from '@exivity/ui'

type RenderAccount = RenderIconProps<Resources['account']>

type RecordIdentifier = { type: string; id: string }

type RemoveParentIds = {
  (accountIds: string[]): string[]
  (accountIdentifiers: RecordIdentifier[]): RecordIdentifier[]
}

export function useRemoveParentAccountIds (): RemoveParentIds {
  const queryCache = useCacheQueryFn()

  return useCallback((accountIdentifier: any[]) => {
    const accountMap: Record<string, true> = {}

    accountIdentifier.forEach((identifier) => {
      accountMap[
        typeof identifier === 'string'
          ? identifier
          : identifier.id
      ] = true
    })

    function filterAccount (identifier: any): boolean {
      const parent = queryCache(queries.relationshipOf('account', identifier, 'parent'))

      if (!parent) return true

      return accountMap[parent.id]
        ? false
        : filterAccount(parent)
    }

    return accountIdentifier.filter(filterAccount)
  }, [queryCache])
}

export function useSelectedChildAccountIndicators (
  accountIdentifier?: RecordIdentifier
) {
  const queryCache = useCacheQueryFn()

  const accountIconMap = useMemo(() => {
    const accountIconMap: Record<string, React.ReactNode> = {}
    if (!accountIdentifier) return accountIconMap

    function addParents (id: string, map = accountIconMap): void {
      const parent = queryCache(queries.relationshipOf('account', id, 'parent'))

      if (!parent) return undefined

      map[parent.id] = <MdMoreHoriz />
      return addParents(parent.id, map)
    }

    addParents(accountIdentifier.id)

    return accountIconMap
  }, [accountIdentifier, queryCache])

  return useCallback(({ item }: RenderAccount) => accountIconMap[item.id] ?? null, [accountIconMap])
}

export function useSelectedChildAccountsIndicators (
  accountIdentifiers: (string|RecordIdentifier)[]
) {
  const queryCache = useCacheQueryFn()

  const accountIconMap = useMemo(() => {
    const accountIconMap: Record<string, React.ReactNode> = {}

    function addParents (id: string, map = accountIconMap): void {
      const parent = queryCache(queries.relationshipOf('account', id, 'parent'))

      if (!parent) return undefined

      map[parent.id] = <MdMoreHoriz />
      return addParents(parent.id, map)
    }

    accountIdentifiers.forEach((identifier) => addParents(
      typeof identifier === 'string'
        ? identifier
        : identifier.id
    ))

    return accountIconMap
  }, [accountIdentifiers, queryCache])

  return useCallback(({ item }: RenderAccount) => accountIconMap[item.id] ?? null, [accountIconMap])
}
