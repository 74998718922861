import { useContext, useCallback } from 'react';
import { compose, complement } from '@exivity/fp';
import { getResourcePermissions, useUserHasPermissionFn } from './permissions/useUserHasPermission';
import { getQueryType } from './queries/utils';
import { DataLayerContextV2 } from './Provider';
export function useAPIQueryV2() {
    const server = useContext(DataLayerContextV2);
    const userHasPermission = useUserHasPermissionFn();
    return useCallback((queryBuilder, options) => {
        const queryExpression = queryBuilder.toQuery();
        const resourceNotAllowed = compose(complement(userHasPermission), getResourcePermissions, getQueryType);
        if (resourceNotAllowed(queryExpression))
            return;
        try {
            if (queryExpression.op === 'findRecords' || queryExpression.op === 'findRelatedRecords') {
                queryExpression.page = { offset: 0, limit: -1, kind: 'offsetLimit' };
            }
            // Do not return result - Async code is not handled in components
            server.query(queryExpression, options);
        }
        catch (_a) {
            // Errors and logging is handled by fetch hooks
        }
    }, [userHasPermission, server]);
}
