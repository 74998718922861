import Coordinator, {
  EventLoggingStrategy,
  LogTruncationStrategy
} from '@orbit/coordinator'

import { setDebug, debug } from '../../utils/log'

export function createLogCoordinator (servers, memory) {
  const EVENT_LOGGING_NAME = 'event-logging'

  const eventStrategy = new EventLoggingStrategy({ name: EVENT_LOGGING_NAME })
  const logCoordinator = new Coordinator({ sources: [memory, ...servers] })

  logCoordinator.addStrategy(new LogTruncationStrategy())

  function onChangeDebug (shouldDebug) {
    setDebug(shouldDebug)

    logCoordinator.deactivate().then(() => {
      debug('Log coordinator deactivated')

      function addEventLoggingStrategy () {
        if (!logCoordinator.getStrategy(EVENT_LOGGING_NAME)) {
          debug('Added event logging strategy')
          logCoordinator.addStrategy(eventStrategy)
        }
      }

      function removeEventLoggingStrategy () {
        if (logCoordinator.getStrategy(EVENT_LOGGING_NAME)) {
          debug('Removed event logging strategy')
          logCoordinator.removeStrategy(EVENT_LOGGING_NAME)
        }
      }

      shouldDebug
        ? addEventLoggingStrategy()
        : removeEventLoggingStrategy()

      logCoordinator.activate().then(() => {
        debug('Log coordinator activated')
      })
    })
  }

  return {
    logCoordinator,
    onChangeDebug
  }
}
