import { format, parse } from 'date-fns'
import { map } from 'ramda'

import { MetadataModel, MetadataValue } from '../../data/types'

export const METADATA_COLUMN_PREFIX = 'metadata_'

export function spreadMetadataValues (metadataValue: MetadataValue = {}) {
  return Object.entries(metadataValue).reduce((formattedValue, [key, val]) => ({
    ...formattedValue,
    [METADATA_COLUMN_PREFIX + key]: val
  }), {})
}

const isoDateRegex = /^(\d{4}-\d{2}-\d{2})$/

export function getMetadataValues (
  metadatas: MetadataModel[],
  dateFormat: string
) {
  return metadatas
    .reduce((acc, { attributes }) => {
      const transformedValues = map((value: string|null) => {
        return isoDateRegex.test(value || '')
          ? format(parse(value as string, 'yyyy-MM-dd', new Date()), dateFormat)
          : value || '(undefined)'
      }, attributes.values) as any

      return ({ ...acc, ...transformedValues })
    }, {} as MetadataValue)
}
