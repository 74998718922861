var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Code } from '../Code';
import { Link } from '../Link';
import { LegacyTable } from '../LegacyTable';
var defaultRenderers = {
    code: function (props) { return _jsx(Code, __assign({}, props)); },
    inlineCode: function (props) { return _jsx(Code, __assign({}, props)); },
    link: function (props) { return _jsx(Link, __assign({}, props)); },
    paragraph: function (_a) {
        var children = _a.children;
        return _jsx("span", { children: children });
    },
    table: function (props) { return _jsx(LegacyTable, __assign({}, props)); }
};
export var Markdown = function (_a) {
    var _b = _a.forceAllLineBreaks, forceAllLineBreaks = _b === void 0 ? false : _b, _c = _a.renderers, renderers = _c === void 0 ? {} : _c, children = _a.children, rest = __rest(_a, ["forceAllLineBreaks", "renderers", "children"]);
    if (forceAllLineBreaks) {
        children = children === null || children === void 0 ? void 0 : children.replace(/\n/g, '  \n');
    }
    var mergedRenderers = useMemo(function () { return (__assign(__assign({}, defaultRenderers), renderers)); }, [renderers]);
    return (_jsx(ReactMarkdown, __assign({}, rest, { components: mergedRenderers, linkTarget: '_blank' }, { children: children })));
};
