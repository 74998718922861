// Call bootstrap scripts
import './application/bootstrap'

import React from 'react'
import { render } from 'react-dom'
import allSettled from 'promise.allsettled'

import { AllProviders } from './application/components/Providers/AllProviders'
import Dispatcher from './application/components/Dispatcher'
import { UnsupportedBrowser } from './domains/support/pages/UnsupportedBrowser'
import isSupportedBrowser from './utils/browserSupport'
import { onUIkitReady } from './styles/uikit'
import { inject } from './jsapi'
// import fonts
import '@fontsource/fira-sans'
import '@fontsource/fira-mono'
import { viewUnsupportedBrowser } from './store/storage'

allSettled.shim()

const root = document.getElementById('root')

inject()

onUIkitReady(() => {
  const application = (
    <AllProviders>
      <Dispatcher />
    </AllProviders>
  )

  const app = isSupportedBrowser()
    ? application
    : viewUnsupportedBrowser()
    ? application
    : <UnsupportedBrowser />
  render(app, root)
})
