import { createContext, useContext } from 'react';
var defaultCtx = {
    list: [],
    onChange: (function () { }),
    value: undefined
};
export var ListContext = createContext(defaultCtx);
export function isMultiple(args) {
    return Array.isArray(args);
}
export function useListContext() {
    return useContext(ListContext);
}
