var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { MdSearch, MdClear } from 'react-icons/md';
import styled from 'styled-components';
import { translate } from '@exivity/translations';
import { Icon } from '../Icon';
import { colorFromPurpose, themeFontFamily, themeFontSize } from '../Theme';
var StyledInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  all: unset;\n  box-sizing: border-box;\n  height: 40px;\n  padding 6px 10px;\n  width: 100%;\n  background-color: ", ";\n  font-family: ", ";\n  font-size: ", ";\n  border-top-left-radius: 4px;\n  border-bottom-left-radius: 4px;\n  border: 2px solid transparent;\n  &:hover, &:focus {\n    border: 2px solid ", ";\n  }\n"], ["\n  all: unset;\n  box-sizing: border-box;\n  height: 40px;\n  padding 6px 10px;\n  width: 100%;\n  background-color: ", ";\n  font-family: ", ";\n  font-size: ", ";\n  border-top-left-radius: 4px;\n  border-bottom-left-radius: 4px;\n  border: 2px solid transparent;\n  &:hover, &:focus {\n    border: 2px solid ", ";\n  }\n"])), colorFromPurpose('secondary', 200), themeFontFamily, themeFontSize(2), colorFromPurpose('primary', 500));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  width: 100%;\n"], ["\n  position: relative;\n  display: flex;\n  width: 100%;\n"])));
var StyledButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  all: unset;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 50px;\n  height: 40px;\n  font-size: ", ";\n  border-top-right-radius: 4px;\n  border-bottom-right-radius: 4px;\n  color: ", ";\n  background-color: ", ";\n  \n  &:hover, &:focus {\n    background-color: ", ";\n  }\n"], ["\n  all: unset;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 50px;\n  height: 40px;\n  font-size: ", ";\n  border-top-right-radius: 4px;\n  border-bottom-right-radius: 4px;\n  color: ", ";\n  background-color: ", ";\n  \n  &:hover, &:focus {\n    background-color: ", ";\n  }\n"])), themeFontSize(4), colorFromPurpose('secondary', 200), colorFromPurpose('secondary', 700), colorFromPurpose('secondary', 800));
var ClearButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  all: unset;\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 7px;\n  right: 50px;\n  \n  height: 26px;\n  width: 26px;\n  border-radius: 180px;\n  font-size: ", ";\n  color: ", ";\n  background-color: ", ";\n  \n  &:hover, &:focus {\n    background-color: ", ";\n  }\n"], ["\n  all: unset;\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 7px;\n  right: 50px;\n  \n  height: 26px;\n  width: 26px;\n  border-radius: 180px;\n  font-size: ", ";\n  color: ", ";\n  background-color: ", ";\n  \n  &:hover, &:focus {\n    background-color: ", ";\n  }\n"])), themeFontSize(3), colorFromPurpose('secondary', 100), colorFromPurpose('secondary', 400), colorFromPurpose('secondary', 500));
export var Searchbar = function (_a) {
    var onSubmit = _a.onSubmit;
    var _b = useState(''), query = _b[0], updateQuery = _b[1];
    var _c = useState(''), submittedQuery = _c[0], updateSubmittedQuery = _c[1];
    function handleSubmit(query) {
        if (submittedQuery !== query)
            onSubmit(query);
        updateSubmittedQuery(query);
    }
    return (_jsxs(Container, { children: [_jsx(StyledInput, { type: 'text', "data-testid": 'select__search', placeholder: translate('Search...'), autoFocus: true, value: query, onKeyDown: function (e) {
                    if (e.code === 'Enter') {
                        e.preventDefault();
                        handleSubmit(query);
                    }
                }, onChange: function (e) { return updateQuery(e.target.value); } }), submittedQuery.length > 0 && (_jsx(ClearButton, __assign({ onClick: function () {
                    handleSubmit('');
                    updateQuery('');
                } }, { children: _jsx(MdClear, {}) }))), _jsx(StyledButton, __assign({ type: 'button', onClick: function () { return handleSubmit(query); } }, { children: _jsx(Icon, { children: _jsx(MdSearch, {}) }) }))] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
