import { translate } from '@exivity/translations'
import { AtomicDoc } from '@exivity/data-layer'

import { HookContext } from '../fetchWithHooks/fetchWithHooks'
import { workThunks } from '../../domains/work/thunks'
import store from '../../store'
import { resources } from '../../data/types/resources'
import { capitalize } from '../../utils/string'

const operations = {
  success: {
    POST: (resource: string) => translate('{resource} successfully created.', resource),
    PATCH: (resource: string) => translate('{resource} successfully updated.', resource),
    PUT: (resource: string) => translate('{resource} successfully updated.', resource),
    DELETE: (resource: string) => translate('{resource} successfully deleted.', resource)
  }
}

function successMessage (
  resource: string,
  operation: keyof typeof operations.success
) {
  store.dispatch(
    workThunks.showSuccessMessage(operations.success[operation](capitalize(resource)))
  )
}

export function notifyUserOnResponse (ctx: HookContext) {
  const resource = resources[ctx.path as keyof typeof resources]

  if (resource && resource.messageUserOn.includes(ctx.method)) {
    successMessage(resource.name, ctx.method as keyof typeof operations.success)
  }

  return ctx
}

const atomicOperations = {
  success: {
    add: (resource: string) => translate('{resource} successfully created.', resource),
    update: (resource: string) => translate('{resource} successfully updated.', resource),
    remove: (resource: string) => translate('{resource} successfully deleted.', resource)
  }
}

const notifyResource: Record<string, () => string> = {
  rate: () => translate('Rate revision'),
  workflow: () => translate('Workflow')
}

function successMessageAtomic (
  resource: string,
  operation: keyof typeof atomicOperations.success
) {
  store.dispatch(
    workThunks.showSuccessMessage(atomicOperations.success[operation](capitalize(resource)))
  )
}

export function notifyUserOnResponseAtomic (atomicDoc: AtomicDoc[]) {
  return (ctx: HookContext) => {
    atomicDoc.forEach(doc => {
      // @ts-ignore
      const type = doc?.data?.type || doc?.ref?.type

      if (type && notifyResource[type]) {
        successMessageAtomic(
          notifyResource[type](),
          doc.op
        )
      }
    })

    return ctx
  }
}
