var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Container, MasterWidget, DetailWidget } from './StyledComponents';
export function MasterDetail(_a) {
    var title = _a.title, children = _a.children;
    return (_jsx(_Fragment, { children: _jsxs(Container, { children: [_jsx(MasterWidget, __assign({ title: title, width: 500 }, { children: children[0] })), _jsx(DetailWidget, { children: children[1] })] }) }));
}
