import React, { useMemo } from 'react'
import { withData } from 'react-orbitjs'
import { QueryBuilder } from '@orbit/data'
import { MdSchedule } from 'react-icons/md'
import styled, { css } from 'styled-components'
import { Resources, WorkflowRunStatus } from '@exivity/data-layer'
import { fromTheme, toRgbString, Icon, Flex, Label } from '@exivity/ui'
import sortBy from 'lodash/sortBy'
import { Link } from '@exivity/routing'

import { useDateFormatter, Formats } from '../../../../../../components/hooks/useDateFormatter'

import { WorkflowRuns } from './Runs'
import { getStatusColor, getFrequencyDescriptions } from './helpers'
import { RUNS_LIMIT } from './fetch'

interface StyledWorkflowStatusProps {
  height?: number
}

const StyledWorkflowStatus = styled(Link)<StyledWorkflowStatusProps>`
  display: flex;
  min-width: 900px;

  color: ${fromTheme(theme => theme.global.textColor)};
  height: 120px;
  border-top: 1px rgba(${fromTheme(theme => toRgbString(theme.colors.gray))}, 0.5) solid;

  &:hover {
    background-color: ${fromTheme(theme => theme.colors.lightGray)};
    color: ${fromTheme(theme => theme.global.textColor)};
    text-decoration: none;
  }

  &:last-child {
    border-bottom: 1px rgba(${fromTheme(theme => toRgbString(theme.colors.gray))}, 0.5) solid;
  }
`

interface MetaProps {
  status: WorkflowRunStatus | 0
}

const Meta = styled(Flex.Item)<MetaProps>`
  min-width: 300px;

  .status-bar {
    float: left;
    height: 100%;
    width: 4px;
    ${({ status }) => status !== 0 && css`background-color: ${getStatusColor(status)};`}
  }

  .info {
    padding: ${fromTheme(theme => theme.space[2])};

    label {
      display: block;
      color: ${fromTheme(theme => theme.global.textColorMuted)};
    }
  }
`

interface WorkflowStatusProps {
  workflow: Resources['workflow']
  schedules?: Resources['workflowschedule'][]
  steps?: Resources['workflowstep'][]
  runs?: Resources['workflowrun'][]
}

export function WorkflowStatus ({
  workflow,
  schedules = [],
  steps = [],
  runs = []
}: WorkflowStatusProps) {
  const formatter = useDateFormatter()

  const latestRuns = useMemo(() => (
    sortBy(runs, (run) => formatter.parse(Formats.IsoDateTime, run.attributes.start_date))
      .slice(Math.max(runs.length - RUNS_LIMIT, 0))
  ), [runs])

  return !!workflow && steps.length > 0 && runs.length > 0
    ? (
      <StyledWorkflowStatus to={`../data-pipelines/workflows/${workflow.id}`}>
        <Meta status={
          latestRuns.length
            ? latestRuns[latestRuns.length - 1].attributes.status
            : 0
        }>
          <div className='status-bar' />
          <div className='info'>
            {workflow.attributes.name}
            <Label>{steps.length} {steps.length === 1 ? 'step' : 'steps'}</Label>
            <Label>
              <Icon><MdSchedule /></Icon>{' '}
              {getFrequencyDescriptions(schedules)}
            </Label>
          </div>
        </Meta>

        <WorkflowRuns runs={latestRuns} />

      </StyledWorkflowStatus>
    )
    : null
}

const mapRecordsToProps = ({ workflow }: WorkflowStatusProps) => ({
  schedules: (q: QueryBuilder) => q.findRelatedRecords(workflow, 'schedules'),
  steps: (q: QueryBuilder) => q.findRelatedRecords(workflow, 'steps'),
  runs: (q: QueryBuilder) => q.findRelatedRecords(workflow, 'runs')
})

const WithData = withData(mapRecordsToProps)(WorkflowStatus)

export default WithData as unknown as typeof WorkflowStatus
