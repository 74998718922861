var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { isAfter, isBefore, isSameDay, isSameMonth, isSameQuarter, isSameYear, isWithinInterval } from 'date-fns';
import { classNames } from '../../utils';
import { fromTheme } from '../../Theme';
import { CalendarMode } from './CalendarMode';
var isSame = (_a = {},
    _a[CalendarMode.Days] = isSameDay,
    _a[CalendarMode.Months] = isSameMonth,
    _a[CalendarMode.Quarters] = isSameQuarter,
    _a[CalendarMode.Years] = isSameYear,
    _a);
export var isMac = function () {
    var ua = navigator.userAgent;
    return ua.indexOf('Mac OS X') > -1;
};
var isCtrlPressed = function (e) {
    return isMac() ? e.metaKey : e.ctrlKey;
};
export function TimeUnit(_a) {
    var _b = _a.value, startValue = _b[0], endValue = _b[1], date = _a.date, onHover = _a.onHover, onStopHovering = _a.onStopHovering, onChange = _a.onChange, mode = _a.mode, min = _a.min, max = _a.max, children = _a.children;
    if ((min && isBefore(date, min)) || (max && isAfter(date, max))) {
        return (_jsx("li", __assign({ className: 'time-unit out-of-bounds' }, { children: children })));
    }
    var isStart = !!(startValue && isSame[mode](date, startValue));
    var isEnd = !!(endValue && isSame[mode](date, endValue));
    var isActive = Boolean(isStart || isEnd);
    var isWithinRange = Boolean((startValue && endValue)
        && isWithinInterval(date, {
            start: startValue,
            end: endValue
        })) && !isActive;
    return (_jsx("li", __assign({ "data-testid": "time-unit".concat(isActive ? '-active' : '').concat(isWithinRange ? '-within-range' : ''), className: classNames({
            active: isActive,
            start: isStart,
            end: isEnd,
            'within-range': !isActive && isWithinRange
        }, 'time-unit'), onMouseOver: (function () { return onHover === null || onHover === void 0 ? void 0 : onHover(date); }), onMouseLeave: (function () { return onStopHovering === null || onStopHovering === void 0 ? void 0 : onStopHovering(date); }), onClick: (function (e) { return onChange === null || onChange === void 0 ? void 0 : onChange(date, isCtrlPressed(e)); }) }, { children: children })));
}
var TIME_UNIT_HEIGHT = 24;
var TIME_UNIT_VERTICAL_PADDING = 4;
var TIME_UNIT_BORDER = 2;
export var TIME_UNIT_TOTAL_HEIGHT = TIME_UNIT_HEIGHT
    + (2 * TIME_UNIT_VERTICAL_PADDING)
    + (2 * TIME_UNIT_BORDER);
export var StyledTimeUnits = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px 0;\n  margin: 0;\n\n  li {\n    list-style-type: none;\n    display: inline-block;\n    text-align: center;\n    border: ", "px solid white;\n  }\n\n  .time-unit {\n    height: ", "px;\n    padding: ", "px 0;\n    cursor: pointer;\n    user-select: none;\n\n    &.active {\n      color: white;\n      background-color: ", ";\n    }\n\n    &.out-of-bounds {\n      background-color: ", ";\n      cursor: not-allowed;\n    }\n\n    &:hover:not(.out-of-bounds) {\n      color: white;\n      background-color: ", ";\n    }\n  }\n"], ["\n  padding: 10px 0;\n  margin: 0;\n\n  li {\n    list-style-type: none;\n    display: inline-block;\n    text-align: center;\n    border: ", "px solid white;\n  }\n\n  .time-unit {\n    height: ", "px;\n    padding: ", "px 0;\n    cursor: pointer;\n    user-select: none;\n\n    &.active {\n      color: white;\n      background-color: ", ";\n    }\n\n    &.out-of-bounds {\n      background-color: ", ";\n      cursor: not-allowed;\n    }\n\n    &:hover:not(.out-of-bounds) {\n      color: white;\n      background-color: ", ";\n    }\n  }\n"])), TIME_UNIT_BORDER, TIME_UNIT_HEIGHT, TIME_UNIT_VERTICAL_PADDING, fromTheme(function (theme) { return theme.global.purposes.primary; }), fromTheme(function (theme) { return theme.colors.lightGray; }), fromTheme(function (theme) { return theme.global.purposes.primary; }));
var templateObject_1;
