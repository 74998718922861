import React, { FC } from 'react'
import { Group, Field, Label, Select } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { pick, viewAttribute } from '@exivity/fp'
import { withData } from 'react-orbitjs'
import { QueryBuilder, RecordIdentity } from '@orbit/data'

import { UserGroupModel } from '../../../../../data/types'

interface AccessLevelGroupProps {
  userGroup: RecordIdentity | null
  onChangeUserGroup: (userGroup: RecordIdentity) => void

  userGroups?: UserGroupModel[]
}

const pickIdentifier = pick(['id', 'type'])
const viewName = viewAttribute('name')

function PlainAccessLevelGroup ({
  userGroup,
  onChangeUserGroup,
  userGroups = []
}: AccessLevelGroupProps) {
  return (
    <Group title={translate('Access level')}>
      <Field.Container>
        <Field data-testid='user-group'>
          <Label>{translate('Group')}</Label>
          <Select
            required
            placeholder={translate('Select a group')}
            data={userGroups}
            value={userGroup || undefined}
            labelAccessor={viewName}
            valueAccessor={pickIdentifier}
            onChange={onChangeUserGroup} />
        </Field>
      </Field.Container>
    </Group>
  )
}

const mapUserGroupsToProps = () => ({
  userGroups: (q: QueryBuilder) => q.findRecords('usergroup')
})

export const AccessLevelGroup = withData(
  mapUserGroupsToProps
)(PlainAccessLevelGroup) as unknown as FC<AccessLevelGroupProps>
