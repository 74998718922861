var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createGlobalStyle, ThemeProvider as NativeThemeProvider } from 'styled-components';
import { useWebFonts } from './useWebFonts';
import { defaultTheme } from './defaultTheme';
import { setThemeBrandAndInverse } from './';
var GlobalStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  body > * {\n    font-family: 'Fira Sans', sans-serif;\n  }\n"], ["\n  body > * {\n    font-family: 'Fira Sans', sans-serif;\n  }\n"])));
export function ThemeProvider(_a) {
    var _b = _a.theme, theme = _b === void 0 ? defaultTheme : _b, brandColor = _a.brandColor, children = _a.children;
    var _c = useState(theme), selectedTheme = _c[0], setTheme = _c[1];
    useEffect(function () {
        if (brandColor) {
            setTheme(setThemeBrandAndInverse(brandColor));
        }
    }, [brandColor]);
    useWebFonts(selectedTheme.fonts);
    return (_jsx(DndProvider, __assign({ backend: HTML5Backend }, { children: _jsxs(NativeThemeProvider, __assign({ theme: selectedTheme }, { children: [_jsx(GlobalStyle, {}), children] })) })));
}
var templateObject_1;
