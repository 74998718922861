"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reduce = void 0;
var ramda_1 = require("ramda");
exports.reduce = (0, ramda_1.curry)(function (f, init, tree) {
    function go(acc, node, indexPath) {
        return node.children.reduce(function (acc, child, i) { return go(acc, child, __spreadArray(__spreadArray([], indexPath, true), [i], false)); }, f(acc, node, indexPath, tree));
    }
    return go(init, tree, []);
});
