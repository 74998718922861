var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, createContext, useContext } from 'react';
import { useToggle } from '@exivity/hooks';
import styled from 'styled-components';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Icon } from '../Icon';
import { fromTheme } from '../Theme';
var StyledIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  user-select: none;\n  font-size: ", "em;\n  flex-grow: 0;\n\n  svg {\n    vertical-align: bottom;\n  }\n"], ["\n  cursor: pointer;\n  user-select: none;\n  font-size: ", "em;\n  flex-grow: 0;\n\n  svg {\n    vertical-align: bottom;\n  }\n"])), fromTheme(function (theme) { return theme.global.sizes.large; }));
function Collapse() {
    var _a = useCollapsible(), collapsed = _a.collapsed, toggleCollapsed = _a.toggleCollapsed;
    return (_jsx(StyledIcon, __assign({ "data-testid": 'collapsible__collapser', onClick: function (e) {
            e.stopPropagation();
            toggleCollapsed();
        } }, { children: collapsed
            ? _jsx(MdKeyboardArrowDown, {})
            : _jsx(MdKeyboardArrowUp, {}) })));
}
function Content(_a) {
    var children = _a.children;
    var collapsed = useCollapsible().collapsed;
    return collapsed
        ? null
        : children;
}
var CollapsibleContext = createContext({
    collapsed: false,
    toggleCollapsed: function () { }
});
function useCollapsible() {
    return useContext(CollapsibleContext);
}
function Collapsible(_a) {
    var _b = _a.initialCollapsed, initialCollapsed = _b === void 0 ? false : _b, collapsed = _a.collapsed, onCollapseChange = _a.onCollapseChange, children = _a.children;
    var _c = useToggle(initialCollapsed), isCollapsed = _c[0], toggleCollapsed = _c[1];
    var collapsableContext = useMemo(function () { return ({
        collapsed: collapsed !== null && collapsed !== void 0 ? collapsed : isCollapsed,
        toggleCollapsed: function () {
            toggleCollapsed();
            onCollapseChange && onCollapseChange(collapsed === undefined
                ? !isCollapsed
                : !collapsed);
        }
    }); }, [
        isCollapsed,
        collapsed,
        toggleCollapsed,
        onCollapseChange
    ]);
    return (_jsx(CollapsibleContext.Provider, __assign({ value: collapsableContext }, { children: children })));
}
Collapsible.useCollapsible = useCollapsible;
Collapsible.Collapse = Collapse;
Collapsible.Content = Content;
export { Collapsible };
var templateObject_1;
