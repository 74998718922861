"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeAtPath = void 0;
var ramda_1 = require("ramda");
var updateAtPath_1 = require("./updateAtPath");
var updateChildren_1 = require("./updateChildren");
exports.removeAtPath = (0, ramda_1.curry)(function (indexPath, node) {
    var _a = [
        indexPath.slice(0, indexPath.length - 1),
        indexPath[indexPath.length - 1]
    ], parentPath = _a[0], childToRemoveIndex = _a[1];
    return (0, updateAtPath_1.updateAtPath)(parentPath, (0, updateChildren_1.updateChildren)(function (children) { return children
        .filter(function (_, i) { return i !== childToRemoveIndex; }); }), node);
});
