import { uniq } from 'ramda'

import { insertIf } from '../../../utils/misc'
import { AccountReportItem } from '../../../API/APIResponseTypes/reports'
import { spreadMetadataValues } from '../metadata'
import auth from '../../../utils/auth'
import { UserGroupPermission } from '../../../data/types'

import { makeBreadcrumb } from './helpers'

function getDefaultProperties (item: AccountReportItem) {
  let parentProperties = {}

  if (item.parents) {
    parentProperties = item.parents.reduce((names, parent) => ({
      ...names,
      [`lvl${parent.level}_name`]: parent.name
    }), { [`lvl${item.parents.length + 1}_name`]: item.account })
  }

  return {
    id: item.account_id,
    type: 'account',
    account: item.account,
    parent: item.parent_name,
    key: item.key,
    keyAgg: makeBreadcrumb(
      [item, ...(item.parents || [])],
      { propAccessor: (item) => item.key, direction: 'right', separator: ' > ' }),
    parents: item.parents,
    ...parentProperties
  }
}

export interface AccountDetailsTableItem extends AccountReportItem{
  net?: number
  cogs?: number
  charge: number
}

export default function accountDetails (
  report: AccountReportItem[],
  includeCogsAndNet: boolean
) {
  if (!report) {
    return []
  }

  // For testing purposes in if statement
  if (includeCogsAndNet === undefined) {
    includeCogsAndNet = auth.can(UserGroupPermission.ViewCogs)
  }

  const accounts: { [key: string]: AccountDetailsTableItem } = {}

  let metadataKeys: any[] = []

  report.forEach(item => {
    const account = accounts[item.account_id]

    if (item.metadata) {
      metadataKeys = uniq([...metadataKeys, ...Object.keys(item.metadata)])
    }

    if (
      account
      && includeCogsAndNet
      && account.cogs !== undefined
      && account.net !== undefined
    ) {
      account.cogs += item.total_cogs
      account.net += item.total_net
    }

    if (account) {
      account.charge += item.total_charge
    } else {
      accounts[item.account_id] = {
        ...getDefaultProperties(item),
        ...spreadMetadataValues(item.metadata),
        charge: item.total_charge,
        ...insertIf(includeCogsAndNet, {
          cogs: item.total_cogs,
          net: item.total_net
        })
      }
    }
  })

  return {
    report: Object.values(accounts),
    metaColumns: metadataKeys.map((key) => ({
      key: `metadata_${key}`,
      label: key,
      type: 'string'
    }))
  }
}
