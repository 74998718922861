import { map, omit, over, prop, curry, zipObj, zipWith, lensProp, filter, propEq } from 'ramda';
const convertLidToId = curry((lidIdMap, { type, lid, id }) => ({
    id: lid
        ? lidIdMap[lid]
        : id,
    type
}));
const omitOp = omit(['op']);
const omitLid = omit(['lid']);
// @ts-ignore
const getIds = map((record) => (record === null || record === void 0 ? void 0 : record.id) || '');
// @ts-ignore
const getLids = map((record) => (record === null || record === void 0 ? void 0 : record.lid) || '');
const getData = map(prop('data'));
export function atomicResultsToTransforms(result, atomicDocs) {
    const remoteIds = getIds(getData(result));
    // @ts-ignore
    const lids = getLids(getData(atomicDocs));
    const lidIdMap = zipObj(lids, remoteIds);
    const fillIds = (relationship) => {
        return (Object.prototype.hasOwnProperty.call(relationship, 'data')
            ? ({
                data: Array.isArray(relationship.data)
                    ? map(convertLidToId(lidIdMap), relationship.data)
                    : relationship.data
                        ? convertLidToId(lidIdMap, relationship.data)
                        : null
            })
            : relationship);
    };
    const cache = zipWith(
    // @ts-ignore
    (id, { op, data, ref }) => (Object.assign(Object.assign({ op,
        id }, omitLid(data)), ref)), remoteIds, atomicDocs);
    const recordsWithIds = map(
    // @ts-ignore
    over(
    // @ts-ignore
    lensProp('relationships'), (relationships = {}) => map(fillIds, relationships)), cache);
    return (t) => map((record) => record.op === 'add'
        ? t.addRecord(omitOp(record))
        : record.op === 'update'
            ? t.updateRecord(omitOp(record))
            : t.removeRecord(omitOp(record)), recordsWithIds);
}
export function atomicDocToTransforms(atomicDoc) {
    return (t) => map(
    // @ts-ignore
    (record) => t.removeRecord(record.ref), filter(propEq('op', 'remove'), atomicDoc));
}
