import React, { useMemo } from 'react'
import { Widget, ExpandHeight, Placeholder } from '@exivity/ui'
import { translate } from '@exivity/translations'

import { Row, Column } from '../../../../components/structural/Layout'
import AdvancedSelect, {
  Column as AdvancedSelectColumn
} from '../../../../components/molecules/AdvancedSelect'
import { DsetModel } from '../../../../data/types'
import { useFetchAllRecords } from '../../../../components/hooks/useFetchAllRecords'
import Dataset from '../../components/Dataset'

interface DatasetsProps {
  datasets: DsetModel[]
  params: Record<string, string>
  router: any
}
const INCLUDE_METADATA_DEFINITION = {
  sources: {
    server: {
      include: ['metadatadefinition']
    }
  }
}

export function Datasets ({ datasets, params, router }: DatasetsProps) {
  useFetchAllRecords('dset', INCLUDE_METADATA_DEFINITION)
  const selected = useMemo(
    () => params.id ? datasets.find(({ id }) => id === params.id) : null,
    [datasets, params.id]
  )

  const listDsets = useMemo(() => datasets.map((dset) => ({
    key: dset.id,
    attributes: {
      id: dset.id
    }
  })), [datasets])

  return (
    <Row>
      <Column width={['medium@l']}>
        <ExpandHeight sticky>
          <Widget p={0}>
            <AdvancedSelect
              data={listDsets}
              selected={params.id}
              onClick={({ key }: any) => (
                router.push(`data-pipelines/datasets/${key}`)
              )}>
              <AdvancedSelectColumn
                field='id'
                label='search'
                width={300}
                flexGrow={1}
                searchable />
            </AdvancedSelect>
          </Widget>
        </ExpandHeight>
      </Column>
      <Column>
        <Widget minWidth={350} height='100%'>
          {selected
            ? <Dataset dataset={selected} />
            : <Placeholder>{translate('Select a dataset')}</Placeholder>}
        </Widget>
      </Column>
    </Row>
  )
}
