var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { format, subYears, addYears } from 'date-fns';
import styled from 'styled-components';
import { CalendarMode } from '../CalendarModes';
export var StyledHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  user-select: none;\n  font-size: 20px;\n  text-transform: uppercase;\n  text-align: center;\n"], ["\n  user-select: none;\n  font-size: 20px;\n  text-transform: uppercase;\n  text-align: center;\n"])));
export function formatDateHeader(browseDate, mode, offset) {
    switch (mode) {
        case CalendarMode.Years:
            return format(subYears(browseDate, 6), 'yyyy') + ' - '
                + format(addYears(browseDate, 5), 'yyyy');
        case CalendarMode.Months:
            return format(browseDate, 'yyyy');
        case CalendarMode.Quarters:
            return offset > 0
                ? format(browseDate, 'yyyy') + ' - ' + format(addYears(browseDate, 1), 'yyyy')
                : format(browseDate, 'yyyy');
        case CalendarMode.Days:
        default:
            return format(browseDate, 'MMMM yyyy');
    }
}
export function CalendarHeader(_a) {
    var date = _a.date, mode = _a.mode, offset = _a.offset;
    return (_jsx(StyledHeader, __assign({ "data-testid": 'calendar-header' }, { children: formatDateHeader(date, mode, offset) })));
}
var templateObject_1;
