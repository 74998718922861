"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapAccum = void 0;
var ramda_1 = require("ramda");
exports.mapAccum = (0, ramda_1.curry)(function (f, init, tree) {
    function go(acc, node, indexPath) {
        var _a = f(acc, node.data, indexPath, tree), newAcc = _a[0], newData = _a[1];
        return {
            data: newData,
            children: node.children.map(function (child, i) { return go(newAcc, child, (0, ramda_1.append)(i, indexPath)); })
        };
    }
    return go(init, tree, []);
});
