var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { MdUnfoldLess, MdUnfoldMore } from 'react-icons/md';
import { injectIf } from '../utils';
import { Icon } from '../Icon';
import { themeColor, themeSpace } from '../Theme';
import { Progress } from '../Progress';
export var StyledCollapser = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transform: rotate(45deg);\n  font-size: 22px;\n"], ["\n  transform: rotate(45deg);\n  font-size: 22px;\n"])));
export var Collapser = function (_a) {
    var collapsed = _a.collapsed, onCollapse = _a.onCollapse;
    return (_jsx(StyledCollapser, __assign({ onClick: function () { return onCollapse && onCollapse(!collapsed); } }, { children: collapsed
            ? _jsx(MdUnfoldMore, {})
            : _jsx(MdUnfoldLess, {}) })));
};
export var CopyRight = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  color: ", ";\n  \n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding: ", ";\n  font-size: .875rem;\n  text-transform: none;\n  \n  ", "\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  color: ", ";\n  \n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding: ", ";\n  font-size: .875rem;\n  text-transform: none;\n  \n  ", "\n"])), themeColor('gray'), themeSpace(2), injectIf('hasFooter', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    justify-content: space-between;\n  "], ["\n    justify-content: space-between;\n  "])))));
var StyledFooter = styled.footer(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 270px;\n  background-color: ", ";\n"], ["\n  width: 270px;\n  background-color: ", ";\n"])), themeColor('dark'));
export var Footer = function (_a) {
    var footer = _a.footer, progress = _a.progress, progressIsActive = _a.progressIsActive, progressText = _a.progressText, collapsed = _a.collapsed, onCollapse = _a.onCollapse;
    return (_jsxs(StyledFooter, { children: [_jsx("div", __assign({ style: { position: 'relative', marginBottom: 30 } }, { children: _jsx(Progress, __assign({ active: progressIsActive, percentage: progress, height: 30 }, { children: progressText })) })), (onCollapse || footer)
                && (_jsxs(CopyRight, __assign({ hasFooter: !!footer }, { children: [footer, onCollapse
                            && _jsx(Collapser, { collapsed: collapsed, onCollapse: onCollapse })] })))] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
