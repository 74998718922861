var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { themePriority, themeSpace } from '../Theme';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
var LoadingOverlayContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([" \n  position: relative;\n  height: 100%;\n  width: 100%;\n  \n  .ui-loading-overlay {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    \n    background: rgba(255, 255, 255, 0.8);\n    z-index: ", ";\n  }\n  \n  .ui-loading-overlay__svg {\n    margin-bottom: ", ";\n  }\n\n  .ui-loading-overlay__child {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n"], [" \n  position: relative;\n  height: 100%;\n  width: 100%;\n  \n  .ui-loading-overlay {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    \n    background: rgba(255, 255, 255, 0.8);\n    z-index: ", ";\n  }\n  \n  .ui-loading-overlay__svg {\n    margin-bottom: ", ";\n  }\n\n  .ui-loading-overlay__child {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n"])), themePriority(3), themeSpace(3));
export var LoadingOverlay = function (_a) {
    var children = _a.children, loading = _a.loading, onCancel = _a.onCancel, props = __rest(_a, ["children", "loading", "onCancel"]);
    return (_jsxs(LoadingOverlayContainer, __assign({}, props, { children: [loading && (_jsx("div", __assign({ className: 'ui-loading-overlay' }, { children: _jsxs("div", __assign({ className: 'ui-loading-overlay__child' }, { children: [_jsx(Spinner, { className: 'ui-loading-overlay__svg' }), onCancel && _jsx(Button.Cancel, { onClick: onCancel })] })) }))), children] })));
};
var templateObject_1;
