import React from 'react'
import { Group, Alert, Field, Label, Radio, Select } from '@exivity/ui'
import { translate } from '@exivity/translations'
import { RecordIdentity } from '@orbit/data'
import { useReportAccounts } from '@exivity/data-layer'
import { viewAttribute, pick } from '@exivity/fp'
import { compose } from 'ramda'

import { UserAccountAccessType } from '../../../../../data/types'
import {
  useRemoveParentAccountIds,
  useSelectedChildAccountsIndicators
} from '../../../../accounts/components'

interface AccountAccessGroupProps {
  isCurrentUser: boolean
  type: UserAccountAccessType
  accounts: RecordIdentity[]
  onChangeType: (type: UserAccountAccessType) => void
  onChangeAccounts: (accounts: RecordIdentity[]) => void
}

const pickIdentifier = pick(['type', 'id'])
const nameAttribute = viewAttribute('name')

export function AccountAccessGroup ({
  isCurrentUser,
  type,
  accounts,
  onChangeType,
  onChangeAccounts
}: AccountAccessGroupProps) {
  const { lvl1Accounts, getChildAccounts } = useReportAccounts()
  const renderMoreIcon = useSelectedChildAccountsIndicators(accounts)
  const removeParentAccounts = useRemoveParentAccountIds()

  return (
    <Group title={translate('Account visibility')}>
      {isCurrentUser && (
        <Alert data-testid='logout-warning' warning>
          {translate('Changes will only be visible throughout the application after you sign out and log in again.')}
        </Alert>
      )}

      <Field.Container>
        <Field data-testid='account-access-type'>
          <Label>{translate('Visibility type')}</Label>
          <Radio.Group value={type}
            onChange={onChangeType}>
            <Radio
              label={translate('Show all accounts')}
              value={UserAccountAccessType.All} />
            <Radio
              label={translate('Show only specific accounts')}
              value={UserAccountAccessType.Custom} />
          </Radio.Group>
        </Field>

        {type !== UserAccountAccessType.All && (
          <Field data-testid='accounts'>
            <Label>{translate('Visible accounts')}</Label>
            <Select
              multiple
              searchable
              renderIcon={renderMoreIcon}
              labelAccessor={nameAttribute}
              valueAccessor={pickIdentifier}
              placeholder={translate('Select accounts')}
              value={accounts}
              data={lvl1Accounts}
              getChildNodes={getChildAccounts}
              onChange={
                compose(
                  onChangeAccounts,
                  removeParentAccounts
                )
              } />
          </Field>
        )}
      </Field.Container>
    </Group>
  )
}
