var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Collapsible as CollapsibleComponent } from '../Collapsible';
import { Header, Title, Content, Separator, GroupIcon } from './components';
function defaultHeader(_a) {
    var _b = _a.collapsible, collapsible = _b === void 0 ? false : _b, title = _a.title, Icon = _a.Icon;
    return (_jsxs(Header, { children: [Icon && _jsx(GroupIcon, { children: _jsx(Icon, {}) }), _jsx(Title, { children: title }), _jsx(Separator, {}), collapsible && _jsx(CollapsibleComponent.Collapse, {})] }));
}
function defaultContent(content) {
    return (_jsx(Content, { children: content }));
}
function Group(_a) {
    var title = _a.title, Icon = _a.Icon, children = _a.children, collapsed = _a.collapsed, initialCollapsed = _a.initialCollapsed, onCollapseChange = _a.onCollapseChange;
    var collapsible = initialCollapsed !== undefined || collapsed !== undefined;
    var child = title
        ? (_jsxs(_Fragment, { children: [defaultHeader({ collapsible: collapsible, Icon: Icon, title: title }), defaultContent(children)] }))
        : children;
    return (_jsx(CollapsibleComponent, __assign({ initialCollapsed: initialCollapsed, collapsed: collapsible
            ? collapsed
            : false, onCollapseChange: onCollapseChange }, { children: child })));
}
Group.Header = Header;
Group.Title = Title;
Group.Separator = Separator;
Group.Icon = GroupIcon;
Group.Content = Content;
Group.Collapser = CollapsibleComponent.Collapse;
export { Group };
