var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { globalFont, usePurposeProp } from '../utils';
import { matchColorFromPurpose } from '../Theme';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { Section } from '../Section';
var Container = styled(Section)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  color: ", ";\n  background-color: ", ";\n  /* border: 1px solid ", "; */\n  padding: 15px;\n  margin: 20px 0 20px 0;\n\n  ", " {\n    margin-top: 0;\n    color: ", ";\n    font-weight: bold;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n\n  ", " {\n    color: ", ";\n  }\n\n  ", " {\n    cursor: pointer;\n    position: relative;\n    left: -3px;\n  }\n"], ["\n  ", ";\n  color: ", ";\n  background-color: ", ";\n  /* border: 1px solid ", "; */\n  padding: 15px;\n  margin: 20px 0 20px 0;\n\n  ", " {\n    margin-top: 0;\n    color: ", ";\n    font-weight: bold;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n\n  ", " {\n    color: ", ";\n  }\n\n  ", " {\n    cursor: pointer;\n    position: relative;\n    left: -3px;\n  }\n"])), globalFont, matchColorFromPurpose(800), matchColorFromPurpose(100), matchColorFromPurpose(500), Heading.Sub, matchColorFromPurpose(800), Text, matchColorFromPurpose(600), Icon);
export function Alert(_a) {
    var _b;
    var title = _a.title, children = _a.children, warning = _a.warning, danger = _a.danger, _c = _a.closable, closable = _c === void 0 ? !danger : _c, onClosed = _a.onClosed, rest = __rest(_a, ["title", "children", "warning", "danger", "closable", "onClosed"]);
    var _d = useState(true), visible = _d[0], setVisible = _d[1];
    var purpose = usePurposeProp({ warning: warning, danger: danger });
    if (!visible) {
        return null;
    }
    return (_jsx(Container, __assign({ as: 'aside' }, (_b = {}, _b[purpose] = true, _b), rest, { children: _jsxs(Flex, __assign({ justifyContent: 'space-between' }, { children: [_jsxs(Flex.Item, __assign({ grow: 1 }, { children: [title && _jsx(Heading.Sub, { children: title }), _jsx(Text, { children: children })] })), (closable || onClosed) && (_jsx(Flex.Item, __assign({ shrink: 1, ml: 2 }, { children: _jsx(Icon, __assign({ onClick: function () {
                            onClosed && onClosed();
                            setVisible(false);
                        } }, { children: _jsx(MdClose, {}) })) })))] })) })));
}
var templateObject_1;
