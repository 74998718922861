import { createSyncCoordinator } from '../coordinators/sync'
import { createLogCoordinator } from '../coordinators/log'
import { debug } from '../../utils/log'

import { serverV1, serverV2 } from './server'
import { createMemory } from './memory'

const servers = [serverV1.server, serverV2.server]

const memory = createMemory()

const syncCoordinator = createSyncCoordinator(servers, memory)
const { logCoordinator, onChangeDebug } = createLogCoordinator(servers, memory)

// Activate log coordinator now
logCoordinator
  .activate()
  .then(() => {
    debug('Log coordinator activated')
  })

const activateCoordinator = syncCoordinator.activate()

// Now pull from client and sync to memory, then activate coordinator
// We do this in a web worker thread to not freeze to main thread as this can
// be a heavy operation
// (worker-loader needs to be configured as a Babel loader)
activateCoordinator.then(() => {
  debug('Sync coordinator activated')
})

export {
  serverV1,
  serverV2,
  memory,
  onChangeDebug
}
