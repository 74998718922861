var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import color from 'color';
import { css } from 'styled-components';
import { fromTheme } from '../../Theme';
export * from './hooks';
export var toRgbArray = function (input) {
    try {
        return color(input).rgb().array();
    }
    catch (err) {
        return [0, 0, 0];
    }
};
export function toRgbString(input) {
    // If input is a var(--name) input, treat it as a 'r, g, b' value
    if (typeof input === 'string' && input.startsWith('var(--')) {
        return input;
    }
    // If input is a 'r, g, b' value, treat it literally
    if (typeof input === 'string' && input.split(',').length === 3) {
        return input;
    }
    return toRgbArray(input).join(', ');
}
export function toRgbCss(input) {
    // Process arrays and hex values ([r, g, b] | #rrggbb) with toRgbString to get 'r, g, b' back
    return "rgb(".concat(toRgbString(input), ")");
}
export var classNames = function (map) {
    var more = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        more[_i - 1] = arguments[_i];
    }
    return (Object.keys(map)
        .reduce(function (sum, key) { return map[key] ? "".concat(sum, " ").concat(key) : sum; }, '') + ' ' + String(more.join(' ')));
};
export var globalSectionSpacing = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: ", "rem 0;\n\n  &:first-child {\n    margin-top: 0;\n  }\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  margin: ", "rem 0;\n\n  &:first-child {\n    margin-top: 0;\n  }\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])), function (props) { return props.noMargin
    ? 0
    : fromTheme(function (theme) { return theme.global.baseSpacing; })(props); });
export var resetBox = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: unset;\n  padding: unset;\n  border: unset;\n  box-sizing: border-box;\n"], ["\n  margin: unset;\n  padding: unset;\n  border: unset;\n  box-sizing: border-box;\n"])));
export var truncateText = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));
export var globalFont = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: ", ";\n  font-weight: normal;\n  font-size: ", "px;\n  color: ", ";\n  line-height: ", ";\n"], ["\n  font-family: ", ";\n  font-weight: normal;\n  font-size: ", "px;\n  color: ", ";\n  line-height: ", ";\n"])), fromTheme(function (theme) { return theme.global.fontFamily; }), fromTheme(function (theme) { return theme.global.baseSize; }), fromTheme(function (theme) { return theme.global.textColor; }), fromTheme(function (theme) { return theme.global.lineHeight; }));
export var globalScrollbar = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ::-webkit-scrollbar {\n    width: ", "em;\n  }\n\n  ::-webkit-scrollbar-track {\n      background-color: transparent;\n  }\n\n  ::-webkit-scrollbar-thumb {\n      background: rgba(128, 128, 128, 0.4);\n      border-radius: 6px;\n      border: 4px solid transparent;\n      background-clip: padding-box !important;\n  }\n\n  ::-webkit-scrollbar-thumb:hover {\n      background: rgba(128, 128, 128, 0.6);\n  }\n"], ["\n  ::-webkit-scrollbar {\n    width: ", "em;\n  }\n\n  ::-webkit-scrollbar-track {\n      background-color: transparent;\n  }\n\n  ::-webkit-scrollbar-thumb {\n      background: rgba(128, 128, 128, 0.4);\n      border-radius: 6px;\n      border: 4px solid transparent;\n      background-clip: padding-box !important;\n  }\n\n  ::-webkit-scrollbar-thumb:hover {\n      background: rgba(128, 128, 128, 0.6);\n  }\n"])), fromTheme(function (theme) { return theme.global.sizes.base; }));
export * from '../isolated';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
