var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { themeColor, themeSpace } from '../Theme';
var StyledSeparator = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  height: ", "; \n  display: flex;\n  align-items: center;\n  \n  hr {\n    width: 100%;\n    border: 0;\n    border-top: 1px solid ", ";\n    margin: 0 ", ";\n  }\n"], ["\n  background-color: ", ";\n  height: ", "; \n  display: flex;\n  align-items: center;\n  \n  hr {\n    width: 100%;\n    border: 0;\n    border-top: 1px solid ", ";\n    margin: 0 ", ";\n  }\n"])), themeColor('dark'), themeSpace(2), themeColor('darkGray'), themeSpace(2));
export function Separator() {
    return (_jsx(StyledSeparator, __assign({ className: 'nav__separator' }, { children: _jsx("hr", {}) })));
}
var templateObject_1;
