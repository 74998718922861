import { fromTheme } from '@exivity/ui'
import { Resources, WorkflowRunStatus } from '@exivity/data-layer'

import { withOrdinalSuffix } from '../../../../../../utils/ordinalSuffix'
import { LocalDate, formats } from '../../../../../../utils/date'

export function getFrequencyDescription ({
  attributes: {
    frequency,
    start_time,
    frequency_modifier
  }
}: Resources['workflowschedule']) {
  const startTime = LocalDate.fromTimestamp(start_time)
  if (frequency === 'hourly') {
    const minutes = withOrdinalSuffix(Number(startTime.format(formats.time).split(':')[1]))
    return frequency_modifier > 1
      ? `every ${frequency_modifier} hours starting on the ${minutes} minute`
      : `hourly starting every ${minutes} minute`
  } else if (frequency === 'daily') {
    const time = startTime.format(formats.time)
    return frequency_modifier > 1
      ? `every ${frequency_modifier} days starting at ${time}`
      : `daily, starting at ${time}`
  } else {
    const day = startTime.format(formats.monthDay).split(' ')[1]
    return `${frequency} on the ${withOrdinalSuffix(Number(day))}`
  }
}

export function getFrequencyDescriptions (schedules: Resources['workflowschedule'][]) {
  return schedules.map(getFrequencyDescription).join(', ')
}

export function getStatusColor (status: WorkflowRunStatus | 0) {
  switch (status) {
    case 'started':
    case 'timed_out':
      return fromTheme(theme => theme.colors.blue)
    case 'success':
      return fromTheme(theme => theme.colors.green)
    case 'failed':
    case 'invalid':
    case 'internal_error':
      return fromTheme(theme => theme.colors.red)
    default:
      return fromTheme(theme => theme.colors.white)
  }
}
