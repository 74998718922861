import { lazy } from 'react'
import { translate } from '@exivity/translations'
import { UserGroupPermission } from '@exivity/data-layer'
import { MdCloud } from 'react-icons/md'

import { featureFlags } from '../application/config/features'

const Extractors = lazy(() => import(
  /* webpackChunkName: 'pipelines.extractors' */ '../components/pages/DataPipelines/Extractors'))

const Transformers = lazy(() => import(
  /* webpackChunkName: 'pipelines.transformers' */
  '../components/pages/DataPipelines/Transformers'
))

// @ts-ignore
const Datasets = lazy(() => import(
  /* webpackChunkName: 'pipelines.transformers' */ '../domains/dataset/pages/Datasets'))

const Lookups = lazy(() => import(
  /* webpackChunkName: 'pipelines.lookups' */ '../components/pages/DataPipelines/Lookups'))

const Metadata = lazy(() => import(
  /* webpackChunkName: 'pipelines.transformers' */ '../domains/metadata/pages/Metadata/index'))

const ReportDefinitions = lazy(() => import(
  /* webpackChunkName: 'pipelines.reports' */ '../components/pages/DataPipelines/Reports/index'))

const Workflows = lazy(() => import(
  /* webpackChunkName: 'pipelines.workflows2' */ '../domains/workflows/pages/Workflows'))

export const datapipelines = {
  path: '/data-pipelines',
  meta: {
    title: () => translate('Data pipelines'),
    icon: MdCloud
  },
  routes: {
    extractors: {
      path: '/extractors/:name?/:tab?',
      requiresPermissions: [UserGroupPermission.ManageDataSources],
      meta: {
        title: () => translate('Extractors'),
        documentation: 'https://docs.exivity.com/Advanced/Data%20pipelines/Extractors'
      },
      component: Extractors
    },
    transformers: {
      path: '/transformers/:name?',
      requiresPermissions: [UserGroupPermission.ManageDataSources],
      meta: {
        title: () => translate('Transformers'),
        documentation: 'https://docs.exivity.com/Advanced/Data%20pipelines/Transformers'
      },
      component: Transformers
    },
    datasets: {
      path: '/datasets/:id?',
      requiresPermissions: [UserGroupPermission.ManageDatasets],
      meta: {
        title: () => translate('Datasets'),
        documentation: 'https://docs.exivity.com/Advanced/Data%20pipelines/Datasets',
        feature: featureFlags.dataset
      },
      component: Datasets
    },
    lookups: {
      path: '/lookups/:name?',
      requiresPermissions: [UserGroupPermission.ManageFiles],
      meta: {
        title: () => translate('Lookups'),
        documentation: 'https://docs.exivity.com/Advanced/Data%20pipelines/Lookups'
      },
      component: Lookups
    },
    metadata: {
      path: '/metadata/:id?',
      requiresPermissions: [UserGroupPermission.ManageMetadataDefinitions],
      meta: {
        title: () => translate('Metadata'),
        documentation: 'https://docs.exivity.com/Advanced/Data%20pipelines/Metadata'
      },
      component: Metadata
    },
    reportDefinitions: {
      path: '/reports/:id?',
      requiresPermissions: [UserGroupPermission.ManageReports],
      meta: {
        title: () => translate('Reports'),
        documentation: 'https://docs.exivity.com/Advanced/Data%20pipelines/Reports'
      },
      component: ReportDefinitions
    },
    workflows: {
      path: '/workflows/:id?',
      requiresPermissions: [UserGroupPermission.ManageWorkflows],
      meta: {
        title: () => translate('Workflows'),
        documentation: 'https://docs.exivity.com/Advanced/Data%20pipelines/Workflows'
      },
      component: Workflows
    }
  }
}
