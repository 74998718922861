var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MdAdd, MdEdit, MdDelete, MdDone, MdClear } from 'react-icons/md';
import styled from 'styled-components';
import { Icon } from '../Icon';
import { Button } from './Button';
var StyledButton = styled(Button).attrs(function (_a) {
    var round = _a.round, children = _a.children;
    return ({
        children: round
            ? undefined
            : children
    });
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", " {\n    font-size: 1.6em;\n    position: relative;\n  }\n"], ["\n  ", " {\n    font-size: 1.6em;\n    position: relative;\n  }\n"])), Icon);
export function Add(_a) {
    var _b = _a.children, children = _b === void 0 ? 'Add' : _b, _c = _a.type, type = _c === void 0 ? 'submit' : _c, round = _a.round, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, __assign({ "data-testid": 'add-button', icon: _jsx(MdAdd, {}), success: true, type: type, round: round }, rest, { children: children })));
}
export function Create(_a) {
    var _b = _a.children, children = _b === void 0 ? 'Create' : _b, _c = _a.type, type = _c === void 0 ? 'submit' : _c, round = _a.round, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, __assign({ "data-testid": 'create-button', icon: _jsx(MdDone, {}), success: true, type: type, round: round }, rest, { children: children })));
}
export function Delete(_a) {
    var _b = _a.children, children = _b === void 0 ? 'Delete' : _b, _c = _a.type, type = _c === void 0 ? 'button' : _c, round = _a.round, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, __assign({ "data-testid": 'delete-button', icon: _jsx(MdDelete, {}), danger: true, type: type, round: round }, rest, { children: children })));
}
export function Edit(_a) {
    var _b = _a.children, children = _b === void 0 ? 'Edit' : _b, _c = _a.type, type = _c === void 0 ? 'submit' : _c, round = _a.round, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, __assign({ "data-testid": 'edit-button', icon: _jsx(MdEdit, {}), primary: true, type: type, round: round }, rest, { children: children })));
}
export function Update(_a) {
    var _b = _a.children, children = _b === void 0 ? 'Update' : _b, _c = _a.type, type = _c === void 0 ? 'submit' : _c, round = _a.round, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, __assign({ "data-testid": 'update-button', icon: _jsx(MdDone, {}), primary: true, type: type, round: round }, rest, { children: children })));
}
export function Cancel(_a) {
    var _b = _a.children, children = _b === void 0 ? 'Cancel' : _b, _c = _a.type, type = _c === void 0 ? 'button' : _c, round = _a.round, rest = __rest(_a, ["children", "type", "round"]);
    return (_jsx(StyledButton, __assign({ "data-testid": 'cancel-button', icon: _jsx(MdClear, {}), outlined: true, secondary: true, type: type, round: round }, rest, { children: children })));
}
export function Clear(_a) {
    var _b = _a.children, children = _b === void 0 ? 'Cancel' : _b, _c = _a.type, type = _c === void 0 ? 'button' : _c, rest = __rest(_a, ["children", "type"]);
    return (_jsx(StyledButton, __assign({ outlined: true, secondary: true, type: type, "data-testid": 'clear-button', icon: _jsx(MdClear, {}) }, rest, { children: children })));
}
var templateObject_1;
