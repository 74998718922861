import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from '../Badge';
export var renderIcon = {
    badge: function (cb) {
        return function (_a) {
            var item = _a.item;
            return _jsx(Badge, { children: cb(item) });
        };
    }
};
