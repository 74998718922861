"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.translate = exports.setLanguage = exports.supportedLanguages = exports.selectedLanguage = void 0;
var translations_1 = require("./translations");
var translators_1 = require("./translators");
exports.selectedLanguage = 'en';
exports.supportedLanguages = Object.keys(translations_1.translations);
function setLanguage(language) {
    exports.selectedLanguage = language;
}
exports.setLanguage = setLanguage;
function translate(text) {
    var variables = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        variables[_i - 1] = arguments[_i];
    }
    return (0, translators_1.translator)(exports.selectedLanguage).apply(void 0, __spreadArray([text], variables, false));
}
exports.translate = translate;
