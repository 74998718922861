var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { globalFont } from '../utils';
import { fromTheme } from '../Theme';
export var Badge = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  word-break: keep-all;\n  pointer-events: none;\n\n  margin-left: ", ";\n  vertical-align: middle;\n  \n  font-size: ", "rem;\n  text-transform: uppercase;\n  color: ", ";\n\n  background-color: ", ";\n  border-radius: ", "px;\n  padding: ", ";\n"], ["\n  ", ";\n  word-break: keep-all;\n  pointer-events: none;\n\n  margin-left: ", ";\n  vertical-align: middle;\n  \n  font-size: ", "rem;\n  text-transform: uppercase;\n  color: ", ";\n\n  background-color: ", ";\n  border-radius: ", "px;\n  padding: ", ";\n"])), globalFont, fromTheme(function (theme) { return theme.space[1]; }), fromTheme(function (theme) { return theme.global.sizes.tiny; }), function (_a) {
    var dark = _a.dark;
    return fromTheme(function (theme) { return dark
        ? theme.colors.dark
        : theme.colors.gray; });
}, function (_a) {
    var dark = _a.dark;
    return fromTheme(function (theme) { return dark
        ? theme.colors.gray
        : theme.colors.lightGray; });
}, fromTheme(function (theme) { return theme.global.borderRadius; }), function (_a) {
    var small = _a.small;
    return fromTheme(function (theme) { return small ? '2px 4px' : theme.space[1]; });
});
var templateObject_1;
