import React from 'react'
import { Table, fromTheme, Badge } from '@exivity/ui'
import { translate } from '@exivity/translations'
import styled from 'styled-components'
import { queries, useCacheQuery, Resources } from '@exivity/data-layer'

import { useReportFormatter } from '../../../components/hooks/useReportFormatter'
import { insertIf } from '../../../utils/misc'
import {
  getServiceBusinessModels
} from '../../rates/components/useServiceBusinessModels'

export enum TierType {
  Standard = 'standard',
  Inherited ='inherited'
}

const StyledTable = styled.div`
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  margin-bottom: ${fromTheme(theme => theme.global.baseSpacing)}em;
`

const Header = styled.div`
  padding: ${fromTheme(theme => theme.space[1])};
  background-color: ${fromTheme(theme => theme.colors.lightGray)};
`

const Column = styled.div`
  padding: ${fromTheme(theme => theme.space[1])};
  border: 2px solid ${fromTheme(theme => theme.colors.lightGray)};
`

export function TierTable ({ rate }: { rate: any }) {
  const reportFormatter = useReportFormatter()
  const service = useCacheQuery(
    queries.relationshipOf('rate', rate, 'service')
  )

  const { chargeLabel, cogsLabel, tiered } = getServiceBusinessModels(service)

  const tiers = useCacheQuery(
    queries
      .relationshipOf('rate', rate, 'ratetiers')
      .sortByAttribute('threshold', 'ascending')
  )

  return (
    <StyledTable data-testid='tier-table'>
      <Table
        keyExtractor={item => item.id}
        data={tiers}
        columns={[
          {
            header: () => (
              <Header data-testid='tier-col'>
                {translate('Tier')}
              </Header>
            ),
            content: ({ index }) => <Column>{index + 1}</Column>,
            width: '25%'
          },
          {
            header: () => (
              <Header data-testid='units-col'>
                {translate('Units')}
              </Header>
            ),
            content: ({ index, item, data }) => (
              <Column data-testid='units'>
                {data[index + 1]
                  ? `${item.attributes.threshold} - ${data[index + 1].attributes.threshold - 1}`
                  : `${item.attributes.threshold}+`}
              </Column>
            ),
            width: '25%'
          },
          ...insertIf(tiered.charge, [{
            header: () => (
              <Header data-testid='charge-col'>
                {translate('Rate')}
                <Badge>
                  {chargeLabel}
                </Badge>
              </Header>
            ),
            content: ({ item }: { item: Resources['ratetier'] }) => (
              <Column>
                {reportFormatter.formatRate(item.attributes.rate || 0)}
              </Column>
            ),
            width: '25%'
          }], []),
          ...insertIf(tiered.cogs, [{
            header: () => (
              <Header data-testid='cogs-col'>
                {translate('Cogs')}
                <Badge>
                  {cogsLabel}
                </Badge>

              </Header>
            ),
            content: ({ item }: { item: Resources['ratetier'] }) => (
              <Column>
                {reportFormatter.formatRate(item.attributes.cogs || 0)}
              </Column>
            ),
            width: '25%'
          }], [])

        ]} />
    </StyledTable>
  )
}
