var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { fromTheme, themeColor, themeSpace } from '../Theme';
import { Collapsible as CollapsibleComponent } from '../Collapsible';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
export var GroupIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", "em;\n  margin-right: ", "em;\n  flex-grow: 0;\n\n  svg {\n    vertical-align: middle;\n    opacity: 0.5;\n  }\n"], ["\n  font-size: ", "em;\n  margin-right: ", "em;\n  flex-grow: 0;\n\n  svg {\n    vertical-align: middle;\n    opacity: 0.5;\n  }\n"])), fromTheme(function (theme) { return theme.global.sizes.large; }), fromTheme(function (theme) { return theme.global.baseSpacing * 0.25; }));
export var Separator = styled.hr(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n  border: 0;\n  margin: 12px 10px;\n  flex-grow: 1;\n"], ["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n  border: 0;\n  margin: 12px 10px;\n  flex-grow: 1;\n"])), themeColor('lightGray'));
var StyledGroupTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", " {\n    white-space: nowrap;\n    color: ", ";\n    font-size: 1em;\n  }\n"], ["\n  ", " {\n    white-space: nowrap;\n    color: ", ";\n    font-size: 1em;\n  }\n"])), Heading, themeColor('gray'));
export function Title(_a) {
    var children = _a.children;
    return (_jsx(StyledGroupTitle, { children: typeof children === 'string'
            ? _jsx(Heading, { children: children })
            : children }));
}
var StyledHeader = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n\n  &:not(:first-child) {\n    margin-top: ", ";\n  }\n"], ["\n  display: flex;\n\n  &:not(:first-child) {\n    margin-top: ", ";\n  }\n"])), themeSpace(2));
export var Header = function (_a) {
    var children = _a.children;
    var toggleCollapsed = CollapsibleComponent.useCollapsible().toggleCollapsed;
    return (_jsx(StyledHeader, __assign({ onClick: toggleCollapsed }, { children: children })));
};
export var StyledContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding-top: ", ";\n  margin-bottom: ", ";\n"], ["\n  padding-top: ", ";\n  margin-bottom: ", ";\n"])), themeSpace(2), themeSpace(2));
export var Content = function (_a) {
    var children = _a.children;
    return (_jsx(CollapsibleComponent.Content, { children: _jsx(StyledContent, { children: children }) }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
