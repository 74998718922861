var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { CalendarMode, getCalendar } from '../CalendarModes';
import { globalFont, toRgbString } from '../../utils';
import { CalendarBrowser } from '../CalendarBrowser';
import { Button } from '../../Button';
import { withExtendableProps } from '../../ExtendProps';
import { StyledQuarters } from '../CalendarModes/Quarters';
import { CalendarHeader } from '../CalendarHeader';
import { isCompleteRange, isEmptyRange, isPartialRange, orderRange } from '../DateRange';
import { fromTheme } from '../../Theme';
import { useDateRange } from './useDateRange';
var SingleUnitTip = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: ", "em;\n  text-align: center;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: ", "em;\n  text-align: center;\n"])), fromTheme(function (theme) { return theme.colors.gray; }), fromTheme(function (theme) { return theme.global.fontFamily; }), fromTheme(function (theme) { return theme.global.sizes.small; }));
function getUnitName(mode) {
    switch (mode) {
        case CalendarMode.Days: return 'day';
        case CalendarMode.Months: return 'month';
        case CalendarMode.Quarters: return 'quarter';
        case CalendarMode.Years: return 'year';
        default: return '';
    }
}
var StyledDateRangeCalendar = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n\n  min-width: 300px;\n  margin-bottom: ", ";\n\n  .time-unit {\n    .header, .arrow {\n      font-size: 20px;\n    }\n\n    &.start {\n      border-radius: 180px;\n\n      ", "\n\n      ", "\n    }\n\n    &.end {\n      border-bottom-right-radius: 180px;\n      border-top-right-radius: 180px;\n    }\n\n    &.within-range {\n      color: white;\n      background-color: rgba(", ", .5);\n    }\n\n    ", "\n  }\n\n  ", " {\n    .start {\n      ", "\n    }\n\n    .end {\n      border-top-right-radius: 0;\n      border-top-left-radius: 0;\n\n      border-bottom-left-radius: 180px;\n      border-bottom-right-radius: 180px;\n    }\n\n    .start.end {\n      border-radius: 180px;\n    }\n  }\n  \n  button {\n    width: 100%;\n  }\n"], ["\n  ", "\n\n  min-width: 300px;\n  margin-bottom: ", ";\n\n  .time-unit {\n    .header, .arrow {\n      font-size: 20px;\n    }\n\n    &.start {\n      border-radius: 180px;\n\n      ", "\n\n      ", "\n    }\n\n    &.end {\n      border-bottom-right-radius: 180px;\n      border-top-right-radius: 180px;\n    }\n\n    &.within-range {\n      color: white;\n      background-color: rgba(", ", .5);\n    }\n\n    ", "\n  }\n\n  ", " {\n    .start {\n      ", "\n    }\n\n    .end {\n      border-top-right-radius: 0;\n      border-top-left-radius: 0;\n\n      border-bottom-left-radius: 180px;\n      border-bottom-right-radius: 180px;\n    }\n\n    .start.end {\n      border-radius: 180px;\n    }\n  }\n  \n  button {\n    width: 100%;\n  }\n"])), globalFont, fromTheme(function (theme) { return theme.space[1]; }), function (_a) {
    var range = _a.range;
    return isPartialRange(range) && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        &:not(:hover) {\n          border-bottom-right-radius: 0;\n          border-top-right-radius: 0;\n        }\n      "], ["\n        &:not(:hover) {\n          border-bottom-right-radius: 0;\n          border-top-right-radius: 0;\n        }\n      "])));
}, function (_a) {
    var range = _a.range;
    return isCompleteRange(range) && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        border-bottom-right-radius: 0;\n        border-top-right-radius: 0;\n      "], ["\n        border-bottom-right-radius: 0;\n        border-top-right-radius: 0;\n      "])));
}, fromTheme(function (theme) { return toRgbString(theme.global.purposes.primary); }), function (_a) {
    var range = _a.range;
    return (isEmptyRange(range) || isCompleteRange(range)) && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      &:not(.active):not(.out-of-bounds):not(.within-range):hover  {\n        border-radius: 180px;\n      }\n\n      &:not(.active):not(.out-of-bounds):hover {\n        background-color: ", ";\n      }\n    "], ["\n      &:not(.active):not(.out-of-bounds):not(.within-range):hover  {\n        border-radius: 180px;\n      }\n\n      &:not(.active):not(.out-of-bounds):hover {\n        background-color: ", ";\n      }\n    "])), fromTheme(function (theme) { return theme.global.purposes.primary; }));
}, StyledQuarters, function (_a) {
    var start = _a.range[0];
    return start && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        border-bottom-right-radius: 0;\n        border-bottom-left-radius: 0;\n\n        border-top-right-radius: 180px;\n        border-top-left-radius: 180px;\n      "], ["\n        border-bottom-right-radius: 0;\n        border-bottom-left-radius: 0;\n\n        border-top-right-radius: 180px;\n        border-top-left-radius: 180px;\n      "])));
});
function _DateRangeCalendar(_a) {
    var _b = _a.mode, mode = _b === void 0 ? CalendarMode.Days : _b, _c = _a.value, value = _c === void 0 ? null : _c, _d = _a.onChange, onChange = _d === void 0 ? function () { return null; } : _d, required = _a.required, min = _a.min, max = _a.max, _e = _a.offset, offset = _e === void 0 ? 0 : _e;
    var _f = getCalendar(mode), Calendar = _f.Calendar, rangeReducer = _f.rangeReducer;
    var _g = useState((value && value[0]) || new Date()), browserDate = _g[0], setBrowserDate = _g[1];
    var useDateRangeOptions = useMemo(function () { return ({ bounds: { min: min, max: max }, offset: offset }); }, [min, max, offset]);
    var _h = useDateRange(value, rangeReducer, useDateRangeOptions), range = _h[0], handlers = _h[1];
    var _j = useState(null), hoveredDate = _j[0], setHoveredDate = _j[1];
    useEffect(function () {
        if (range !== value && isCompleteRange(range)) {
            onChange(range);
        }
    }, [onChange, value, range]);
    // The range as it is displayed in the calendar can be different from the actual range.
    var apparentRange = (hoveredDate && isPartialRange(range)
        ? orderRange([range[0], hoveredDate])
        : range);
    return (_jsx(StyledDateRangeCalendar, __assign({ "data-mode": mode, "data-offset": offset, "data-testid": 'date-range-calendar', range: apparentRange }, { children: _jsxs(CalendarBrowser, __assign({ value: browserDate, mode: mode, onChange: setBrowserDate }, { children: [_jsx(CalendarHeader, { date: browserDate, mode: mode, offset: offset }), _jsx(Calendar, { value: apparentRange, browserDate: browserDate, min: min, max: max, offset: offset, onChange: function (date, ctrlPressed) {
                        if (ctrlPressed) {
                            handlers.selectDateAsRange(date);
                        }
                        else {
                            handlers.selectDate(date);
                        }
                    }, onHoverItem: setHoveredDate, onEndHoverItem: function () { return setHoveredDate(null); } }), _jsxs(SingleUnitTip, { children: ["Hold down CTRL to select a single ", getUnitName(mode)] }), !required && (_jsx(Button.Clear, __assign({ onClick: function () {
                        // @ts-ignore
                        onChange(null);
                        handlers.resetRange();
                    } }, { children: "Clear range" })))] })) })));
}
export var DateRangeCalendar = withExtendableProps('DateRangeCalendar')(_DateRangeCalendar);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
