var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { setMonth, format } from 'date-fns';
import { CalendarMode } from '../CalendarMode';
import { StyledTimeUnits, TimeUnit } from '../shared';
export var StyledMonths = styled(StyledTimeUnits)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  li {\n    width: calc(33.33% - 5px);\n  }\n"], ["\n  li {\n    width: calc(33.33% - 5px);\n  }\n"])));
export function Months(_a) {
    var value = _a.value, browserDate = _a.browserDate, onHoverItem = _a.onHoverItem, onEndHoverItem = _a.onEndHoverItem, rest = __rest(_a, ["value", "browserDate", "onHoverItem", "onEndHoverItem"]);
    function renderMonth(_, month) {
        var thisMonth = setMonth(browserDate, month);
        return (_jsx("span", __assign({ "data-testid": 'calendar-months-month' }, { children: _jsx(TimeUnit, __assign({ date: thisMonth, mode: CalendarMode.Months, value: value, onHover: onHoverItem, onStopHovering: onEndHoverItem }, rest, { children: format(thisMonth, 'MMM') })) }), month));
    }
    return (_jsx(StyledMonths, __assign({ "data-testid": 'calendar-months' }, { children: new Array(12).fill(null).map(renderMonth) })));
}
var templateObject_1;
