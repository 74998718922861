export type LevelKey =
  | 'lvl1_key'
  | 'lvl2_key'
  | 'lvl3_key'
  | 'lvl4_key'
  | 'lvl5_key'

export const accountLevelKeys: { [key: number]: LevelKey } = {
  1: 'lvl1_key',
  2: 'lvl2_key',
  3: 'lvl3_key',
  4: 'lvl4_key',
  5: 'lvl5_key'
} as const
