import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from '@exivity/translations'
import { SelectFilter, Tooltip } from '@exivity/ui'
import { queries, useCacheQueryFn, Resources, useCacheQuery } from '@exivity/data-layer'
import { exists } from '@exivity/fp'

import { getMaxAccountLevel } from '../../../../selectors/definition'
import { reportsActions, reportSelectors } from '../../state'
import { stringSortRecordsBy } from '../../../../utils/array'

const getAccountName = (account: Resources['account']) => account.attributes.name
const getAccountId = (account: Resources['account']) => account.id

export const useReportAccounts = () => {
  const accountIds = useSelector(reportSelectors.getReportAccountIds)
  const queryFn = useCacheQueryFn()

  return useMemo(() => {
    const queryAccount = (id: string) => queryFn(queries.find('account', id))

    return accountIds
      .map(queryAccount)
      .filter(exists)
      .sort(stringSortRecordsBy('name')) as Resources['account'][]
  }, [accountIds, queryFn])
}

export function ParentAccountFilter () {
  const dispatch = useDispatch()
  const selectedParentAccountId = useSelector(reportSelectors.getSelectedParentAccount)
  const selectedReport = useSelector(reportSelectors.getSelectedReportId)
  const selectedDepth = useSelector(reportSelectors.getAccountDepth)
  const selectedParentAccount = useCacheQuery(queries.find('account', selectedParentAccountId))
  const accounts = useReportAccounts()

  const disabledTooltip = useMemo(() => {
    if (!selectedReport) {
      return translate('Please choose a report first')
    }

    if (String(selectedDepth) === String(getMaxAccountLevel(selectedReport))) {
      return translate('Filtering is not available at this depth')
    }
  }, [selectedDepth, selectedReport])

  return (
    <Tooltip
      placement='bottom'
      content={disabledTooltip}>
      <SelectFilter
        searchable
        disabled={!!disabledTooltip}
        placeholder={translate('Account')}
        data={accounts}
        inputValue={selectedParentAccount?.attributes?.name ?? undefined}
        value={selectedParentAccount?.id || undefined}
        labelAccessor={getAccountName}
        valueAccessor={getAccountId}
        clearText={translate('Clear filter')}
        onClear={
          selectedParentAccount
            ? () => {
              dispatch(reportsActions.filters.updateParentAccount(null))
            }
        : undefined
        }
        onChange={useCallback((parentAccount) => {
          dispatch(reportsActions.filters.updateParentAccount(parentAccount))
        }, [dispatch])} />
    </Tooltip>
  )
}
