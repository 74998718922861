import { batch } from 'react-redux'

import { reportsActions } from '../domains/reports/state'
import { SummaryGroupInstancesBy } from '../domains/reports/state/filters/filterTypes'
import { SummaryPrintMode } from '../domains/reports/state/api'
import { Report } from '../domains/reports/state/filters/reports'
import store from '../store'
import { FetchStatus } from '../API/types'

import navigate from './navigate'
import wait from './wait'

export const CLASSNAME_FONT_FIRA_SANS_400 = 'ex-loaded--fira-sans--400'
export const CLASSNAME_FONT_FIRA_SANS_700 = 'ex-loaded--fira-sans--700'
export const CLASSNAME_REPORT_SUMMARY_TITLE = 'ex-summary__title'
export const CLASSNAME_REPORT_SUMMARY_INSTANCES = 'ex-summary__instances'
export const CLASSNAME_REPORT_SUMMARY_SERVICES = 'ex-summary__services'
export const CLASSNAME_REPORT_SUMMARY_NO_DATA = 'ex-summary__no-data'

export type ShowSummaryReportProps = {
  reportId: string
  depth: number
  startIso: string
  endIso: string
  parentAccountFilter: string|null
  accountFilter: string|null
  showAccounts: boolean
  showServices: boolean
  showInstances: boolean
  groupInstancesBy: 'instances'|'services'
  consolidated: boolean
}

function byId (id: string) {
  return store.dispatch(reportsActions.filters.updateReport(id))
}

function depth (depth: number) {
  return store.dispatch(reportsActions.filters.updateDepth(depth))
}

// Accept number here for backwards compatibility
function filterAccount (id: number | string) {
  return store.dispatch(reportsActions.filters.updateAccount(id ? String(id) : null))
}

function showSummaryAccounts (show = true) {
  return store.dispatch(reportsActions.filters.includeInSummary('accounts', show))
}

function showSummaryServices (show = true) {
  return store.dispatch(reportsActions.filters.includeInSummary('services', show))
}

function showSummaryInstances (show = true) {
  return store.dispatch(reportsActions.filters.includeInSummary('instances', show))
}

function setAccountsFilter (filter: string) {
  return store.dispatch(reportsActions.filters.updateSearchFilter('accounts', filter))
}

function summaryGroupInstanceBy (key: SummaryGroupInstancesBy) {
  return store.dispatch(reportsActions.filters.updateInstanceGroupBy(key))
}

function setServicesFilter (filter: string) {
  return store.dispatch(reportsActions.filters.updateSearchFilter('services', filter))
}

function setInstancesFilter (filter: string) {
  return store.dispatch(reportsActions.filters.updateSearchFilter('instances', filter))
}

function setFilter (reportName: Report, filter: string) {
  return (store.dispatch(reportsActions.filters.updateSearchFilter(reportName, filter)))
}

const SERVER_TIMEOUT = 600000 // 10 minutes

async function showSummaryReport ({
  reportId,
  depth,
  startIso,
  endIso,
  parentAccountFilter,
  accountFilter,
  showAccounts,
  showServices,
  showInstances,
  groupInstancesBy,
  consolidated
}: ShowSummaryReportProps) {
  store.dispatch((dispatch: any) => {
    batch(() => {
      dispatch(reportsActions.dimensions.invalidateData('summaryServices'))
      dispatch(reportsActions.dimensions.invalidateData('summaryInstances'))
      dispatch(reportsActions.filters.updateReport(reportId))
      dispatch(reportsActions.filters.updateDepth(depth))
      dispatch(reportsActions.filters.updateDateRange([new Date(startIso), new Date(endIso)]))
      dispatch(reportsActions.filters.includeInSummary('accounts', showAccounts))
      dispatch(reportsActions.filters.includeInSummary('services', showServices))
      dispatch(reportsActions.filters.includeInSummary('instances', showInstances))
      dispatch(reportsActions.filters.updateSummaryConsolidated(consolidated))
      dispatch(reportsActions.filters.updateParentAccount(parentAccountFilter))
      dispatch(reportsActions.filters.updateInstanceGroupBy(
        groupInstancesBy === 'instances'
          ? SummaryGroupInstancesBy.Instances
          : SummaryGroupInstancesBy.Services
      ))

      dispatch(reportsActions.api.updateSummaryPrintMode(
        accountFilter
          ? SummaryPrintMode.Print
          : SummaryPrintMode.PrintAll
      ))
    })
  })

  await wait.state(() => {
    const onLocation = window.location.href.includes('summary')

    if (!onLocation) {
      accountFilter
        ? navigate.to('reports/summary/' + accountFilter)
        : navigate.to('reports/summary')
    }

    return onLocation
  })

  const screenIsRdy = Promise.all([
    wait.selector(`.${CLASSNAME_FONT_FIRA_SANS_400}`),
    wait.selector(`.${CLASSNAME_FONT_FIRA_SANS_700}`),
    wait.selector(`.${CLASSNAME_REPORT_SUMMARY_TITLE}`),
    showServices
      ? wait.selector(`.${CLASSNAME_REPORT_SUMMARY_SERVICES}`)
      : Promise.resolve(),
    showInstances
      ? wait.selector(`.${CLASSNAME_REPORT_SUMMARY_INSTANCES}`)
      : Promise.resolve()
  ])

  await Promise.any([
    screenIsRdy,
    wait.state((state) => [
      state.reports.dimensions.summaryInstances.status !== FetchStatus.Idle
      || state.reports.dimensions.summaryServices.status !== FetchStatus.Idle,
      state.reports.dimensions.summaryInstances.status !== FetchStatus.Loading,
      state.reports.dimensions.summaryServices.status !== FetchStatus.Loading
    ], SERVER_TIMEOUT)
      .then(() => new Promise<void>((resolve) => {
        // sleep to give time to render
        setTimeout(() => {
          resolve()
        }, 500)
      }))
      .then(() => {
        return wait.selector(`.${CLASSNAME_REPORT_SUMMARY_NO_DATA}`)
      }),
    wait.state((state) => [
      state.reports.dimensions.summaryInstances.status === FetchStatus.Failed
      || state.reports.dimensions.summaryServices.status === FetchStatus.Failed
    ], SERVER_TIMEOUT)
  ])

  return 'Ready'
}

export default {
  // Public (new)
  showSummaryReport,

  // Public (legacy)
  byId,
  depth,
  filterAccount,
  showSummaryAccounts,
  showSummaryServices,
  showSummaryInstances,
  setAccountsFilter,
  summaryGroupInstanceBy,

  // Private
  setServicesFilter,
  setInstancesFilter,
  setFilter
}
