import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SelectFilter } from '@exivity/ui'
import { useSelector } from 'react-redux'
import { queries, Resources, useCacheQueryFn } from '@exivity/data-layer'
import { exists } from '@exivity/fp'
import { useParams } from '@exivity/routing'

import { SummaryParams } from '../../pages/Summary'
import { reportSelectors } from '../../state'
import { stringSortRecordsBy } from '../../../../utils/array'

function getAccountName (account: Resources['account']) {
  return account.attributes.name
}

function getAccountId (account: Resources['account']) {
  return account.id
}

interface SummaryAccountFilterProps {
  accountId?: string
}

const ConstrainWidth = styled.div`
  width: 200px;
`

export function SummaryAccountFilter ({ accountId }: SummaryAccountFilterProps) {
  const [{ id }, setParams] = useParams<SummaryParams>()
  const consolidated = useSelector(reportSelectors.shouldConsolidateSummary)
  const accountIds = useSelector(reportSelectors.getSummaryReportAccountIds)
  const cacheQueryFn = useCacheQueryFn()

  const accounts = useMemo(() => !consolidated
    ? accountIds
      .map((id: string) => cacheQueryFn(queries.find('account', id)))
      .filter(exists)
      .sort(stringSortRecordsBy('name')) as Resources['account'][]
    : [],
  [consolidated, cacheQueryFn, accountIds]
  )

  const selectedAccountId = accountId || id || undefined

  const inputValue = useMemo(() => {
    return selectedAccountId
      ? accounts
        .find((account) => account.id === selectedAccountId)
        ?.attributes
        ?.name ?? undefined
        : undefined
  }, [accounts, selectedAccountId])

  if (consolidated) return null

  return (
    <ConstrainWidth className='summary-account-filter'>
      <SelectFilter
        searchable
        inputValue={inputValue}
        value={selectedAccountId || undefined}
        data={accounts}
        labelAccessor={getAccountName}
        valueAccessor={getAccountId}
        onChange={setParams('id')} />
    </ConstrainWidth>
  )
}
