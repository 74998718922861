var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, createContext, useContext } from 'react';
export var UIExtendPropsContext = createContext({});
export function extendUIProps(extensions) {
    return extensions;
}
export function useExtendProps(component, props) {
    var extensions = useContext(UIExtendPropsContext)[component] || {};
    if (typeof extensions === 'function') {
        return extensions(props);
    }
    return extensions;
}
export function withExtendableProps(name) {
    return function (Component) {
        return forwardRef(function ExtendedComponent(props, ref) {
            var extendedProps = useExtendProps(name, props);
            return _jsx(Component, __assign({ ref: ref }, props, extendedProps));
        });
    };
}
