var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { PARENT, CHILDREN } from '../utils/tree';
import { getDistanceFromSibling } from './helpers';
var StyledBranchSpacer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: ", "px;\n  height: 100%;\n  \n  .treelist__expand--invisible {\n    visibility: hidden;\n  }\n"], ["\n  margin-left: ", "px;\n  height: 100%;\n  \n  .treelist__expand--invisible {\n    visibility: hidden;\n  }\n"])), function (_a) {
    var spacing = _a.spacing, level = _a.level;
    return spacing * (level - 1);
});
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var BranchSpacer = function (_a) {
    var children = _a.children, index = _a.index, data = _a.data, _b = _a.spacing, spacing = _b === void 0 ? 40 : _b, _c = _a.padding, padding = _c === void 0 ? 0 : _c;
    if (padding > spacing) {
        console.warn('BranchSpacer: Padding cannot be greater than spacing');
    }
    var item = data[index];
    var distance = getDistanceFromSibling(data, index);
    return (_jsx(StyledBranchSpacer, __assign({ firstChild: item[PARENT] && item[PARENT][CHILDREN][0].key === item.key, level: item.level, spacing: spacing, padding: Math.min(padding, spacing), distance: distance }, { children: _jsx(Content, { children: children }) })));
};
var templateObject_1, templateObject_2;
