var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import { MdClose } from 'react-icons/md';
import { ListFocus } from '../../ListFocus';
import { ListItem } from '../../ListItem';
import { Flex } from '../../Flex';
import { Icon } from '../../Icon';
import { fromTheme } from '../../Theme';
import { DefaultItem } from './DefaultItem';
import { calculateHeight, ITEM_HEIGHT, NO_DATA_TEXT } from './helpers';
export var StyledList = styled(List)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ul {\n    padding: 0;\n    margin: 0;\n    list-style-type: none;\n  }\n"], ["\n  ul {\n    padding: 0;\n    margin: 0;\n    list-style-type: none;\n  }\n"])));
export var StyledClearItemFlex = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  height: ", "px;\n  width: 100%;\n"], ["\n  color: ", ";\n  height: ", "px;\n  width: 100%;\n"])), fromTheme(function (theme) { return theme.colors.gray; }), ITEM_HEIGHT);
export function defaultValueAccessor(item) {
    if (!item.value) {
        return item;
    }
    return item.value;
}
export function defaultLabelAccessor(item) {
    if (typeof item === 'string') {
        return item;
    }
    else if (item.label) {
        return typeof item.label === 'function'
            ? item.label()
            : item.label;
    }
    else {
        return 'No label';
    }
}
export function SelectList(_a) {
    var data = _a.data, width = _a.width, value = _a.value, onChange = _a.onChange, _b = _a.labelAccessor, labelAccessor = _b === void 0 ? defaultLabelAccessor : _b, _c = _a.valueAccessor, valueAccessor = _c === void 0 ? defaultValueAccessor : _c, _d = _a.innerElementType, innerElementType = _d === void 0 ? 'ul' : _d, _e = _a.noDataText, noDataText = _e === void 0 ? NO_DATA_TEXT : _e, _f = _a.clearable, clearable = _f === void 0 ? false : _f, _g = _a.clearText, clearText = _g === void 0 ? 'Clear selection' : _g, onClear = _a.onClear, children = _a.children, rest = __rest(_a, ["data", "width", "value", "onChange", "labelAccessor", "valueAccessor", "innerElementType", "noDataText", "clearable", "clearText", "onClear", "children"]);
    var itemData = useMemo(function () { return ({
        items: data,
        onChange: onChange,
        value: value,
        labelAccessor: labelAccessor,
        valueAccessor: valueAccessor
    }); }, [data, value, onChange, valueAccessor, labelAccessor]);
    var calculatedHeight = calculateHeight(itemData.items);
    return (_jsx("span", __assign({ "data-testid": 'select-list' }, { children: _jsxs(ListFocus, __assign({}, rest, { children: [(data.length > 0 && clearable && value)
                    && (_jsx(ListItem, { children: _jsxs(StyledClearItemFlex, __assign({ "data-testid": 'clear-button', justifyContent: 'space-between', alignItems: 'center', onClick: onClear }, { children: [_jsx(Flex.Item, { children: clearText }), _jsx(Flex.Item, { children: _jsx(Icon, { children: _jsx(MdClose, {}) }) })] })) })), data.length === 0
                    ? _jsx(ListItem, { children: noDataText })
                    : (_jsx(StyledList, __assign({ height: calculatedHeight, itemData: itemData, itemCount: itemData.items.length, itemSize: ITEM_HEIGHT, innerElementType: innerElementType, width: width || '100%' }, { children: children || DefaultItem })))] })) })));
}
var templateObject_1, templateObject_2;
