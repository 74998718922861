var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { globalFont } from '../utils';
import { fromTheme } from '../Theme';
import { useFieldContext } from '../Field/context';
function LabelGroup(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (_jsx("div", __assign({ className: 'label-group' }, { children: React.Children.map(children, function (child) { return (React.isValidElement(child)
            ? React.cloneElement(child, rest)
            : null); }) })));
}
var SubLabel = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  display: block;\n  font-size: ", "rem;\n  color: ", ";\n\n  > * {\n    display: block;\n    font-size: ", "rem;\n    color: ", ";\n  }\n"], ["\n  ", "\n\n  display: block;\n  font-size: ", "rem;\n  color: ", ";\n\n  > * {\n    display: block;\n    font-size: ", "rem;\n    color: ", ";\n  }\n"])), globalFont, fromTheme(function (theme) { return theme.global.sizes.small; }), fromTheme(function (theme) { return theme.global.textColorMuted; }), fromTheme(function (theme) { return theme.global.sizes.small; }), fromTheme(function (theme) { return theme.global.textColorMuted; }));
export var StyledLabel = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "  \n"], ["\n  ", "  \n"])), globalFont);
function Label(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    var id = useFieldContext().id;
    return (_jsx(StyledLabel, __assign({ htmlFor: id }, rest, { children: children })));
}
Label.Group = LabelGroup;
Label.Sub = SubLabel;
export { Label };
var templateObject_1, templateObject_2;
