var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { Tabs } from '../../Tabs';
import { CalendarMode } from '../CalendarModes/CalendarMode';
var StyledModePicker = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .tab-list {\n    display: flex;\n    \n    > * {\n      flex-grow: 1;\n    }\n  }\n"], ["\n  .tab-list {\n    display: flex;\n    \n    > * {\n      flex-grow: 1;\n    }\n  }\n"])));
var MODE_TABS = (_a = {},
    _a[CalendarMode.Days] = 'Days',
    _a[CalendarMode.Months] = 'Months',
    _a[CalendarMode.Quarters] = 'Quarters',
    _a[CalendarMode.Years] = 'Years',
    _a);
export function ModePicker(_a) {
    var _b = _a.modes, modes = _b === void 0 ? Object.values(CalendarMode) : _b, value = _a.value, onChange = _a.onChange, children = _a.children;
    if (modes.length < 2) {
        throw new Error('The ModePicker component is redundant if it has less than 2 options');
    }
    return (_jsxs("div", { children: [_jsx(StyledModePicker, { children: _jsx(Tabs, __assign({ activeIndex: modes.indexOf(value), onActiveIndexChange: function (i) { return onChange && onChange(modes[i]); } }, { children: _jsx(Tabs.TabList, __assign({ className: 'tab-list' }, { children: modes.map(function (mode) { return _jsx(Tabs.Tab, { children: MODE_TABS[mode] }, mode); }) })) })) }), React.isValidElement(children) && React.cloneElement(children, { mode: value })] }));
}
var templateObject_1;
