var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { withStyles } from '../utils/withStyles';
import { Variations } from './Variations';
function defaultRenderHeader(_a) {
    var header = _a.header;
    return header;
}
function defaultRenderContent(_a) {
    var item = _a.item, column = _a.column;
    if (!column) {
        throw Error('Unable to render a content cell');
    }
    return item[column];
}
function defaultRenderFooter(_a) {
    var footer = _a.footer;
    return footer;
}
export function _Table(_a) {
    var data = _a.data, columns = _a.columns, keyExtractor = _a.keyExtractor, _b = _a.renderHeaderCell, renderHeaderCell = _b === void 0 ? defaultRenderHeader : _b, _c = _a.renderContentCell, renderContentCell = _c === void 0 ? defaultRenderContent : _c, _d = _a.renderFooterCell, renderFooterCell = _d === void 0 ? defaultRenderFooter : _d, context = _a.context;
    var hasFooter = useMemo(function () { return columns.some(function (column) { return !!column.footer; }); }, [columns]);
    return (_jsxs("table", __assign({ style: { width: '100%' } }, { children: [_jsx("thead", __assign({ "data-testid": 'table-header' }, { children: _jsx("tr", { children: columns.map(function (column, columnIndex) { return (_jsx("th", { children: typeof column.header === 'function'
                            ? column.header({ data: data, context: context })
                            : renderHeaderCell({ header: column.header, context: context }) }, columnIndex)); }) }) })), _jsx("tbody", __assign({ "data-testid": 'table-content' }, { children: data.map(function (item, index) { return (_jsx("tr", { children: columns.map(function (column, columnIndex) {
                        var shared = { item: item, context: context, index: index, data: data };
                        return (_jsx("td", { children: typeof column.content === 'function'
                                ? column.content(shared)
                                : renderContentCell(__assign({ column: column.content }, shared)) }, columnIndex));
                    }) }, keyExtractor(item, index))); }) })), hasFooter && (_jsx("tfoot", { children: _jsx("tr", __assign({ "data-testid": 'table-footer' }, { children: columns.map(function (column, columnIndex) { return (_jsx("td", { children: typeof column.footer === 'function'
                            ? column.footer({ data: data, context: context })
                            : renderFooterCell({ footer: column.footer, context: context, data: data }) }, columnIndex)); }) })) }))] })));
}
var Table = withStyles(Variations.Default)(_Table);
export { Table };
