var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { MdArrowDropDown } from 'react-icons/md';
import { Dropdown } from '../Dropdown';
import { Icon } from '../Icon';
import { Placement } from '../LegacyDropdown/Popper';
import { themeColor, themeSpace } from '../Theme';
import { Button } from './Button';
export var SelectOption = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  all: unset;\n  line-height: 30px;\n  padding: 0 ", ";\n  \n  &:hover, :focus {\n    background-color: ", ";\n  }\n"], ["\n  all: unset;\n  line-height: 30px;\n  padding: 0 ", ";\n  \n  &:hover, :focus {\n    background-color: ", ";\n  }\n"])), themeSpace(2), themeColor('lightGray'));
SelectOption.defaultProps = {
    type: 'button'
};
var StyledButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0;\n\n  ", " {\n    margin: 0;\n  }\n"], ["\n  padding: 0;\n\n  ", " {\n    margin: 0;\n  }\n"])), Icon);
StyledButton.displayName = 'TriggerButton';
export function Select(_a) {
    var _b = _a.placement, placement = _b === void 0 ? Placement.BOTTOM_END : _b, children = _a.children, onClick = _a.onClick, rest = __rest(_a, ["placement", "children", "onClick"]);
    var reference = (_jsx(StyledButton, __assign({}, rest, { icon: _jsx(MdArrowDropDown, {}), onClick: function (e) {
            onClick && onClick(e);
        } })));
    return (_jsx(Dropdown, __assign({ placement: placement, closeOnClick: false, reference: reference }, { children: children })));
}
var templateObject_1, templateObject_2;
