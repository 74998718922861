var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Children, useState } from 'react';
import chunk from 'lodash/chunk';
import styled, { css } from 'styled-components';
import { Flex } from '../Flex';
import { LegacyTable } from '../LegacyTable';
import { fromTheme } from '../Theme';
import { useObserveResize } from '../useObserveResize';
var smallMetaTableStyles = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  /* Force table to not behave like a table anymore */\n  table, thead, tbody, th, td, tr, ", ", ", " { \n    display: block; \n    width: 100%;\n  }\n"], ["\n  /* Force table to not behave like a table anymore */\n  table, thead, tbody, th, td, tr, ", ", ", " { \n    display: block; \n    width: 100%;\n  }\n"])), Flex, Flex.Item);
var largeMetaTableStyles = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  th {\n    width: 40%;\n  }\n\n  td {\n    width: 60%;\n  }\n\n  ", " ", ":only-child {\n    th {\n      width: 20%;\n    }\n\n    td {\n      width: 80%;\n    }\n  }\n"], ["\n  th {\n    width: 40%;\n  }\n\n  td {\n    width: 60%;\n  }\n\n  ", " ", ":only-child {\n    th {\n      width: 20%;\n    }\n\n    td {\n      width: 80%;\n    }\n  }\n"])), Flex, Flex.Item);
var StyledMetaTable = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: ", "em;\n\n  td {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n  \n  ", "\n"], ["\n  margin-bottom: ", "em;\n\n  td {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n  \n  ", "\n"])), fromTheme(function (theme) { return theme.global.baseSpacing; }), function (_a) {
    var small = _a.small;
    return small
        ? smallMetaTableStyles
        : largeMetaTableStyles;
});
export function MetaTableItem(_a) {
    var label = _a.label, value = _a.value;
    return (_jsxs(_Fragment, { children: [_jsx("th", { children: label }), _jsx("td", __assign({ title: typeof value === 'string' ? value : undefined }, { children: value }))] }));
}
MetaTableItem.displayName = 'MetaTableItem';
function renderRows(items) {
    return (_jsx(LegacyTable, __assign({ small: true, compact: true }, { children: _jsx("tbody", { children: items.map(function (item, i) { return (_jsx("tr", __assign({ "data-testid": 'row' }, { children: item }), i)); }) }) })));
}
function renderColumns(chunks) {
    return (_jsx(Flex, __assign({ direction: 'row' }, { children: chunks.map(function (items, i) { return (_jsx(Flex.Item, __assign({ width: 1 / chunks.length, "data-testid": 'column' }, { children: renderRows(items) }), i)); }) })));
}
function MetaTable(_a) {
    var breakSize = _a.breakSize, _b = _a.direction, direction = _b === void 0 ? 'column' : _b, children = _a.children;
    var _c = useState(false), isSmall = _c[0], setIsSmall = _c[1];
    var ref = useObserveResize(function (node) {
        var shouldBeSmall = node.getBoundingClientRect().width < 500;
        if (shouldBeSmall && !isSmall)
            setIsSmall(true);
        if (!shouldBeSmall && isSmall)
            setIsSmall(false);
    });
    var chunks = breakSize
        ? chunk(Children.toArray(children), breakSize)
        : [Children.toArray(children)];
    return (_jsx(StyledMetaTable, __assign({ ref: ref, small: isSmall }, { children: direction === 'row' ? renderRows(chunks) : renderColumns(chunks) })));
}
MetaTable.Item = MetaTableItem;
export { MetaTable };
var templateObject_1, templateObject_2, templateObject_3;
