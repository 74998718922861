var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import styled, { css } from 'styled-components';
import { injectIf, injectUnless, preciseEm } from '../utils';
import { matchButtonHeight, matchColorFromPurpose, matchThemeProp, themeColor, themeFontFamily, themeGlobal } from '../Theme';
import { Icon } from '../Icon';
var RadioButtonContext = createContext({
    value: '',
    onChange: function () { }
});
var Option = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  --light-color: ", ";\n  --dark-color: ", ";\n  --text-dark: ", ";\n\n  display: inline-flex;\n  gap: 0.5em;\n  align-items: center;\n  font-family: ", ";\n  font-size: ", "em;\n  line-height: ", ";\n  color: var(--text-dark);\n  text-transform: uppercase;\n\n  height: ", "em;\n  padding: 0 ", "em;\n  border-radius: 0px;\n  border: 0px;\n  background-color: var(--light-color);\n  \n  &[disabled] {\n    pointer-events: none;\n    opacity: .3;\n  }\n  \n  ", "\n  \n  ", "\n  \n  ", " {\n    font-size: 1.5em;\n  }\n"], ["\n  --light-color: ", ";\n  --dark-color: ", ";\n  --text-dark: ", ";\n\n  display: inline-flex;\n  gap: 0.5em;\n  align-items: center;\n  font-family: ", ";\n  font-size: ", "em;\n  line-height: ", ";\n  color: var(--text-dark);\n  text-transform: uppercase;\n\n  height: ", "em;\n  padding: 0 ", "em;\n  border-radius: 0px;\n  border: 0px;\n  background-color: var(--light-color);\n  \n  &[disabled] {\n    pointer-events: none;\n    opacity: .3;\n  }\n  \n  ", "\n  \n  ", "\n  \n  ", " {\n    font-size: 1.5em;\n  }\n"])), matchColorFromPurpose(200, 1, 'secondary'), matchColorFromPurpose(700, 1, 'secondary'), matchColorFromPurpose(900, 1, 'secondary'), themeFontFamily, matchThemeProp(function (theme) { return theme.global.sizes; }, { modifier: function (em) { return em / 16 * 14; } }), themeGlobal('lineHeight'), matchButtonHeight, preciseEm(1.5), injectIf('active', css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: ", ";\n    background-color: var(--dark-color);\n  "], ["\n    color: ", ";\n    background-color: var(--dark-color);\n  "])), themeColor('white'))), injectUnless('active', css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    &:hover {\n      background-color: ", ";\n    }\n  "], ["\n    &:hover {\n      background-color: ", ";\n    }\n  "])), matchColorFromPurpose(300, 1, 'secondary'))), Icon);
var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  --radius: ", "px;\n  \n  display: flex;\n  \n  ", ":first-of-type {\n    border-top-left-radius: var(--radius);\n    border-bottom-left-radius: var(--radius);\n  }\n  \n  ", ":last-of-type {\n    border-top-right-radius: var(--radius);\n    border-bottom-right-radius: var(--radius);\n  }\n"], ["\n  --radius: ", "px;\n  \n  display: flex;\n  \n  ", ":first-of-type {\n    border-top-left-radius: var(--radius);\n    border-bottom-left-radius: var(--radius);\n  }\n  \n  ", ":last-of-type {\n    border-top-right-radius: var(--radius);\n    border-bottom-right-radius: var(--radius);\n  }\n"])), themeGlobal('borderRadius'), Option, Option);
var RadioButtonOption = function (_a) {
    var value = _a.value, title = _a.title, disabled = _a.disabled, className = _a.className, children = _a.children;
    var _b = useContext(RadioButtonContext), selectedValue = _b.value, onChange = _b.onChange, allDisabled = _b.disabled, sizeAndPurpose = __rest(_b, ["value", "onChange", "disabled"]);
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "radio-btn-".concat(value), hidden: true }, { children: children })), _jsx(Option, __assign({ id: "radio-btn-".concat(value), className: className, type: 'button' }, sizeAndPurpose, { title: title, disabled: allDisabled || disabled, active: value === selectedValue, onClick: function () { return onChange(value); } }, { children: children }))] }));
};
function RadioButton(_a) {
    var className = _a.className, children = _a.children, props = __rest(_a, ["className", "children"]);
    return (_jsx(Container, __assign({ className: className }, { children: _jsx(RadioButtonContext.Provider, __assign({ value: props }, { children: children })) })));
}
RadioButton.Option = RadioButtonOption;
export { RadioButton };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
