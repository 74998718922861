var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { globalFont } from '../utils';
import { fromTheme } from '../Theme';
import { useTabsContext, TabsContext } from './helpers';
var StyledTab = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-flex;\n  justify-content: space-around;\n  color: ", ";\n  list-style: none;\n  padding: 0.5rem 0;\n  margin: 0 10px -1px 10px;\n  user-select: none;\n  cursor: pointer;\n  outline: none;\n\n  &:first-child {\n    margin-left: 0;\n  }\n\n  ", "\n\n  ", "\n"], ["\n  display: inline-flex;\n  justify-content: space-around;\n  color: ", ";\n  list-style: none;\n  padding: 0.5rem 0;\n  margin: 0 10px -1px 10px;\n  user-select: none;\n  cursor: pointer;\n  outline: none;\n\n  &:first-child {\n    margin-left: 0;\n  }\n\n  ", "\n\n  ", "\n"])), fromTheme(function (theme) { return theme.colors.gray; }), function (_a) {
    var isActive = _a.isActive;
    return isActive && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: #6F6F6F;\n    border-bottom: 4px solid #ccc;\n\n    &:focus {\n      border-bottom-color: #6F6F6F;\n    }\n  "], ["\n    color: #6F6F6F;\n    border-bottom: 4px solid #ccc;\n\n    &:focus {\n      border-bottom-color: #6F6F6F;\n    }\n  "])));
}, function (_a) {
    var disabled = _a.disabled;
    return disabled && "\n    pointer-events: none;\n    opacity: 0.6;\n  ";
});
function Tab(_a) {
    var children = _a.children, _index = _a._index, disabled = _a.disabled, onClick = _a.onClick, rest = __rest(_a, ["children", "_index", "disabled", "onClick"]);
    var _b = useTabsContext(), activeIndex = _b.activeIndex, setActiveIndex = _b.setActiveIndex;
    if (_index === undefined) {
        throw new Error('The Tabs.Tab should be a direct child of the Tabs.TabList component');
    }
    var isActive = activeIndex === _index;
    return (_jsx(StyledTab, __assign({ "data-testid": 'tabs-tab', "data-active": isActive, isActive: isActive, tabIndex: isActive ? 0 : -1, disabled: disabled, onClick: function (e) {
            onClick && onClick(e);
            !disabled && setActiveIndex(_index);
        } }, rest, { children: children })));
}
var TabsList = styled.ol.attrs({ 'data-testid': 'tabs-list' })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid #ccc;\n  padding-left: 0;\n\n  :focus {\n    outline: none;\n  }\n"], ["\n  border-bottom: 1px solid #ccc;\n  padding-left: 0;\n\n  :focus {\n    outline: none;\n  }\n"])));
function useFocus(initFocus) {
    var _a = useState(initFocus), focused = _a[0], setFocused = _a[1];
    return [focused, {
            focus: function () { return setFocused(true); },
            blur: function () { return setFocused(false); }
        }];
}
function TabList(_a) {
    var children = _a.children, onFocus = _a.onFocus, onBlur = _a.onBlur, onKeyDown = _a.onKeyDown, rest = __rest(_a, ["children", "onFocus", "onBlur", "onKeyDown"]);
    var _b = useTabsContext(), next = _b.next, prev = _b.prev;
    var _c = useFocus(false), focused = _c[0], _d = _c[1], focus = _d.focus, blur = _d.blur;
    var tabAmount = React.Children.count(children);
    return (_jsx(TabsList, __assign({ tabIndex: -1 }, rest, { onFocus: function (e) {
            onFocus && onFocus(e);
            focus();
        }, onBlur: function (e) {
            onBlur && onBlur(e);
            blur();
        }, onKeyDown: function (e) {
            onKeyDown && onKeyDown(e);
            if (focused) {
                var disabledTabs = React.Children.map(children, function (child, i) { return (React.isValidElement(child) && child.props.disabled ? i : null); }).filter(function (item) { return item !== null; });
                switch (e.key) {
                    case 'ArrowRight': {
                        next(tabAmount, disabledTabs);
                        break;
                    }
                    case 'ArrowLeft': {
                        prev(tabAmount, disabledTabs);
                        break;
                    }
                    default:
                }
            }
        } }, { children: React.Children.map(children, function (child, index) { return (child && React.cloneElement(child, { _index: index })); }) })));
}
var TabPanel = styled.div.attrs({ 'data-testid': 'tabs-panel' })(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
function TabPanels(_a) {
    var children = _a.children;
    var activeIndex = useTabsContext().activeIndex;
    var activeChild = React.Children.toArray(children)[activeIndex];
    return (_jsx(_Fragment, { children: activeChild
            ? activeChild
            : null }));
}
var StyledTabs = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n  width: 100%;\n\n  display: flex;\n  flex-direction: column;\n\n  ", " {\n    flex-grow: 1;\n    \n    ", "\n  }\n"], ["\n  ", "\n  width: 100%;\n\n  display: flex;\n  flex-direction: column;\n\n  ", " {\n    flex-grow: 1;\n    \n    ", "\n  }\n"])), globalFont, TabPanel, function (props) { return !props.disableAnimation && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      will-change: transform, opacity;\n\n      @keyframes slidein {\n        from {\n          transform: translateX(-10px);\n          opacity: 0;\n        }\n\n        to {\n          transform: translateX(0px);\n          opacity: 1;\n        }\n      }\n\n      animation-duration: 0.5s;\n      animation-name: slidein;\n    "], ["\n      will-change: transform, opacity;\n\n      @keyframes slidein {\n        from {\n          transform: translateX(-10px);\n          opacity: 0;\n        }\n\n        to {\n          transform: translateX(0px);\n          opacity: 1;\n        }\n      }\n\n      animation-duration: 0.5s;\n      animation-name: slidein;\n    "]))); });
export function Tabs(_a) {
    var children = _a.children, onActiveIndexChange = _a.onActiveIndexChange, initialActiveIndex = _a.initialActiveIndex, activeIndex = _a.activeIndex, _b = _a.disableAnimation, disableAnimation = _b === void 0 ? false : _b, className = _a.className;
    var _c = useState(initialActiveIndex || 0), uncontrolledActiveIndex = _c[0], setUncontrolledActiveIndex = _c[1];
    if (initialActiveIndex !== undefined && activeIndex !== undefined) {
        throw new Error([
            'Both the activeIndex and the initialActiveIndex props are passed',
            'while these values are mutually exclusive.'
        ].join(' '));
    }
    var contextValue = {
        activeIndex: activeIndex !== null && activeIndex !== void 0 ? activeIndex : uncontrolledActiveIndex,
        setActiveIndex: function (index) {
            onActiveIndexChange && onActiveIndexChange(index);
            setUncontrolledActiveIndex(index);
        }
    };
    return (_jsx(TabsContext.Provider, __assign({ value: contextValue }, { children: _jsx(StyledTabs, __assign({ disableAnimation: disableAnimation, className: className }, { children: children })) })));
}
/**
 * @description Button that is used to select the Tabs.TabPanel that we want to display.
 */
Tabs.Tab = Tab;
/**
 * @description Container for al the Tabs.Tab components.
 */
Tabs.TabList = TabList;
/**
 * @description
 * Container for the content we want to display when
 * this Tabs.Tab related to this Tabs.TabPanel is active.
 */
Tabs.TabPanel = TabPanel;
/**
 * @description Container for al the Tabs.TabPanel components.
 */
Tabs.TabPanels = TabPanels;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
