var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { themeColor, themePurpose, themeSpace } from '../Theme';
import { injectIf } from '../utils';
import { Title } from './Title';
import { NavIcon } from './NavIcon';
import { NavGroupContext } from './NavBar';
// eslint-disable-next-line max-len
export var chevronRight = 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%3E%3C%2Fpolyline%3E%0A%3C%2Fsvg%3E';
var Group = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0 ", ";\n  height: 64px;\n  \n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0 ", ";\n  height: 64px;\n  \n  &:hover {\n    background-color: ", ";\n  }\n"])), themeSpace(2), themeColor('lightDark'));
var Options = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 10px 0;\n  margin: 0;\n  width: 100%;\n"], ["\n  padding: 10px 0;\n  margin: 0;\n  width: 100%;\n"])));
var StyledNavGroup = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n  color: ", ";\n  text-decoration: none;\n  text-transform: uppercase;\n  \n  ", " {\n    display: none;\n  }\n\n  &.group--expand {\n    ", " {\n      display: block;\n    }\n  }\n  \n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n  color: ", ";\n  text-decoration: none;\n  text-transform: uppercase;\n  \n  ", " {\n    display: none;\n  }\n\n  &.group--expand {\n    ", " {\n      display: block;\n    }\n  }\n  \n  ", "\n"])), themeColor('dark'), themeColor('white'), Options, Options, injectIf('active', css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", " {\n      background-color: ", ";\n    }\n  \n    ", " {\n      color: ", ";\n    }\n  "], ["\n    ", " {\n      background-color: ", ";\n    }\n  \n    ", " {\n      color: ", ";\n    }\n  "])), Group, themeColor('lightDark'), NavIcon, themePurpose('brand'))));
var Chevron = styled.i
    .attrs({ className: 'nav-group__chev' })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  &::after {\n    content: '';\n    width: 1.5em;\n    height: 1.5em;\n    float: right;\n    background-image: url(", ");\n    background-repeat: no-repeat;\n    background-position: 50% 50%; \n    transform: rotate(180deg);\n    transition-property: transform;\n    transition-duration: 200ms;\n    transition-delay: 0ms;\n    transition-timing-function: ease-in-out;\n    \n   ", ";\n  }\n"], ["\n  &::after {\n    content: '';\n    width: 1.5em;\n    height: 1.5em;\n    float: right;\n    background-image: url(", ");\n    background-repeat: no-repeat;\n    background-position: 50% 50%; \n    transform: rotate(180deg);\n    transition-property: transform;\n    transition-duration: 200ms;\n    transition-delay: 0ms;\n    transition-timing-function: ease-in-out;\n    \n   ", ";\n  }\n"])), chevronRight, injectIf('open', css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      transition-property: transform;\n      transition-duration: 200ms;\n      transition-delay: 0ms;\n      transition-timing-function: ease-in-out;\n      transform: rotate(270deg);\n    "], ["\n      transition-property: transform;\n      transition-duration: 200ms;\n      transition-delay: 0ms;\n      transition-timing-function: ease-in-out;\n      transform: rotate(270deg);\n    "])))));
var getIndexOfSelf = function (node) { return Array.from(node.parentElement.children).findIndex(function (child) { return child === node; }); };
export function NavGroup(_a) {
    var name = _a.name, _b = _a.active, active = _b === void 0 ? false : _b, icon = _a.icon, children = _a.children;
    var _c = useContext(NavGroupContext), openAtIndex = _c.openAtIndex, onClick = _c.onClick;
    var _d = useState(null), index = _d[0], setIndex = _d[1];
    return (_jsxs(StyledNavGroup, __assign({ className: 'navbar__group', tabIndex: -1, active: active, ref: function (node) {
            if (node) {
                setIndex(getIndexOfSelf(node));
            }
        } }, { children: [_jsxs(Group, __assign({ onClick: function () { return index !== null && onClick(index); } }, { children: [_jsx(NavIcon, { children: icon }), _jsx(Title, { children: name }), _jsx(Chevron, { open: openAtIndex === index })] })), _jsx(Options, { children: React.Children.map(children, function (child) { return React
                    .cloneElement(child, { isSubLink: true }); }) })] })));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
