import MemorySource from '@orbit/memory'
import { Resources } from '@exivity/data-layer'
import { isEmpty } from 'ramda'

import { parentAccounts } from '../../../selectors/accounts'
import type {
  AccountReportItem,
  AccountReportItemAPI
} from '../../../API/APIResponseTypes/reports'
import {
  accountLevelKeys,
  MetadataModel
} from '../../../data/types'
import type { RootState } from '../../../reducers'
import { getMetadataValues } from '../metadata'
import { configurationSelectors } from '../../../domains/administration/state/configuration'

function getStore () {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return require('../../../data/store').default as MemorySource
}

export default async function enrichAccounts (state: RootState, data: AccountReportItemAPI[]) {
  return data.map(item => {
    let account: Resources['account']
    const newItem = item as AccountReportItem

    // Format numbers
    const toParse = [
      'total_quantity',
      'unit_based_subtotal_charge',
      'interval_based_subtotal_charge',
      'total_charge',
      'total_cogs',
      'total_net'
    ] as const

    toParse.forEach(field => {
      newItem[field] = parseFloat(item[field] as string)
    })

    // Find account names
    try {
      account = getStore()
        .cache.query((query) => query.findRecord({
          type: 'account',
          id: newItem.account_id
        })) as Resources['account']

      const metadatas = getStore().cache
        .query((q) => q.findRelatedRecords(account, 'metadata')) as MetadataModel[]

      if (!isEmpty(metadatas)) {
        newItem.metadata = getMetadataValues(
          metadatas,
          configurationSelectors.getDateFormat(state)
        )
      }

      newItem.account = account.attributes.name
      newItem.key = account.attributes[accountLevelKeys[account.attributes.level]]
      newItem.parents = parentAccounts(account)
      newItem.keyAgg = ''
      newItem.parent_name = newItem.parents.length
        ? newItem.parents[0].name + '-id-' + newItem.parents[0].id
        : ''
    } catch (error) {
      newItem.account = '(unknown account)'
      newItem.key = '(unknown account)'
      newItem.keyAgg = ''
    }

    return { ...newItem }
  })
}
